.createButton__container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  outline: none;
  border: 3px solid transparent;
  background-color: transparent;
}

.createButton__container:active,
.createButton__container:focus {
  border: 3px solid var(--focusColor);
}

.createButton__container--noFocus,
.createButton__container--noFocus:active,
.createButton__container--noFocus:focus {
  border: 3px solid transparent;
}

.createButton__text {
  font-style: normal;
  font-weight: normal;
  text-align: right;
  color: #089bab;
  user-select: none;
  white-space: nowrap;
  padding: 0;
  margin: 0;
}

@media (max-width: 640px) {
  .createButton__text {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .createButton__text {
    font-size: 14px;
    line-height: 1;
  }
}

@media (min-width: 1008px) {
  .createButton__text {
    font-size: 16px;
    line-height: 24px;
  }
}

.createButton__icon {
  padding-right: 10px;
}

@media (max-width: 640px) {
  .createButton__icon {
    padding-left: 0;
  }
}

@media (min-width: 641px) and (max-width: 1008px) {
  .createButton__icon {
    height: 24px;
    width: 24px;
  }
}

.createButton__text--grey {
  color: "#D9E1EC";
}
