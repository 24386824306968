.scrollArea {
  overflow-x: hidden;
  overflow-y: auto;
  scroll-padding: 0 0 0 3px;
  margin-bottom: 0;
}

@media only screen and (max-width: 640px) {
  .scrollArea {
    padding: 4px 3px 3px 3px;
    height: 0;
    flex: 1 0 auto;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollArea::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width: 641px) {
  .scrollArea {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    height: 270px;
    padding: 4px 10px 3px 3px;
    scroll-padding: 0 0 0 3px;
    margin-bottom: 43px;
  }
}

.getSite {
  background-color: #fff;
}

@media only screen and (max-width: 640px) {
  .getSite {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 24px;
  }
}

@media only screen and (min-width: 641px) {
  .getSite {
    height: 100%;
    width: 720px;
    box-sizing: border-box;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-left: 63px;
    padding-right: 63px;
    margin: auto;
  }
}

.getSite__container {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 640px) {
  .getSite__container {
    flex: 1 0 auto;
  }
}

@media only screen and (min-width: 641px) {
  .getSite__container {
    /* background-color: #fafafb; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.getSite__heading {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
}

@media only screen and (max-width: 640px) {
  .getSite__heading {
    line-height: 1;
    margin: 0 0 12px 0;
  }
}

@media only screen and (min-width: 641px) {
  .getSite__heading {
    padding-top: 28px;
    margin-top: 0;
  }
}

.getSite__list {
  padding-left: 0;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 640px) {
  .getSite__list {
    margin: 0;
  }
}

@media only screen and (min-width: 641px) {
  .getSite__list {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
}

.getSite__list--withScroll {
  margin-top: 0;
  padding-bottom: 0;
}

@media only screen and (min-width: 641px) {
  .getSite__list--withScroll {
    margin-top: 0;
    padding-bottom: 0;
  }
}

.getSite__item {
  display: flex;
  align-items: center;
  list-style-type: none;
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
  padding-left: 14px;
}

.getSite__item--withScroll {
  margin-bottom: -15px;
}

@media only screen and (min-width: 376px) {
  .getSite__item:hover {
    cursor: pointer;
  }

  @media only screen and (min-width: 376px) {
    .getSite__item:last-child {
      margin-bottom: 0;
    }
  }
}
