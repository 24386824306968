.editProfile__view {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 230;
}

@media (max-width: 640px) {
  .editProfile__view {
    margin: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

@media (min-width: 641px) {
  .editProfile__view {
    margin: 100px auto;
    width: 1000px;
    border: 1px solid white;
    border-radius: 20px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .editProfile__view {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
  }
}

.editProfile__body {
  display: flex;
  flex-direction: column;
}

@media (max-width: 640px) {
  .editProfile__body {
    width: 100%;
    margin-top: 24px;
    padding-right: 16px;
    padding-left: 16px;
    box-sizing: border-box;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .editProfile__body {
    margin: 0 20px;
  }
}

@media (min-width: 1008px) {
  .editProfile__body {
    width: auto;
    margin-right: 66px;
    margin-left: 66px;
  }
}

.editProfile__row {
  display: flex;
  width: 100%;
}

.editProfile__row--alignCenter {
  align-items: center;
}

@media (max-width: 640px) {
  .editProfile__row {
    flex-direction: column;
  }
}

@media (min-width: 641px) {
  .editProfile__row {
    flex-direction: row;
  }
}

.editProfile__col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.editProfile__title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #121a2d;
}

@media (max-width: 640px) {
  .editProfile__title {
    font-size: 20px;
    line-height: 1;
    margin: 24px 0 0 2px;
    padding: 0;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .editProfile__title {
    margin: 0 0 30px 0;
    font-size: 30px;
    line-height: 1;
  }
}

@media (min-width: 1008px) {
  .editProfile__title {
    margin: 0;
    font-size: 34px;
    line-height: 27px;
  }
}

@media (max-width: 640px) {
  .editProfile__title--photoCapture {
    margin-left: 40px;
  }
}

.editProfile__footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .editProfile__footer {
    margin-top: auto;
    margin-bottom: 20px;
    padding-top: 37px;
  }
}

@media (min-width: 641px) {
  .editProfile__footer {
    justify-content: flex-end;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .editProfile__footer {
    justify-content: flex-end;
    margin: 30px 108px 25px 0;
  }
}

@media (min-width: 1008px) {
  .editProfile__footer {
    justify-content: flex-end;
    margin: 38px 108px 50px 51px;
  }
}

.editProfile__profilePic {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  border: 0.5px solid #ededed;
}

@media (max-width: 640px) {
  .editProfile__profilePic {
    margin-bottom: 0;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .editProfile__profilePic {
    margin-right: 10px;
  }
}

@media (min-width: 1008px) {
  .editProfile__profilePic {
    margin-right: 24px;
  }
}

.editProfile__profilePicButton {
  width: 168px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 640px) {
  .editProfile__overlay {
    height: 100%;
  }
}

@media (min-width: 641px) {
  .editProfile__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(152, 169, 188, 0.3);
    backdrop-filter: blur(46.2108px);
    z-index: 210;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .editProfile__overlay {
    position: fixed;
    left: 0;
  }
}

@media (min-width: 1008px) {
  .editProfile__overlay {
    left: -120px; /* Needed to cover vertical menu */
  }
}

@media (max-width: 640px) {
  .editProfile__header {
    margin-left: 18px;
    margin-top: 19px;
  }
}

@media (min-width: 641px) {
  .editProfile__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .editProfile__header {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 0;
  }
}

@media (min-width: 1008px) {
  .editProfile__header {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 66px;
    height: 100px;
  }
}

@media (max-width: 640px) {
  .editProfile__backArrow {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .editProfile__backArrow__icon {
    padding-right: 4px;
  }
}

@media (max-width: 640px) {
  .editProfile__goBackTitle {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(18, 26, 45, 0.5);
    line-height: 1;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 640px) {
  .editProfile__contentContainer {
    height: 100%;
    overflow: scroll;
    overflow-x: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-top: 18px;
    margin-bottom: auto;
  }
}

@media (max-width: 640px) {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .editProfile__contentContainer::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 640px) {
  .editProfile__image {
    width: 58px;
    height: 58px;
    border-radius: 58px;
    overflow: hidden;
  }
}

@media (min-width: 641px) {
  .editProfile__image {
    width: 58px;
    height: 58px;
    border-radius: 58px;
    overflow: hidden;
  }
}

@media (max-width: 640px) {
  .editProfile__cameraIconContainer {
    width: 30px;
    height: 30px;
    padding: 0;
  }
  .editProfile__cameraIconContainer:hover {
    cursor: pointer;
  }
  .editProfile__cameraIcon {
    color: #089bab;
  }
}

@media (min-width: 641px) {
  .editProfile__closeIcon {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
}

@media (min-width: 641px) {
  .editProfile__DropdownMultiSelectContainer {
    width: 430px;
    margin-left: auto;
    margin-top: -4px;
  }
}
