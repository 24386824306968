@media (max-width: 640px) {
  .invEquiv__backArrow {
    display: flex;
    align-items: center;
  }

  .invEquiv__backArrowIcon {
    padding-right: 4px;
  }

  .invEquiv__goBackTitle {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(18, 26, 45, 0.5);
    line-height: 1;
    margin: 0;
    padding: 0;
  }

  .invEquiv__title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #121a2d;
    font-size: 20px;
    line-height: 1;
    margin: 24px 0 0 2px;
    padding: 0;
  }

  .invEquiv__breakToNewRow {
    flex-basis: 100%;
    height: 0;
  }
}

@media (min-width: 641px) {
  .invEquiv__subHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 30px;
  }

  .invEquiv__backIcon {
    margin-right: 10px;
    width: 36px;
    height: 36px;
  }

  .invEquiv__descriptionHeading {
    font-style: normal;
    line-height: 44px;
    font-size: 32px;
    margin: 0;
  }

  .invEquiv__addEquivContainer {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .invEquiv__header {
    display: flex;
    flex-wrap: wrap;
    margin-left: 18px;
    margin-top: 1px;
    margin-bottom: 18px;
    margin-right: 22px;
  }
}

@media (min-width: 641px) {
  .invEquiv__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  }
}

@media (max-width: 640px) {
  .invEquiv__main {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    max-height: 100vh;
    margin: 12px 12px 0 12px;
    overflow: hidden;
  }
}

@media (min-width: 641px) {
  .invEquiv__main {
    width: 100%;
  }
}

.invEquiv__container {
  width: 100%;
  margin: 0;
  box-sizing: border-box;
}

@media (max-width: 640px) {
  .invEquiv__container {
    font-family: "Poppins", sans-serif;
    padding: 0 18px;
  }
}

.invEquiv__overlayContainer {
  display: flex;
}

@media (max-width: 640px) {
  .invEquiv__overlayContainer {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

@media (min-width: 641px) {
  .invEquiv__overlayContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(152, 169, 188, 0.3);
    backdrop-filter: blur(46.2108px);
    z-index: 210;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .invEquiv__overlayContainer {
    position: fixed;
    left: 0;
  }
}

@media (min-width: 1008px) {
  .invEquiv__overlayContainer {
    left: -120px; /* Needed to cover vertical menu */
  }
}

.invEquiv__dateStyle {
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-align: right;
  color: #888c96;
  margin: 0;
}

@media (max-width: 640px) {
  .invEquiv__dateStyle {
    font-size: 14px;
  }
}

@media (min-width: 641px) {
  .invEquiv__dateStyle {
    font-size: 16px;
  }
}

@media (max-width: 640px) {
  .invEquiv__headerGroup {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .invEquiv__equivalency {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-weight: 400;
    color: #ffffff;
  }
}

@media (min-width: 641px) {
  .invEquiv__equivalency {
    font-weight: 300;
    color: #454b60;
  }
}

@media (max-width: 640px) {
  .invEquiv__descriptionGroup {
    margin: 0 0 18px 0;
    padding: 0;
  }
}

.invEquiv__descriptionGroup {
  padding: 0;
}

.invEquiv__description {
  font-weight: 400;
}

@media (max-width: 640px) {
  .invEquiv__description {
    margin: 0 0 4px 0;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #121a2d;
  }
}

@media (min-width: 641px) {
  .invEquiv__description {
    color: #089bab;
  }
}

@media (min-width: 641px) {
  .invEquiv__descriptionGroup {
    margin: 0;
  }
}

.invEquiv__IDContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.invEquiv__IDGroup {
  font-style: normal;
  font-weight: normal;
}

@media (max-width: 640px) {
  .invEquiv__IDGroup {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 5px;
  }
}

@media (min-width: 641px) {
  .invEquiv__IDGroup {
    font-size: 18px;
    line-height: 27px;
    padding-right: 36px;
  }
}

@media (max-width: 640px) {
  .invEquiv__IDKey {
    color: #778ca2;
  }
}

@media (min-width: 641px) {
  .invEquiv__ID {
    color: #121a2d;
  }
}

.invEquiv__ID {
  color: #121a2d;
}

.invEquiv__manufacturerGroup {
  font-style: normal;
}

@media (max-width: 640px) {
  .invEquiv__manufacturerGroup {
    font-size: 14px;
    line-height: 1;
  }
}

@media (min-width: 641px) {
  .invEquiv__manufacturerGroup {
    font-size: 18px;
    line-height: 27px;
  }
}

.invEquiv__manufacturerKey {
  color: #778ca2;
  font-weight: 500;
}

.invEquiv__manufacturerName {
  color: #121a2d;
  font-weight: 400;
}

@media (max-width: 640px) {
  .invEquiv__searchBarContainer {
    flex-grow: 1;
    margin-left: 12px;
  }
}

@media (min-width: 641px) {
  .invEquiv__searchBarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding-bottom: 61px;
  }
}

@media (max-width: 640px) {
  .invEquiv__searchBar {
    width: 100%;
    order: 2;
  }
}

@media (min-width: 641px) {
  .invEquiv__searchBar {
    width: inherit;
  }
}

.invEquiv__searchBar::placeholder {
  font-size: 15px;
  line-height: 22px;
  color: #7b818b;
  opacity: 0.5;
}

.invEquiv__addEquivalencyGroup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 640px) {
  .invEquiv__addEquivalencyGroup {
    margin-bottom: 12px;
  }
}

.invEquiv__createBtn {
  width: 27px;
  height: 27px;
  stroke: #089bab;
  fill: none;
  padding-right: 10px;
}

.invEquiv__createBtn:hover {
  cursor: pointer;
}

.invEquiv__addEquivalency {
  font-size: 16px;
  line-height: 24px;
  color: #089bab;
}

@media (max-width: 640px) {
  .invEquiv__nonScrollContent {
    margin: 0 4px 0 4px;
  }
}

@media (min-width: 641px) {
  .invEquiv__nonScrollContent {
    width: 100%;
  }
}
