.refillReportTable__scrollArea {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding-right: 10px;
}

.refillReportTable__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.refillReportTable__searchRowContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 34px;
}

.refillReportTable__searchRow {
  display: flex;
  width: 100%;
}

.refillReportTable__button {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: 123px;
  height: 48px;
  padding: 0 20px;
  color: #ffffff;
  background-color: #089bab;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.refillReportTable__deviceTitleRow {
  display: flex;
  align-items: center;
  width: 100%;
}
.refillReportTable__deviceTitleRow {
  margin-top: 34px;
}

.refillReportTable__backIcon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.refillReportTable__selectedDeviceRow {
  display: flex;
}

.refillReportTable__selectedDeviceRow {
  width: 100%;
}

.refillReportTable__selectedDeviceRowItem {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  color: #252631;
}

.refillReportTable__selectedDeviceRowItem {
  font-size: 18px;
}

.refillReportTable__selectedDeviceRowItem--rightPadded {
  margin-right: 30px;
}

.refillReportTable__view {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.refillReportTable__view {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: 100%;
  /* min-height: 1024px; */
  box-sizing: border-box;
}

.refillReportTable__container {
  display: flex;
  flex-direction: column;
  margin-top: 34px;
}

.refillReportTable__mainHeading {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
  border-bottom: 2px solid rgb(8, 155, 171);
}

.refillReportTable__mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  box-shadow: 0px 5px 14px rgb(120 146 141 / 5%);
  min-height: 79px;
  margin-bottom: 10px;
  border: 1px solid #e8ecef;
  border-radius: 10px;
  cursor: pointer;
}

.refillReportTable__mainContainer--expanded {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.refillReportTable__productInfo {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
}

.refillReportTable__productInfo--expanded {
  box-shadow: 0px 5px 14px rgb(120 146 141 / 5%);
  border: 1px solid #e8ecef;
  border-radius: 10px;
  margin-bottom: 20px;
}

.refillReportTable__mainColumnName {
  font-weight: 500;
  font-size: 14px;
  color: rgb(18, 26, 45);
  margin: 0;
  padding: 0;
}

.refillReportTable__mainColumnName--item1 {
  margin-left: 42px;
  margin-right: 70px;
  white-space: nowrap;
}

.refillReportTable__mainColumnName--item2 {
  margin-right: 30px;
  white-space: nowrap;
}

.refillReportTable__mainColumnName--item3 {
  margin-right: 420px;
}

.refillReportTable__mainColumnName--item4 {
  margin-right: 68px;
}

.refillReportTable__mainColumnName--item5 {
  margin-right: 60px;
}

.refillReportTable__mainColumnName--item6 {
  width: 100%;
  text-align: right;
  margin-right: 86px;
}

.refillReportTable__mainColumnVal--item1 {
  margin-left: 40px;
  margin-right: 34px;
  min-width: 114px;
  width: 114px;
  text-align: left;
  white-space: nowrap;
}

.refillReportTable__mainColumnVal--item2 {
  margin-right: 34px;
  min-width: 124px;
  width: 124px;
  white-space: nowrap;
}

.refillReportTable__mainColumnVal--item3 {
  margin-right: 10px;
  min-width: 460px;
  width: 5px;
  white-space: wrap;
}

.refillReportTable__mainColumnVal--item4 {
  margin-right: 34px;
  min-width: 60px;
  width: 60px;
  text-align: right;
  white-space: nowrap;
}

.refillReportTable__mainColumnVal--item5 {
  min-width: 68px;
  width: 68px;
  text-align: right;
}

.refillReportTable__mainColumnVal--item6 {
  width: 100%;
  text-align: right;
  margin-right: 80px;
}

.refillReportTable__subHeadingsContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.refillReportTable__subHeadingsContainer p {
  color: rgb(8, 155, 171);
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  white-space: nowrap;
}

.refillReportTable__subHeading--item1 {
  margin: 0 170px 0 54px;
}

.refillReportTable__subHeading--item2 {
  margin-right: 100px;
}

.refillReportTable__subHeading--item3 {
  text-align: right;
  margin-right: 78px;
  width: 100%;
}

.refillReportTable__subHeading {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.refillReportTable__subContainer {
  display: flex;
  flex-direction: column;
}

.refillReportTable__subContainer {
  margin-left: 30px;
  margin-right: 30px;
}

.refillReportTable__subColumnVal--item1 {
  margin-left: 24px;
  margin-right: 52px;
  min-width: 200px;
  white-space: nowrap;
}

.refillReportTable__subColumnVal--item2 {
  margin-right: 502px;
  min-width: 210px;
  white-space: nowrap;
}

.refillReportTable__subColumnVal--item3 {
  text-align: right;
  margin-right: 46px;
  width: 100%;
}

.refillReportTable__boxIcon {
  width: 16px;
  height: 16px;
  margin-left: 16px;
  margin-right: 8px;
}

.refillReportTable__boxIconContainer {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: -32px;
}

.refillReportTable__boxIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  min-height: 50px;
  font-size: 12px;
  color: rgb(8, 155, 171);
  border-top: 2px solid rgba(217, 225, 236, 0.3);
}

.refillReportTable__stockedItemContainer {
  display: flex;
  align-items: center;
  color: rgb(8, 155, 171);
  font-size: 14px;
  font-weight: 300;
  border: 1px solid #e8ecef;
  border-radius: 10px;
  box-shadow: 0px 5px 14px rgb(120 146 141 / 5%);
  margin: 0 30px 10px 30px;
}

.refillReportTable__forceLineBreak {
  display: block;
}
