.addAP__container {
  display: flex;
  flex-direction: column;
  margin: 100px auto;
  width: 1000px;
  height: 739px;
  background-color: white;
  border: 1px solid white;
  border-radius: 20px;
  z-index: 50;
}

.addAP__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.addAP__title {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 27px;
  color: #121a2d;
  margin-left: 66px;
  margin-top: 64px;
}

.addAP__closeIconContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin-top: 35px;
  margin-right: 36px;
}

.addAP__closeIcon {
  fill: #121a2d;
  cursor: pointer;
}

.addAP__searchBarContainer {
  margin-left: 66px;
  margin-bottom: 58px;
}

.addAP__productsHeadingsContainer {
  margin-left: 66px;
  margin-right: 66px;
}

.addAP__errorBannerContainer {
  position: absolute;
  width: 1000px;
}

.backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(152, 169, 188, 0.3);
  backdrop-filter: blur(46.2108px);
}

.modal {
  position: fixed;
  top: 21vh;
  width: 100%;
  background-color: transparent;
  z-index: 25;
}
