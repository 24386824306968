.BarcodeProperties__container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 6px;
}

/* general input container */
.BarcodeProperties__inputContainer {
  width: 54px;
}

/* general input */
.BarcodeProperties__inputContainer input {
  text-align: right;
  padding-right: 5px;
}

/* general boxes container */
.BarcodeProperties__boxesContainer {
  display: flex;
  align-items: center;
}

.BarcodeProperties__boxesContainer--textStyles {
  margin-top: 5px;
}

/* position */
.BarcodeProperties__positionContainer {
  display: flex;
  justify-content: space-between;
}

.BarcodeProperties__xBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
}

.BarcodeProperties__xPositionInputContainer {
  width: 54px;
}

.BarcodeProperties__yBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BarcodeProperties__yPositionInputContainer {
  width: 54px;
}

/* size */
.BarcodeProperties__sizeContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.BarcodeProperties__widthBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
}

.BarcodeProperties__widthInputContainer {
  width: 54px;
}

.BarcodeProperties__heightBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BarcodeProperties__heightInputContainer {
  width: 54px;
}

.BarcodeProperties__symbologyContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.BarcodeProperties__symbologyContainer div {
  width: 100%;
}

/* general p */
.BarcodeProperties__generalParagraph {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  padding: 0;
  margin: 0;
  line-height: 1;
  color: var(--black);
}

/* general p span */
.BarcodeProperties__container p span {
  display: block;
  font-size: 10px;
  margin-top: 3px;
}

.BarcodeProperties__positionContainer p,
.BarcodeProperties__sizeContainer p {
  margin-top: 8px;
}

/* propertyName container */
.BarcodeProperties__propertyNameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.BarcodeProperties__propertyNameContainer .BarcodeProperties__inputContainer {
  margin-left: 5px;
  width: 100%;
}

/* defaultValue container */
.BarcodeProperties__defaultValueContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.BarcodeProperties__defaultValueContainer .BarcodeProperties__inputContainer {
  margin-left: 5px;
  width: 100%;
}

/* showValueContainer */

.BarcodeProperties__showValueContainer {
  display: flex;
  align-items: center;
  margin-top: 30px;
  font-size: 24px;
}

.BarcodeProperties__showValueCheckBoxContainer {
  width: 18px;
  height: 1px;
  display: flex;
  align-items: center;
  margin-right: 3px;
}

.BarcodeProperties__showValueCheckBoxContainer svg {
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0;
}

.BarcodeProperties__trashIconContainer {
  position: absolute;
  right: 8px;
  top: -43px;
  cursor: pointer;
}

.BarcodeProperties__trashIconContainer svg {
  fill: #d9534f;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in-out;
}

.BarcodeProperties__trashIconContainer:hover svg {
  transform: scale(1.1);
}

.BarcodeProperties__canisterDataMapping {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.BarcodeProperties__canisterDataMapping div {
  width: 100%;
}

.BarcodeProperties__barcodeValueError {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  padding: 6px 5px 0 0;
  margin: 0;
  line-height: 1;
  color: #d9534f;
  width: 100%;
  text-align: right;
  box-sizing: border-box;
}

.BarcodeProperties__symbologyDropdownOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 10;
}

.BarcodeProperties__symbologyDropdownArrowOverlay {
  position: absolute;
  left: 226px;
  top: 24px;
  max-width: 20px;
  height: 16px;
  background-color: #fff;
}

option {
  font-size: 12px !important;
  color: var(--black);
  background: transparent;
  font-style: bold;
}
