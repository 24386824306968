@media (min-width: 641px) {
  .DeliverySchedule__overlay {
    display: block;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    background: rgba(152, 169, 188, 0.3);
    -webkit-backdrop-filter: blur(46.2108px);
    backdrop-filter: blur(46.2108px);
    z-index: 40;
  }

  .DeliverySchedule__view {
    display: flex;
    flex-direction: column;
    width: 1000px;
    height: 739px;
    background-color: white;
    border: 1px solid white;
    border-radius: 20px;
    margin: 100px auto;
    z-index: 50;
  }
  .DeliverySchedule__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 66px;
    height: 100px;
  }
  .DeliverySchedule__body {
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
    margin-right: 66px;
    margin-left: 66px;
  }
  .DeliverySchedule__dayBoxesContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
  .DeliverySchedule__dayBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 5px;
  }
  .DeliverySchedule__title {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 27px;
    color: #121a2d;
    user-select: none;
  }
  .DeliverySchedule__closeIcon {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
  .DeliverySchedule__footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 66px;
  }
  .DeliverySchedule__time {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid var(--black);
    width: 90%;
    outline: none;
  }
}

@media (max-width: 640px) {
  .DeliverySchedule__view {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
  }
  .DeliverySchedule__body {
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
  }
  .DeliverySchedule__dayBoxesContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    margin-top: 16px;
  }
  .DeliverySchedule__dayBox {
    display: flex;
    flex-direction: column;
    max-width: 120px;
    margin-bottom: 34px;
    padding-right: 30px;
  }
  .DeliverySchedule__title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #121a2d;
    font-size: 20px;
    line-height: 1;
    margin: 24px 0 0 2px;
    padding: 0;
  }
  .DeliverySchedule__closeIcon {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
  .DeliverySchedule__footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .DeliverySchedule__time {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid var(--black);
    width: 90%;
    outline: none;
  }

  .DeliverySchedule__header {
    margin-bottom: 24px;
  }

  .DeliverySchedule__backArrow {
    display: flex;
    align-items: center;
  }

  .DeliverySchedule__backArrowIcon {
    padding-right: 4px;
  }

  .DeliverySchedule__goBackTitle {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(18, 26, 45, 0.5);
    line-height: 1;
    margin: 0;
    padding: 0;
  }

  .DeliverySchedule__lineBreak {
    display: block;
    margin-top: 4px;
  }
}
