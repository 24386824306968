@media only screen and (max-width: 640px) {
  .header {
    margin-left: 18px;
    margin-top: 1px;
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 641px) {
  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    min-height: 120px;
    width: 720px;
    box-sizing: border-box;
    padding-left: 60px;
    padding-right: 60px;
    background-color: #089bab;
  }
  .header--fullBottomBorderRadius {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

@media only screen and (min-width: 641px) {
  .header {
    height: 260px;
  }
  .header--short {
    height: 141px;
  }
}
