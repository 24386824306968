@media (min-width: 641px) {
  .MenuItem__container {
    outline: none;
    border: 1px solid transparent;
  }

  .MenuItem__container:focus,
  .MenuItem__container:active {
    border: 1px solid white;
    border-radius: 8px;
  }
}
