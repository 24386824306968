.videoView__buttonGroup {
  margin-top: auto;
  padding-top: 30px;
  text-align: center;
}

.videoView__loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(50% - 100px);
  left: 0;
  right: 0;
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.videoView__loader svg {
  margin-top: 40px;
}

.videoView__videoPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.videoView__videoPlayerContainer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1600px;
  position: relative;
}

@media (max-width: 640px) {
  .videoView__videoPlayerContainer {
    margin-top: 24px;
    max-width: 100%;
  }
}
