.accountSetup__container {
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.accountSetup__scrollArea {
  padding: 4px;
  height: 100%;
  max-height: 790px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

.accountSetup__scrollArea::-webkit-scrollbar-track {
  margin-top: 44px;
  margin-bottom: 44px;
}

.accountSetup__formBody {
  display: flex;
  flex-direction: column;
}

@media (min-width: 641px) {
  .accountSetup__formBody {
    margin-left: 40px;
    margin-right: 24px;
  }
}

.accountSetup__groupedAccordion {
  position: relative;
}

.accountSetup__groupedAccordion--noBottomMargin {
  margin-bottom: -40px;
}

@media (max-width: 640px) {
  .accountSetup__accordions {
    width: 100%;
  }
}

@media (min-width: 641px) {
  .accountSetup__accordions {
    width: 890px;
  }
}

.accountSetup__formView {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}

@media (max-width: 640px) {
  .accountSetup__formView {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 12px;
  }
}

@media (min-width: 641px) {
  .accountSetup__formView {
    width: 1000px;
    margin: auto 0;
    margin-top: 70px;
    margin-bottom: 34px;
    border: 1px solid white;
    border-radius: 20px;
  }
}

.accountSetup__formHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 66px;
  margin-top: 64px;
  margin-bottom: 51px;
}

/* .accountSetup__formFooter {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 60px;
  margin-left: 50px;
  margin-right: 108px;
  margin-bottom: 58px;
} */

.accountSetup__footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .accountSetup__footer {
    justify-content: center;
    align-items: center;
    margin-top: auto;
    padding-top: 25px;
  }
}

@media (min-width: 641px) {
  .accountSetup__footer {
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 38px;
    margin-left: 50px;
    margin-right: 108px;
    margin-bottom: 51px;
  }
}

.accountSetup__formCloseContainer {
  position: absolute;
  top: 35px;
  right: 36px;
}

.accountSetup__formCloseContainer:hover {
  cursor: pointer;
}

.accountSetup__checkboxContainer {
  position: absolute;
  top: 12px;
  right: 0;
}

.accountSetup__checkboxContainer--2 {
  z-index: 85;
}

.accountSetup__checkboxContainer--3 {
  z-index: 75;
}

.accountSetup__accordion {
  position: relative;
  margin-bottom: 40px;
}

.accountSetup__accordion:last-child {
  position: relative;
  margin-bottom: 0;
}

.accountSetup__accordion--0 {
  z-index: 100;
}

.accountSetup__accordion--1 {
  z-index: 90;
}

.accountSetup__accordion--2 {
  z-index: 80;
}

.accountSetup__accordion--3 {
  z-index: 70;
}

@media (max-width: 640px) {
  .accountSetup__header {
    margin-left: 18px;
    margin-top: 1px;
    margin-bottom: 24px;
  }
}

@media (max-width: 640px) {
  .accountSetup__backArrow {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .accountSetup__goBackTitle {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(18, 26, 45, 0.5);
    line-height: 1;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 640px) {
  .accountSetup__backArrowIcon {
    padding-right: 4px;
  }
}

@media (max-width: 640px) {
  .accountSetup__title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #121a2d;
    font-size: 20px;
    line-height: 1;
    margin: 24px 0 0 2px;
    padding: 0;
  }
}
