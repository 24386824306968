.ControlMedTag {
  padding: 10px 25px;
  border: 2px solid rgb(255, 0, 0);
}

.ControlMedTagText {
  color: rgb(255, 0, 0);
}

.PartialMedTag {
  padding: 10px 25px;
  border: 2px solid rgb(250, 128, 114);
}

.PartialMedTagText {
  color: rgb(250, 128, 114);
}