.EV54Device__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
}

@media (min-width: 641px) {
  .EV54Device__container {
    max-height: 700px;
  }
}

.EV54Device__container p {
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #121a2d;
}

.EV54Device__header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.EV54Device__tabContainer {
  display: flex;
  width: 600px;
  min-width: 500px;
}

.EV54Device__actionsIconContainer {
  display: flex;
  color: #121a2d;
  /*opacity: 0.5;*/
}

.EV54Device__popupContainer {
  position: relative;
}
