.confirmation__container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media only screen and (max-width: 640px) {
  .confirmation__container {
    flex: 1 0 auto;
  }
}

@media only screen and (min-width: 641px) {
  .confirmation__container {
    margin-top: 40px;
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 43px;
  }
}

.confirmation__heading {
  margin-top: 12px;
  margin-bottom: 4px;
  font-family: "Poppins", sans-serif;
  color: #093a64;
  font-weight: 500;
  font-size: 16px;
}

@media only screen and (min-width: 641px) {
  .confirmation__heading {
    margin-top: 28px;
    margin-bottom: 16px;
  }
}

.confirmation__buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin-top: auto;
}

@media only screen and (max-width: 640px) {
  .confirmation__buttons {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 641px) {
  .confirmation__buttons {
    margin-bottom: 30px;
  }
}

.confirmation__button {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-right: 0;
  margin-left: 0;
  border: none;
}

@media only screen and (max-width: 640px) {
  .confirmation__button {
    height: 48px;
    width: 122px;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 641px) {
  .confirmation__button {
    width: 100%;
    height: 48px;
    border-radius: 10px;
  }
}

.confirmation__button--create {
  color: rgb(255, 255, 255);
  background-color: rgb(8, 155, 171);
}

@media only screen and (max-width: 640px) {
  .confirmation__button--create {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 641px) {
  .confirmation__button--create {
    margin-bottom: 0;
    width: 213px;
  }
}

.confirmation__button--continue {
  color: rgb(255, 255, 255);
  background-color: rgb(8, 155, 171);
}

@media only screen and (min-width: 641px) {
  .confirmation__button--continue {
    margin: 0 auto;
    width: 213px;
  }
}

.confirmation__button--cancel {
  background-color: rgb(231, 231, 232);
}

@media only screen and (min-width: 641px) {
  .confirmation__button--cancel {
    width: 138px;
    margin-right: 20px;
  }
}

.confirmation__stockLocation {
  font-weight: 600;
  font-style: italic;
}

.confirmation__confirmationMsgBox {
  padding: 10px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  border-radius: 6px;
}

@media only screen and (min-width: 641px) {
  .confirmation__confirmationMsgBox {
    padding: 30px 30px;
    margin-top: 0;
  }
}

.confirmation__confirmationMsg {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #363f4c;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

@media only screen and (min-width: 641px) {
  .confirmation__confirmationMsg {
    margin-bottom: 20px;
  }
}
