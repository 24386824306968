.BarcodeComponent__barcodeContainer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2px;
}

.BarcodeComponent__defaultValue {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: var(--black);
  padding: 0;
  margin: -22px 0 0 0;
  line-height: 2;
  font-size: 10px;
}
