.users__row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

@media (max-width: 640px) {
  .users__row {
    flex-direction: column;
    align-items: flex-start;
  }
}

.users__actionsHeading {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 29px;
}

.users__overlay {
  display: block;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(152, 169, 188, 0.3);
  backdrop-filter: blur(46.2108px);
  z-index: 2;
  overflow: hidden;
}

.users__scroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

@media (min-width: 641px) {
  .users__scroll {
    padding-right: 10px;
  }
}

@media (max-width: 640px) {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .users__scroll:-webkit-scrollbar {
    display: none;
  }
}

.users__listItem {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 0;
  margin-bottom: 10px;
  border: 1px solid #e8ecef;
  box-sizing: border-box;
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  color: #121a2d;
  padding: 0;
  list-style: none;
}

@media (max-width: 640px) {
  .users__listItem {
    font-size: 14px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .users__listItem {
    height: 48px;
    min-height: 48px;
    font-size: 13px;
  }
}

@media (min-width: 1008px) {
  .users__listItem {
    min-height: 80px;
    height: 80px;
    font-size: 14px;
  }
}

.users__listItem li {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.users__listItem .users__actionsContainer {
  overflow: visible;
}

.users__listItem--lightGray {
  background-color: lightgray;
  opacity: 0.5;
}

.users__roleContainer {
  border-radius: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  cursor: pointer;
}

@media (min-width: 1008px) {
  .users__roleContainer {
    justify-content: flex-start;
  }
}

.users__role {
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  border: 1px solid #fff;
  box-sizing: border-box;
}

@media (max-width: 640px) {
  .users__role {
    width: 26px;
    height: 26px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .users__role {
    width: 27px;
    height: 27px;
  }
}

@media (min-width: 1008px) {
  .users__role {
    width: 38px;
    height: 38px;
  }
}

.users__roleText {
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  color: #000000;
}

@media (max-width: 640px) {
  .users__roleText {
    font-size: 8px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .users__roleText {
    font-size: 8px;
  }
}

@media (min-width: 1008px) {
  .users__roleText {
    font-size: 14px;
  }
}

.users__dataCardsContainer {
  height: 100%;
  overflow: scroll;
  overflow-x: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-top: 18px;
  margin-bottom: auto;
}

.users__dataCardsContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.users__dataCardsContainer::-webkit-scrollbar {
  display: none;
}

.users__actions {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  text-align: right;
  position: relative;
  margin-right: 30px;
}

@media (max-width: 640px) {
  .users__actions {
    margin-top: 6px;
  }
}

@media (max-width: 640px) {
  .users__actionsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    position: relative;
    height: 30px;
  }
}

@media (max-width: 640px) {
  .users__createButtonContainer {
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media (min-width: 641px) {
  .users__createButtonContainer {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 640px) {
  .users__searchBarContainer {
    position: absolute;
    top: 0;
    width: 78%;
    padding-left: 32px;
    box-sizing: border-box;
    z-index: 5;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .users__searchBarContainer {
    flex-grow: 1;
    margin-right: 20px;
  }
}

@media (min-width: 1008px) {
  .users__searchBarContainer {
    width: 100%;
  }
}

.users__profilePic {
  border-radius: 30px;
  margin-right: 8px;
}

@media (max-width: 640px) {
  .users__profilePic {
    width: 28px;
    height: 28px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .users__profilePic {
    width: 28px;
    height: 28px;
  }
}

@media (min-width: 1008px) {
  .users__profilePic {
    width: 40px;
    height: 40px;
  }
}

.users__tableHeader {
  padding-left: 0;
  list-style: none;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #121a2d;
  margin-left: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
}

@media (max-width: 640px) {
  .users__tableHeader {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .users__tableHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    border-bottom: 2px solid #089bab;
    margin-left: 1px;
    padding-bottom: 13px;
    font-size: 13px;
  }
}

@media (min-width: 1008px) {
  .users__tableHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 2px solid #089bab;
    padding-bottom: 14px;
    font-size: 14px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .users__nameHeader {
    min-width: 174px;
    width: 174px;
    margin-left: 10px;
  }

  .users__rolesHeader {
    min-width: 107px;
    width: 107px;
  }

  .users__defaultSiteHeader {
    min-width: 138px;
    width: 138px;
  }

  .users__emailHeader {
    box-sizing: border-box;
    min-width: 270px;
    width: 270px;
    margin-right: 10px;
  }

  .users__phoneNumberHeader {
    min-width: 102px;
    width: 102px;
  }

  .users__profilePicData {
    margin-left: 10px;
  }

  .users__nameData {
    min-width: 127px;
    width: 127px;
    margin-right: 10px;
  }

  .users__defaultSiteData {
    min-width: 127px;
    width: 127px;
    margin-right: 10px;
  }

  .users__rolesData {
    min-width: 97px;
    width: 97px;
    margin-right: 10px;
    flex-wrap: nowrap;
  }

  .users__emailData {
    box-sizing: border-box;
    min-width: 270px;
    width: 270px;
    margin-right: 10px;
  }

  .users__phoneNumberData {
    min-width: 120px;
    width: 120px;
    margin-right: 10px;
  }

  .users__actionsContainer {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    text-align: right;
    position: relative;
    padding-right: 14px;
  }
}

@media (max-width: 640px) {
  .users__tableHeader {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 1008px) {
  .users__nameHeader {
    min-width: 263px;
    width: 263px;
    margin-left: 100px;
  }

  .users__rolesHeader {
    min-width: 126px;
    width: 126px;
  }

  .users__defaultSiteHeader {
    min-width: 216px;
    width: 216px;
  }

  .users__emailHeader {
    min-width: 335px;
    width: 335px;
  }

  .users__phoneNumberHeader {
    min-width: 200px;
    width: 200px;
  }

  .users__profilePicData {
    min-width: 80px;
    width: 80px;
    justify-items: center;
    align-items: flex-start;
    margin-left: 20px;
  }

  .users__nameData {
    min-width: 176px;
    width: 176px;
    margin-right: 40px;
    overflow: hidden;
  }

  .users__defaultSiteData {
    min-width: 176px;
    width: 176px;
    margin-right: 40px;
    overflow: hidden;
  }

  .users__rolesData {
    min-width: 130px;
    width: 130px;
    margin-right: 40px;
    flex-wrap: nowrap;
  }

  .users__emailData {
    min-width: 290px;
    width: 290px;
    margin-right: 40px;
  }

  .users__phoneNumberData {
    min-width: 190px;
    width: 190px;
    margin-right: 10px;
  }

  .users__actionsContainer {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    text-align: right;
    position: relative;
    margin-right: 30px;
  }
}

.users__moreRoles {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  background-color: #e5eaed;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  border: 1px solid #fff;
  cursor: pointer;
}

@media (max-width: 1007px) {
  .users__moreRoles {
    height: 27px;
    width: 27px;
  }
}

.users__overflowHidden {
  overflow: hidden;
  white-space: nowrap;
}

.users__actionsContainer:hover {
  cursor: pointer;
}

.users__popupContainer {
  position: relative;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .users__popupContainer {
    top: var(--popupTopMd);
    right: var(--popupRightMd);
  }
}

@media (min-width: 1008px) {
  .users__popupContainer {
    top: var(--popupTopLg);
    right: var(--popupRightLg);
  }
}

@media only screen and (max-width: 640px) {
  .users__errorBannerContainer {
    margin: 0 10px 10px 10px;
    position: relative;
    z-index: 210;
  }
}

@media only screen and (min-width: 641px) {
  .users__errorBannerContainer {
    width: 100%;
    margin-bottom: 12px;
    z-index: 250;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1007px) {
  .users__errorBannerContainer {
    margin-top: 35px;
  }
}

@media only screen and (min-width: 1008px) {
  .users__errorBannerContainer {
    margin-top: 70px;
  }
}
