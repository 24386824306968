.RepeaterProperties__container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 6px;
}

/* general input container */
.RepeaterProperties__inputContainer {
  width: 54px;
}

/* general input */
.RepeaterProperties__inputContainer input {
  text-align: right;
  padding-right: 5px;
}

/* general boxes container */
.RepeaterProperties__boxesContainer {
  display: flex;
  align-items: center;
}

.RepeaterProperties__boxesContainer--textStyles {
  margin-top: 5px;
}

/* padding */
.RepeaterProperties__topAndRightPaddingContainer,
.RepeaterProperties__bottomAndLeftPaddingContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.RepeaterProperties__topPaddingBoxContainer,
.RepeaterProperties__bottomPaddingBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 24px;
  margin-right: 12px;
}

/* row gap */
.RepeaterProperties__rowGapContainer {
  display: flex;
  margin-bottom: 18px;
}

.RepeaterProperties__rowGapBoxContainer {
  margin-left: 74px;
}

.RepeaterProperties__rightPaddingBoxContainer,
.RepeaterProperties__leftPaddingBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* position */
.RepeaterProperties__positionContainer {
  display: flex;
  justify-content: space-between;
}

.RepeaterProperties__xBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
}

.RepeaterProperties__xPositionInputContainer {
  width: 54px;
}

.RepeaterProperties__yBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RepeaterProperties__yPositionInputContainer {
  width: 54px;
}

/* size */
.RepeaterProperties__sizeContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.RepeaterProperties__widthBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
}

.RepeaterProperties__widthInputContainer {
  width: 54px;
}

.RepeaterProperties__heightBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RepeaterProperties__heightInputContainer {
  width: 54px;
}

.RepeaterProperties__orientationContainer,
.RepeaterProperties__repeatCountContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 34px;
}

.RepeaterProperties__orientationContainer div,
.RepeaterProperties__repeatCountContainer div {
  width: 100%;
}

/* general p */
.RepeaterProperties__container p {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  padding: 0;
  margin: 0;
  line-height: 1;
  color: var(--black);
}

/* general p span */
.RepeaterProperties__container p span {
  display: block;
  font-size: 10px;
  margin-top: 3px;
}

.RepeaterProperties__positionContainer p,
.RepeaterProperties__sizeContainer p,
.RepeaterProperties__topAndRightPaddingContainer p,
.RepeaterProperties__bottomAndLeftPaddingContainer p {
  margin-top: 8px;
}

/* propertyName container */
.RepeaterProperties__propertyNameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.RepeaterProperties__propertyNameContainer .RepeaterProperties__inputContainer {
  margin-left: 5px;
  width: 100%;
}

.RepeaterProperties__trashIconContainer {
  position: absolute;
  right: 8px;
  top: -43px;
  cursor: pointer;
}

.RepeaterProperties__trashIconContainer svg {
  fill: #d9534f;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in-out;
}

.RepeaterProperties__trashIconContainer:hover svg {
  transform: scale(1.1);
}
