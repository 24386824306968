@media (max-width: 640px) {
  .fillList__scrollArea {
    height: 100%;
    overflow: scroll;
    overflow-x: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-top: 18px;
    margin-bottom: auto;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .fillList__scrollArea::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 641px) {
  .fillList__scrollArea {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    padding-right: 10px;
  }
}

.fillList__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.fillList__searchRowContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 34px;
}

.fillList__searchRow {
  display: flex;
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .fillList__searchBarContainer {
    position: absolute;
    top: 0;
    width: 80%;
    padding-left: 32px;
    box-sizing: border-box;
    z-index: 6;
  }
}

.fillList__button {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: 123px;
  height: 48px;
  padding: 0 20px;
  color: #ffffff;
  background-color: #089bab;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.fillList__deviceTitleRow {
  display: flex;
  align-items: center;
  width: 100%;
}

@media (max-width: 640px) {
  .fillList__deviceTitleRow {
    justify-content: center;
    margin-top: 24px;
  }
}

@media (min-width: 641px) {
  .fillList__deviceTitleRow {
    margin-top: 34px;
  }
}

.fillList__backIcon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

@media (max-width: 640px) {
  .fillList__backIcon {
    width: 26px;
    height: 26px;
    margin-right: 8px;
  }
}

.fillList__selectedDeviceRow {
  display: flex;
}

@media (min-width: 641px) {
  .fillList__selectedDeviceRow {
    width: 100%;
  }
}

.fillList__selectedDeviceRowItem {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  color: #252631;
}

@media (max-width: 640px) {
  .fillList__selectedDeviceRowItem {
    font-size: 16px;
  }
}

@media (min-width: 641px) {
  .fillList__selectedDeviceRowItem {
    font-size: 18px;
  }
}

@media (max-width: 640px) {
  .fillList__selectedDeviceRowItem--rightPadded {
    margin-right: 6px;
  }
}

@media (min-width: 641px) {
  .fillList__selectedDeviceRowItem--rightPadded {
    margin-right: 30px;
  }
}
.fillList__view {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 640px) {
  .fillList__view {
    flex: 1 1;
    height: 100vh;
  }
}

@media (min-width: 641px) {
  .fillList__view {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100%;
    /* min-height: 1024px; */
    box-sizing: border-box;
  }
}

.fillList__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 640px) {
  .fillList__container {
    max-height: 90vh;
  }
}

@media (min-width: 641px) {
  .fillList__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 34px;
  }
}

.fillList__mainHeading {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
  border-bottom: 2px solid rgb(8, 155, 171);
}

.fillList__mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  box-shadow: 0px 5px 14px rgb(120 146 141 / 5%);
  min-height: 79px;
  margin-bottom: 10px;
  border: 1px solid #e8ecef;
  border-radius: 10px;
  cursor: pointer;
}

.fillList__mainContainer--expanded {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.fillList__productInfo {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
}

.fillList__productInfo--expanded {
  box-shadow: 0px 5px 14px rgb(120 146 141 / 5%);
  border: 1px solid #e8ecef;
  border-radius: 10px;
  margin-bottom: 20px;
}

.fillList__mainColumnName {
  font-weight: 500;
  font-size: 14px;
  color: rgb(18, 26, 45);
  margin: 0;
  padding: 0;
}

.fillList__mainColumnName--item1 {
  margin-left: 42px;
  margin-right: 140px;
}

.fillList__mainColumnName--item1b {
  margin-right: 100px;
}

.fillList__mainColumnName--item2 {
  margin-right: 240px;
}

.fillList__mainColumnName--item3 {
  margin-right: 96px;
}

.fillList__mainColumnName--item4 {
  margin-right: 154px;
}

.fillList__mainColumnName--item5 {
  margin-right: 172px;
}

.fillList__mainColumnName--item6 {
  margin-right: 150px;
}

.fillList__mainColumnName--item7 {
  margin-right: 150px;
}

.fillList__mainColumnName--item8 {
  width: 100%;
  text-align: right;
  margin-right: 86px;
}

.fillList__mainColumnVal--item1 {
  margin-left: 40px;
  margin-right: 50px;
  min-width: 152px;
  white-space: nowrap;
}

.fillList__mainColumnVal--item1b {
  margin-right: 50px;
  min-width: 111px;
  white-space: nowrap;
}

.fillList__mainColumnVal--item2 {
  margin-right: 50px;
  min-width: 270px;
  white-space: nowrap;
}

.fillList__mainColumnVal--item3 {
  margin-right: 90px;
  min-width: 56px;
  text-align: center;
}

.fillList__mainColumnVal--item4 {
  margin-right: 50px;
  min-width: 142px;
  white-space: nowrap;
}

.fillList__mainColumnVal--item5 {
  margin-right: 50px;
  min-width: 130px;
}

.fillList__mainColumnVal--item6 {
  margin-right: 130px;
  min-width: 52px;
  text-align: right;
}

.fillList__mainColumnVal--item7 {
  margin-right: 50px;
  min-width: 50px;
  text-align: right;
}

.fillList__mainColumnVal--item8 {
  width: 100%;
  text-align: right;
  margin-right: 60px;
}

.fillList__subHeading {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.fillList__subContainer {
  display: flex;
  flex-direction: column;
}

@media (max-width: 640px) {
  .fillList__subContainer {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (min-width: 641px) {
  .fillList__subContainer {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.fillList__subColumnName {
  font-weight: 500;
  font-size: 14px;
  color: #089bab;
  margin: 0;
  padding: 0;
}

.fillList__subColumnName--item1 {
  margin-left: 30px;
  margin-right: 278px;
}

.fillList__subColumnName--item2 {
  margin-right: 258px;
}

.fillList__subColumnName--item3 {
  margin-right: 50px;
}

.fillList__subColumnName--item4 {
  width: 100%;
  text-align: right;
  margin-right: 30px;
}

@media (max-width: 640px) {
  .fillList__subColumnVal--item1 {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (min-width: 641px) {
  .fillList__subColumnVal--item1 {
    margin-left: 30px;
    margin-right: 52px;
    min-width: 260px;
    white-space: nowrap;
  }
}

.fillList__subColumnVal--item2 {
  margin-right: 52px;
  min-width: 250px;
  white-space: nowrap;
}

.fillList__subColumnVal--item3 {
  margin-right: 50px;
  min-width: 250px;
  white-space: nowrap;
}

.fillList__subColumnVal--item4 {
  width: 100%;
  text-align: right;
  margin-right: 30px;
}

.fillList__boxIcon {
  width: 16px;
  height: 16px;
  margin-left: 16px;
  margin-right: 8px;
}

.fillList__boxIconContainer {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: -32px;
}

.fillList__boxIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  min-height: 50px;
  font-size: 12px;
  color: rgb(8, 155, 171);
  border-top: 2px solid rgba(217, 225, 236, 0.3);
}

.fillList__stockedItemContainer {
  display: flex;
  align-items: center;
  color: rgb(8, 155, 171);
  font-size: 14px;
  font-weight: 300;
  border: 1px solid #e8ecef;
  border-radius: 10px;
  box-shadow: 0px 5px 14px rgb(120 146 141 / 5%);
  margin-bottom: 10px;
}

@media (max-width: 640px) {
  .fillList__externalItems {
    margin: 0 0 0 0;
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    color: #121a2d;
    font-family: "Poppins", sans-serif;
    line-height: 1;
    font-weight: 400;
    font-size: 16px;
  }
}
