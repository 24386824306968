.button {
  color: #ffffff;
  background-color: #089bab;
  height: 48px;
  border: none;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.button--inverse {
  background: #ffffff;
  color: #089bab;
}

@media only screen and (max-width: 640px) {
  .button {
    width: 100%;
    margin: 20px 0 18px 0;
  }
}

@media only screen and (min-width: 641px) {
  .button {
    width: 213px;
    margin: auto;
    /* margin-top: 40px; */
    margin-bottom: 40px;
  }
}
