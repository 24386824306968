/* .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(152, 169, 188, 0.3);
  backdrop-filter: blur(46.2108px);
} */

@media only screen and (min-width: 641px) {
  .backdrop {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-height: 1014px;
    background-color: rgba(152, 169, 188, 0.3);
    backdrop-filter: blur(46.2108px);
    z-index: 70;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1007px) {
  .backdrop {
    left: -70px;
  }
}

@media only screen and (min-width: 1008px) {
  .backdrop {
    left: -120px;
  }
}
