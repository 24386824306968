.AddRetailScript__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.AddRetailScript__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.AddRetailScript__header h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    color: #089BAB;
    margin: 0;
}

.AddRetailScript__header span {
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 44px;
    color: #454B60;
}

.AddRetailScript__col {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
}

.AddRetailScript__row {
    display: flex;
    width: 100%;
}

.AddRetailScript__footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}
