.stockLocations__mainView {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.stockLocations__flexContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.stockLocations__flexContainer--spaceBetween {
  justify-content: space-between;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .stockLocations__overlay {
    width: 100vw;
    left: -70px;
    position: absolute;
    min-height: 670px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: rgba(152, 169, 188, 0.3);
    backdrop-filter: blur(46.2108px);
    z-index: 2;
  }
}

@media (min-width: 1008px) {
  .stockLocations__overlay {
    display: block;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    background-color: rgba(152, 169, 188, 0.3);
    backdrop-filter: blur(46.2108px);
    z-index: 2;
  }
}

.stockLocations__backIcon {
  cursor: pointer;
}

.stockLocations__title {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 49px;
  color: #089bab;
  user-select: none;
}

@media (max-width: 640px) {
  .stockLocations__title {
    font-family: "Poppins", sans-serif;
    color: #121a2d;
    font-size: 20px;
    line-height: 1;
    margin: 24px 0 0 2px;
    padding: 0;
  }
}

.stocklocations__subtitle {
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 49px;
  color: black;
  user-select: none;
}

@media (max-width: 640px) {
  .stocklocations__subtitle {
    font-size: 20px;
    line-height: 1;
    margin: 24px 0 0 2px;
    padding: 0;
  }
}

.stockLocationsMobile__mainContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: hidden;
  box-sizing: border-box;
  margin: 16px 16px 0 16px;
}

.stockLocationsMobile__header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 18px;
  margin-top: 1px;
  margin-bottom: 18px;
  margin-right: 22px;
}

.stockLocationsMobile__backAndSearch {
  display: flex;
  align-items: center;
  width: 100%;
}

.stockLocationsMobile__searchBarContainer {
  flex-grow: 1;
  margin-left: 12px;
}

.stockLocationsMobile__backArrow {
  display: flex;
  align-items: center;
}

.stockLocationsMobile__backArrowIcon {
  padding-right: 4px;
}

.stockLocationsMobile__goBackTitle {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: rgba(18, 26, 45, 0.5);
  line-height: 1;
  margin: 0;
  padding: 0;
}

.stockLocationsMobile__createButtonContainer {
  margin-top: 18px;
  margin-left: -12px;
}

.stockLocationsMobile__CreateStockLocationContainer {
  margin: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
