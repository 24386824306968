.reportContainer {
  overflow: auto;
}

.productsContainer {
  display: flex;
  align-items: center;
}

.productsContainer p:not(last-child) {
  padding-right: 50px;
}

.batchContainer {
  background: #ffffff;
  border: 1px solid #e8ecef;
  box-shadow: 0 5px 14px rgba(120, 146, 141, 0.05);
  border-radius: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 4px 20px 12px 20px;
}

.DataMatrixContainer {
  height: 46px;
  overflow: hidden;
}

.DataMatrixContainer svg {
  margin-left: -16px;
}

.QRBarcodeContainer {
  height: 46px;
  overflow: hidden;
}

.QRBarcodeContainer svg {
  margin-left: -16px;
}

.QRBarcodeContainer svg {
  margin-left: -16px;
}

.Code128AContainer {
  height: 46px;
  overflow: hidden;
}

.Code128AContainer svg {
  margin-left: -16px;
}

.Code128AContainer svg {
  margin-left: -13px;
}

.Code128BContainer {
  height: 46px;
  overflow: hidden;
}

.Code128BContainer svg {
  margin-left: -13px;
}

.loader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #121a2d;
}

.loader svg {
  margin-top: 40px;
}

.searchBarContainer {
  position: absolute;
  top: 16px;
  left: 72px;
}

@media (max-width: 1150px) {
  .barcodeValueSelectionContainer,
  .barcodeSymbologySelectionContainer {
    display: none;
  }
}

@media (min-width: 1151px) {
  .barcodeValueSelectionContainer {
    position: absolute;
    top: 13px;
    left: 680px;
    width: 138px;
  }
}

@media (min-width: 1151px) {
  .barcodeSymbologySelectionContainer {
    position: absolute;
    top: 13px;
    left: 860px;
    width: 138px;
  }
}

@media (min-width: 1151px) {
  .barcodeValueSelectionContainer span,
  .barcodeSymbologySelectionContainer span {
    margin-top: -10px;
  }
}
