.settings__main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (max-width: 640px) {
  .settings__main {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: #fff;
  }
}

.settings__headerRow {
  display: flex;
  flex-direction: column;
}

@media (max-width: 640px) {
  .settings__headerRow {
    margin-top: 24px;
  }
}

@media (min-width: 641px) {
  .settings__headerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.settings__tabBar {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  z-index: 2;
}

@media (max-width: 640px) {
  .settings__tabBar {
    width: 100%;
    justify-content: flex-start;
    position: relative;
    top: 90px;
    left: 0;
  }
}

@media (min-width: 641px) {
  .settings__tabBar {
    position: static;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .settings__tabBar {
    padding-left: 0;
    padding-right: 16px;
  }
}

@media (min-width: 1008px) {
  .settings__tabBar {
    justify-content: center;
    /* height: 90px; */
    padding-right: 36px;
  }
}

.settings__date {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #888c96;
}

.settings__header {
  position: relative;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding-top: 24px;
  padding-left: 24px;
  width: 100%;
  min-height: 120px;
  /* background-color: #089bab; */
  background-color: #f2f2f2;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

@media (max-width: 640px) {
  .settings__header {
    z-index: 175;
  }
}

.settings__title {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
  line-height: 33px;
}

.settings__backArrow {
  padding-right: 15px;
  margin-top: 4px;
}

.settings__mobileUtilitiesRow {
  min-height: 64px;
  max-height: 64px;
  background-color: #fff;
  z-index: 8;
  width: 100%;
}

@media (max-width: 640px) {
  .settings__picFrameHeaderContainer {
    position: absolute;
    top: 18px;
    right: 20px;
    z-index: 5;
  }

  .settings__picFrameHeader {
    width: 28px;
    height: 28px;
    border-radius: 30px;
  }
}
