@media only screen and (max-width: 640px) {
  .header {
    margin-left: 18px;
    margin-right: 18px;
    margin-top: 13px;
    /* margin-bottom: 24px; */
  }
}

@media only screen and (min-width: 641px) {
  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #089bab;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 260px;
    min-width: 720px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 60px;
    padding-right: 60px;
  }
  .header--short {
    height: 141px;
  }
  .header--fullBottomBorderRadius {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.backArrow {
  padding-right: 15px;
}

@media (max-width: 640px) {
  .backArrow {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 641px) {
  .backArrow:hover {
    cursor: pointer;
  }
}

@media (max-width: 640px) {
  .goBackTitle {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(18, 26, 45, 0.5);
    line-height: 1;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 640px) {
  .backArrow__icon {
    padding-right: 4px;
  }
}

@media only screen and (min-width: 641px) {
  .headerTitleGroup {
    display: flex;
    align-items: center;
    margin-top: 64px;
    margin-left: 0;
  }
}

.headerTitle {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

@media only screen and (max-width: 640px) {
  .headerTitle {
    font-size: 20px;
    line-height: 1;
    margin: 24px 0 0 2px;
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .headerTitle {
    color: #ffffff;
    font-size: 34px;
    line-height: 27px;
  }
}

.header--withInput {
  height: 178px;
}

.inputContainer {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

@media only screen and (min-width: 641px) {
  .inputContainer {
    margin-top: 0;
    /* margin-bottom: 24px; */
  }
}

.inputStyles {
  border: 2px solid #089bab;
  margin: 0 auto;
}

@media only screen and (min-width: 641px) {
  .closeModalIcon {
    fill: #fff;
    height: 20.7px;
    width: 19.5px;
    position: absolute;
    right: 45px;
    top: 35px;
    mix-blend-mode: normal;
    transition: all ease-in-out 0.3s;
  }
  .closeModalIcon:hover {
    cursor: pointer;
  }
}
