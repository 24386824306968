.pharmacySitesDataCard__container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #e8ecef;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
}

.pharmacySitesDataCard__rowGroup {
  display: flex;
  justify-content: flex-start;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

/* @media (max-width: 440px) {
  .pharmacySitesDataCard__rowGroup:nth-child(3) {
    flex-direction: column;
  }
} */

.pharmacySitesDataCard__rowGroup:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.pharmacySitesDataCard__colGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  color: #121a2d;
  font-family: "Poppins", sans-serif;
  line-height: 16px;
}

.pharmacySitesDataCard__colGroup--1 {
  width: 100%;
  margin-right: 20px;
}

/* @media (max-width: 440px) {
  .pharmacySitesDataCard__phoneNumberGroup {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
} */

.pharmacySitesDataCard__colGroup--2 {
  min-width: 130px;
  width: 130px;
}

.pharmacySitesDataCard__dataList {
  list-style-type: none;
  padding: 0;
  margin: 8px;
}

.pharmacySitesDataCard__dataItem {
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin-left: 0 !important;
  color: #121a2d;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

.pharmacySitesDataCard__dataItem:not(:last-child) {
  margin-bottom: 4px;
}

.pharmacySitesDataCard__dataItem--actions {
  justify-content: space-between;
  align-items: flex-start;
}

.pharmacySitesDataCard__dataItem:not(:last-child) {
  margin-bottom: 4px;
}

.pharmacySitesDataCard__heading {
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 10px;
}

.pharmacySitesDataCard__value {
  font-size: 13px;
  font-weight: 300;
  overflow: hidden;
  width: 100%;
}

.pharmacySitesDataCard__actions {
  position: absolute;
  top: 0;
  right: 0;
}

.pharmacySitesDataCard__actions:hover {
  cursor: pointer;
}

.pharmacySitesDataCard__dataItem--heading {
  color: #089bab;
  min-width: 102px;
  max-width: 102px;
}

.pharmacySitesDataCard__dataItem--value {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.3;
}
