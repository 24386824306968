.support__buttonGroup {
  margin-top: auto;
  text-align: center;
}

.support__dataButton button {
  width: 140px;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
