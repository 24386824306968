.Report2Collections__pageContainer {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Report2Collections__headerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 10px;
}

.Report2Collections__header {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #454b60;
  padding-bottom: 24px;
  width: 100%;
  text-align: center;
}

.Report2Collections__selectStatement {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--green);
  max-width: 300px;
  padding-left: 8px;
}

.Report2Collections__buttonGroup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Report2Collections__reportCollectionsGroup {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.Report2Collections__schemasListing {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  min-width: 220px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px 15px;
  margin-right: 15px;
}

@media (min-width: 720px) {
  .Report2Collections__schemasListing {
    min-width: 250px;
    margin-right: 20px;
  }
}

@media (min-width: 850px) {
  .Report2Collections__schemasListing {
    min-width: 300px;
    margin-right: 30px;
  }
}

@media (min-width: 1008px) {
  .Report2Collections__schemasListing {
    min-width: 300px;
    margin-right: 40px;
  }
}

.Report2Collections__collectionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.Report2Collections__reportConcept {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: var(--black);
  margin-bottom: 7px;
  padding-left: 5px;
  padding-right: 5px;
}

@media (max-width: 1007px) {
  .Report2Collections__reportConcept {
    font-size: 12px;
  }
}

.Report2Collections__reportConcept:not(:last-child) {
  border-bottom: 1px dotted var(--blackLight);
}

.Report2Collections__reportConcept--active {
  display: none;
}

.Report2Collections__reportConcept--inActive {
  font-weight: 200;
}

.Report2Collections__dropBoxContainers {
  display: flex;
  justify-content: space-around;
  overflow: auto;
  height: 100%;
}

@media (min-width: 1008px) {
  .Report2Collections__dropBoxContainers {
    justify-content: space-between;
  }
}

.Report2Collections__dropBoxContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Report2Collections__dropBoxHeadings {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Report2Collections__dropBoxHeadings p:nth-child(2) {
  padding-right: 44px;
}

.Report2Collections__dropBoxHeadings p:nth-child(2) {
  @media (min-width: 720px) {
    padding-right: 68px;
  }
}

.Report2Collections__dropBoxHeadings p:nth-child(2) {
  @media (min-width: 850px) {
    padding-right: 128px;
  }
}

.Report2Collections__dropBoxHeadings p:nth-child(2) {
  @media (min-width: 1008px) {
    padding-right: 58px;
  }
}

.Report2Collections__schemasDropBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  min-width: 220px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px 15px;
}

@media (min-width: 720px) {
  .Report2Collections__schemasDropBox {
    min-width: 250px;
  }
}

@media (min-width: 850px) {
  .Report2Collections__schemasDropBox {
    min-width: 300px;
  }
}

@media (min-width: 1008px) {
  .Report2Collections__schemasDropBox {
    min-width: 300px;
  }
}

.Report2Collections__previewButtonContainer {
  margin-bottom: 10px;
}
