.stockIconBar {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.stockIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.stockIcon__fill {
  fill: #778ca2;
}

.stockIcon__fill--active {
  fill: #089bab;
}

.stockIcon__stroke {
  stroke: #778ca2;
}

.stockIcon__stroke--active {
  stroke: #089bab;
}

.stockIcon__title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  text-align: center;
  color: #778ca2;
  padding-top: 8px;
}

.stockIcon__title--active {
  font-weight: 500;
  color: #089bab;
}
