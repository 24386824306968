.AddStockLocation__listitem_row {
    display: flex;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 5px;
}

.AddStockLocation__listitem_row p {
    margin: 0;
    color: #121a2d;
}

.AddStockLocation__list_icon {
    margin-right: 5px;
    cursor: pointer;
}

.AddStockLocation__container {
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
}

.AddStockLocation__title {
    display: flex;
    align-items: center;
}

.AddStockLocation__container h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #121a2d;
    margin-left: 10px;
}

.AddStockLocation__container h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #089BAB;
}

.AddStockLocation__stock_location {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: #089BAB;
    padding: 0.5em 1em;
    flex-shrink: 1;
}

.AddStockLocation__stock_location p {
    color: #FFFFFF;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
}
