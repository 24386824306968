.Reports__view {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Reports__row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.Reports__title {
  margin: 0;
  font-style: normal;
  color: #454b60;
}

@media (max-width: 640px) {
  .Reports__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 2;
  }
}

@media (min-width: 641px) {
  .Reports__title {
    font-weight: 300;
    font-size: 32px;
    line-height: 44px;
  }
}

@media (max-width: 640px) {
  .Reports__mainBody {
    margin: 16px 16px 16px 16px;
  }

  .Reports__SearchBarContainer {
    position: absolute;
    top: 0;
    left: 35px;
    width: 75%;
  }
}

@media (min-width: 641px) {
  .Reports__mainBody {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    width: inherit;
  }
}

.Reports__container {
  display: flex;
  flex-wrap: wrap;
  max-height: 400px;
  gap: 30px 30px;
}

.Reports__container--standard {
  min-height: 170px;
}

.Reports__container--custom {
  min-height: 170px;
}

@media (min-width: 641px) {
  .Reports__pageHeaderContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .Reports__labelEditorPageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .Reports__pageHeader {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #121a2d;
  }
}

.Reports__deleteWarningModal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 400px;
  height: 140px;
  border-radius: 5px;
  background-color: white;
  z-index: 10000000;
}

.Reports__deleteWarningModal p {
  padding: 24px 18px 24px 18px;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  color: var(--black);
}

.Reports__ModalButtonsGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Reports__reportOptions {
  display: flex;
  align-items: center;
}
