.PrintMedList__container {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  background-color: #fff;
  height: 413px;
  border-radius: 20px;
}

@media (max-width: 640px) {
  .PrintMedList__container {
    height: 100vh;
    width: 100%;
    padding-top: 50px;
    box-sizing: border-box;
  }
}

@media (min-width: 641px) {
  .PrintMedList__container {
    height: 100%;
    width: 100%;
    padding: 20px;
  }
}

@media (max-width: 640px) {
  .PrintMedList__table {
    margin-top: 50px;
  }
}

.PrintMedList__buttonsContainer {
  display: flex;
  gap: 5px;
}

@media (max-width: 640px) {
  .PrintMedList__buttonContainer {
    width: 100%;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: auto;
    margin-bottom: 16px;
  }
}

@media (min-width: 641px) {
  .PrintMedList__buttonContainer {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}


.PrintMedList__closeModalIcon {
  fill: #121a2d;
  height: 20.7px;
  width: 20.7px;
  position: absolute;
  right: 35px;
  top: 35px;
  mix-blend-mode: normal;
  cursor: pointer;
}


.PrintMedList__buttonLink {
  color: #0000EE;
  font-family: inherit;
  font-size: 14px;
  text-align: center;
  border: none;
  background: none;
  cursor: pointer;
}

.button {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  height: 48px;
  padding: 0;
  color: #ffffff;
  background-color: #089bab;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

@media (max-width: 640px) {
  .button {
    width: 100%;
    margin: 0;
  }
}

@media (min-width: 641px) {
  .button {
    width: 213px;
    margin: 0;
  }
}

.button--disabled {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(8, 155, 171, 0.5);
  cursor: not-allowed;
}

.controlGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 300px;
  min-height: 75px;
  margin: 6px 0;
}

.controlBorder {
  display: flex;
  flex-direction: row;
}

@media (max-width: 640px) {
  .controlBorder {
    width: 330px;
    border-bottom: 1px solid var(--black);
  }
}

@media (min-width: 641px) {
  .controlBorder {
    width: 400px;
    border-bottom: 1px solid var(--black);
  }
}

.controlButton {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.controlButtonPopup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 148px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 6%);
  border: solid 2px var(--gray);
  background-color: #ffffff;
  overflow: auto;
  margin-top: 10px;
  border-radius: 6px;
  width: 396px;
  position: absolute;
  left: -400px;
}

@media (max-width: 640px) {
  .controlButtonPopup {
    width: 330px;
    left: -331px;
  }
}

@media (min-width: 641px) {
  .controlButtonPopup {
    width: 396px;
    left: -400px;
  }
}

.controlButtonPopupListItem {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: var(--black);
  cursor: pointer;
  margin: 0;
  padding: 5px 10px;
}

@media (min-width: 641px) {
  .controlButtonPopupListItem {
    width: 100%;
    max-width: 364px;
  }
}

.controlButtonPopupListItem:hover {
  color: var(--green);
}

.controlButtonInputGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 38px;
}

@media (max-width: 640px) {
  .controlButtonInputGroup {
    width: 330px;
    margin-top: 46px;
  }
}

.controlLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--green);
}

.controlInput {
  font-family: Poppins;
  font-style: normal;
  font-weight: 404;
  font-size: 18px;
  line-height: 27px;
  color: var(--black);
  border: none;
  outline: none;
  flex: 1;
  flex-direction: row;
  max-height: 36px;
  min-width: 50px;
  background: transparent;
}

.controlValidationLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  color: var(--red);
}

@media only screen and (max-width: 640px) {
  .PrintMedList__headingGroup {
    position: absolute;
    left: 18px;
    top: 13px;
  }
}

@media (max-width: 640px) {
  .PrintMedList__backArrow {
    padding-right: 15px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .PrintMedList__goBackTitle {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(18, 26, 45, 0.5);
    line-height: 1;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 640px) {
  .PrintMedList__backArrow__icon {
    padding-right: 4px;
  }
}

.PrintMedList__title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #121a2d;
}

@media (max-width: 640px) {
  .PrintMedList__title {
    font-size: 20px;
    line-height: 1;
    margin: 24px 0 0 2px;
    padding: 0;
  }
}
