.PillSortDashboard__viewContainer {
  overflow-x: hidden;
  overflow-y: auto;
}

.PillSortDashboard__graphsGroup {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 36px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.PillSortDashboard__graphsGroup:not(:last-child) {
  margin-bottom: 40px;
}

.PillSortDashboard__graphsGroup > * {
  border: 1px solid #e8ecef;
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
}

.PillSortDashboard__graphsGroupSiteDescription:not(:first-child) {
  margin-top: 26px;
}

.PillSortDashboard__graphContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins", sans-serif;
  width: 450px;
  height: 290px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
}

/* .PillSortDashboard__graphContainer * {
  cursor: pointer;
} */

.PillSortDashboard__graphContainer--suctionCup {
  cursor: default;
}

/* .PillSortDashboard__graphContainer--suctionCup * {
  cursor: default;
} */

.PillSortDashboard__graphContainer--paddingTop {
  padding-top: 24px;
}

.PillSortDashboard__graphContainer--paddingBottom {
  padding-bottom: 12px;
}

.PillSortDashboard__graphHeading {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 1;
  margin: 0;
  padding: 0;
}

.PillSortDashboard__graphHeading--suctionCup {
  margin-bottom: 4px;
}

.PillSortDashboard__suctionCupGraphSubHeading {
  min-height: 14px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 1;
  margin: 0 0 4px 0;
  padding: 0;
}

.PillSortDashboard__NDCInfo {
  font-size: 100px;
  font-weight: 400;
  text-align: center;
  margin-top: 36px;
}

.PillSortDashboard__syncFusionDropdownContainer {
  width: 190px;
  margin-right: 24px;
}

.PillSortDashboard__syncFusionDropdownContainer input {
  font-size: 14px !important;
}

.PillSortDashboard__suctionCupsListContainer {
  width: 100%;
  margin-top: 11px;
  padding: 0 14px 0 18px;
  box-sizing: border-box;
}

.PillSortDashboard__suctionCupsListScroll {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  height: 192px;
  overflow-y: auto;
}

.PillSortDashboard__suctionCupListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 15px 14px 10px;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
}

.PillSortDashboard__suctionCupListItem:hover {
  background-color: var(--blackExtraLight);
}

.PillSortDashboard__suctionCupListItem p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 1;
  margin: 0;
  padding: 0;
}

.PillSortDashboard__cupLifeIndicator {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.PillSortDashboard__cupLifeIndicator--red {
  background-color: #dc3545;
}

.PillSortDashboard__cupLifeIndicator--yellow {
  background-color: #ffdf10;
}

.PillSortDashboard__cupLifeIndicator--green {
  background-color: #64be00;
}

.PillSortDashboard__loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 450px;
  height: 288px;
  left: 0;
  right: 0;
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #121a2d;
}

.PillSortDashboard__loader svg {
  margin-top: 40px;
}
