.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  width: 100vw;
  height: 100vh;
}

@media (min-width: 641px) {
  .container {
    background-image: url("../../assets/background-create-password.jpg");
    background-position: center;
    background-repeat: norepeat;
    background-size: cover;
    min-height: 1024px;
  }
}

.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 100%;
}

@media (max-width: 640px) {
  .cardContainer {
    width: 100%;
  }
}

@media (min-width: 641px) {
  .cardContainer {
    border-bottom-left-radius: 70px;
    border-top-left-radius: 70px;
    width: 610px;
    min-width: 610px;
  }
}

.cardBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
}

.logoText {
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  line-height: 57px;
  letter-spacing: 0.5px;
  color: #778ca2;
}

.logoTextBold {
  font-weight: 600;
}

.title {
  margin-top: 10%;
  font-style: normal;
  font-weight: 400;
  color: #089bab;
}

@media (max-width: 640px) {
  .title {
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.5px;
  }
}

@media (min-width: 641px) {
  .title {
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 0.7px;
  }
}
