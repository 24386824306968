.LabelEditor__labelAndButtonsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.LabelEditor__groupContainer {
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-family: "Poppins", sans-serif;
  height: 100%;
  width: 100%;
}

.LabelEditor__labelEditorContainer {
  display: flex;
  justify-content: center;
  flex: 1;
  /* height: 100%; */
}

.LabelEditor__objectExplorer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 200px;
  height: 100%;
  border: 2px solid black;
  margin-right: 8px;
}

.LabelEditor__objectExplorer header h2 {
  font-weight: 500;
  color: var(--black);
  font-size: 16px;
  line-height: 1;
  margin: 16px 0 0 16px;
  padding: 0;
}

.LabelEditor__objectExplorerGroup {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-left: 16px;
}

.LabelEditor__svgContainer {
  width: 24px;
  height: 24px;
}

.LabelEditor__svgContainer svg {
  width: 24px;
  height: 24px;
}

.LabelEditor__objectExplorerTypeName {
  color: var(--black);
  font-size: 14px;
  line-height: 1;
  margin: 0 0 0 5px;
  padding: 0;
}

.LabelEditor__objectExplorerTypeName--inactive {
  color: rgba(0, 0, 0, 0.4);
}

.LabelEditor__objectExplorerSVGContainer svg {
  fill: var(--black);
}

.LabelEditor__objectExplorerSVGContainer--inactive svg {
  fill: rgba(0, 0, 0, 0.4);
}

.LabelEditor__objectExplorerTextSVGContainer svg {
  stroke: var(--black);
}

.LabelEditor__objectExplorerTextSVGContainer--inactive svg {
  stroke: rgba(0, 0, 0, 0.4);
}

.LabelEditor__objectExplorerImageSVGContainer svg {
  stroke: var(--black);
}

.LabelEditor__objectExplorerImageSVGContainer--inactive svg {
  stroke: rgba(0, 0, 0, 0.4);
}

.LabelEditor__canvas {
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 100%;
  border: 2px solid black;
  margin-right: 8px;
  overflow: hidden;
}

.LabelEditor__canvasSelections {
  display: flex;
  flex-direction: column;
}

.LabelEditor__properties {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 260px;
  width: 260px;
  height: 100%;
  border: 2px solid black;
}

.LabelEditor__properties header h2 {
  font-weight: 500;
  color: var(--black);
  font-size: 16px;
  line-height: 1;
  margin: 16px 0 0 16px;
  padding: 0;
}

.LabelEditor__deleteWarningModal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 400px;
  height: 174px;
  border-radius: 5px;
  background-color: white;
  z-index: 10000000;
}

.LabelEditor__deleteWarningModal p {
  padding: 24px 18px 24px 18px;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  color: var(--black);
}

.LabelEditor__ModalButtonsGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LabelEditor__buttonsContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 34px;
  margin: auto 0 0 0;
}

.LabelEditor__labelEditorHeading {
  display: flex;
  justify-content: center;
  margin: 0 0 50px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
  width: 100%;
  text-align: center;
}
