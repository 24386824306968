.TextProperties__container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 6px;
}

/* general input container */
.TextProperties__inputContainer {
  width: 54px;
}

/* general input */
.TextProperties__inputContainer input {
  text-align: right;
  padding-right: 5px;
}

/* general boxes container */
.TextProperties__boxesContainer {
  display: flex;
  align-items: center;
}

.TextProperties__boxesContainer--textStyles {
  margin-top: 5px;
}

/* position */
.TextProperties__positionContainer {
  display: flex;
  justify-content: space-between;
}

.TextProperties__xBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
}

.TextProperties__xPositionInputContainer {
  width: 54px;
}

.TextProperties__yBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TextProperties__yPositionInputContainer {
  width: 54px;
}

/* size */
.TextProperties__sizeContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.TextProperties__widthBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
}

.TextProperties__widthInputContainer {
  width: 54px;
}

.TextProperties__heightBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TextProperties__heightInputContainer {
  width: 54px;
}

.TextProperties__textColorContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

/* propertyName container */
.TextProperties__propertyNameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.TextProperties__propertyNameContainer .TextProperties__inputContainer {
  margin-left: 5px;
  width: 100%;
}

/* defaultValue container */
.TextProperties__defaultValueContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.TextProperties__defaultValueContainer .TextProperties__inputContainer {
  margin-left: 5px;
  width: 100%;
}

/* font container, font-size container, canisterDataMapping */
.TextProperties__fontContainer,
.TextProperties__fontSizeContainer,
.TextProperties__canisterDataMapping {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.TextProperties__fontContainer div,
.TextProperties__fontSizeContainer div,
.TextProperties__canisterDataMapping div {
  width: 100%;
}

/* textStyles container */
.TextProperties__textStylesContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.TextProperties__styleBoxesContainer,
.TextProperties__alignmentBoxesContainer {
  display: flex;
  align-items: center;
}

.TextProperties__styleBoxesContainer {
  margin-right: 12px;
}

.TextProperties__boldContainer,
.TextProperties__italicContainer,
.TextProperties__underlineContainer {
  border: 1px solid var(--black);
  border-radius: 2px;
  padding: 2px;
  width: 18px;
  height: 18px;
  overflow: hidden;
}

.TextProperties__leftAlignmentContainer,
.TextProperties__centerAlignmentContainer,
.TextProperties__rightAlignmentContainer {
  border: 1px solid var(--black);
  border-radius: 2px;
  padding: 2px;
  width: 18px;
  height: 18px;
  overflow: hidden;
}

.TextProperties__boldContainer,
.TextProperties__italicContainer,
.TextProperties__leftAlignmentContainer,
.TextProperties__centerAlignmentContainer {
  margin-right: 5px;
}

.TextProperties__boldContainer svg,
.TextProperties__italicContainer svg,
.TextProperties__underlineContainer svg {
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0;
}

.TextProperties__leftAlignmentContainer svg,
.TextProperties__centerAlignmentContainer svg,
.TextProperties__rightAlignmentContainer svg {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
}

/* wordWrappingContainer 
/* textStyles container */
.TextProperties__wordWrappingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.TextProperties__wordWrapContainer {
  display: flex;
  align-items: center;
}

.TextProperties__wordWrapCheckBoxContainer {
  width: 18px;
  height: 1px;
  display: flex;
  align-items: center;
  margin-right: 3px;
}

.TextProperties__wordWrapCheckBoxContainer svg {
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0;
}

/* general p */
.TextProperties__container p {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  padding: 0;
  margin: 0;
  line-height: 1;
  color: var(--black);
}

/* general p span */
.TextProperties__container p span {
  display: block;
  font-size: 10px;
  margin-top: 3px;
}

.TextProperties__positionContainer p,
.TextProperties__sizeContainer p {
  margin-top: 8px;
}

.TextProperties__wordWrapContainer p {
  font-size: 14px;
  white-space: nowrap;
  padding-right: 34px;
}

.TextProperties__wordLinesContainer {
  width: 100%;
  margin-top: -1px;
}

.TextProperties__trashIconContainer {
  position: absolute;
  right: 8px;
  top: -43px;
  cursor: pointer;
}

.TextProperties__trashIconContainer svg {
  fill: #d9534f;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in-out;
}

.TextProperties__trashIconContainer:hover svg {
  transform: scale(1.1);
}
