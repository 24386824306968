.quickGuidesCard__topicCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--green);
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
  border-radius: 5px;
  text-decoration: none;
}

@media (min-width: 641px) {
  .quickGuidesCard__topicCard {
    width: 340px;
    height: 209px;
  }
}

@media (min-width: 1008px) {
  .quickGuidesCard__topicCard {
    width: 420px;
    height: 258px;
  }
}

@media (max-width: 640px) {
  .quickGuidesCard__topicCard {
    height: 258px;
    width: 100%;
    box-sizing: border-box;
  }
}

.quickGuidesCard__topicCard h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  background-color: var(--green);
  text-align: center;
  padding: 10px 0;
  margin: 0;
  width: 100%;
}

@media (min-width: 641px) {
  .quickGuidesCard__topicCard h2 {
    font-size: 18px;
  }
}

@media (min-width: 1008px) {
  .quickGuidesCard__topicCard h2 {
    font-size: 20px;
  }
}

@media (max-width: 640px) {
  .quickGuidesCard__topicCard h2 {
    font-size: 16px;
  }
}

.quickGuidesCard__cardImageContainer {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  background: var(--blackExtraLight);
}
