.Popup__container {
  margin-top: 10px;
  position: absolute;
  z-index: 200;
  background: #ffffff;
  box-shadow: 0 2px 16px rgba(153, 155, 168, 0.34);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 16px;
}

@media (max-width: 640px) {
  .Popup__container {
    margin-top: -10px;
    /* right: 10px; */
  }
}

.Popup__MenuItem {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #ebedf8;
}

.Popup__MenuItem p {
  font-family: "Poppins", sans-serif;
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #121a2d;
  overflow: hidden;
  white-space: nowrap;
}

.Popup__MenuItem:focus p {
  color: #089bab;
}

.Popup__MenuItem p:hover {
  font-weight: 500;
  color: #089bab;
}
