.StockLocationCard__hr {
  height: 1px;
  width: 100%;
  border-width: 0;
  color: var(--black);
  background-color: var(--black);
  margin-top: 18px;
  margin-bottom: 18px;
}

.StockLocationCard__parentAndChildrenCardsGroup {
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 0;
  margin-bottom: 10px;
  border: 1px solid #e8ecef;
  box-sizing: border-box;
  /* filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.StockLocationCard__05)); */
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  color: #121a2d;
  padding: 0;
}

.StockLocationCard__parentAndChildrenCardsGroup--expanded {
  border: none;
}

.StockLocationCard__childCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #121a2d;
  border: 1px solid #e8ecef;
  border-radius: 10px;

  padding: 10px;
}

.StockLocationCard__parentCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  background-color: transparent;
  /* box-shadow: 0px 5px 14px rgba(120 146 141 / 5%); */
  line-height: 16px;
  font-weight: 300;
}

.StockLocationCard__parentCard--expanded {
  border: 1px solid var(--green);
  border-radius: 10px;
  margin: 0 0 16px 0;
  padding: 10px;
}

.StockLocationCard__hasChildrenIconContainer {
  position: absolute;
}

.StockLocationCard__hasChildrenIconContainer--collapsed {
  right: 20px;
  top: 4px;
}

.StockLocationCard__hasChildrenIconContainer--expanded {
  right: 30px;
  top: 12px;
}

.StockLocationCard__hasChildrenIcon {
  width: 16px;
  height: 16px;
}

.StockLocationCard__action_items {
  display: flex;
  align-items: center;
  position: relative;
}

.StockLocationCard__contextMenuContainer {
  position: absolute;
  padding: 5px;
  z-index: 100;
}

.StockLocationCard__contextMenuContainer--collapsed {
  right: 0;
  top: 0;
}

.StockLocationCard__contextMenuContainer--collapsedChild {
  right: 8px;
  top: 8px;
}

.StockLocationCard__contextMenuContainer--expanded {
  right: 8px;
  top: 8px;
}

.StockLocationCard__contextMenuContainer:hover {
  cursor: pointer;
}

.StockLocationCard__contextMenuWrapper {
  position: relative;
  right: 130px;
}

.StockLocationCard__cardRow {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
  padding-bottom: 10px;
  color: #121a2d;
  font-family: "Poppins", sans-serif;
  line-height: 16px;
}

.StockLocationCard__cardRow:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.StockLocationCard__headingAndValueGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.StockLocationCard__headingAndValueGroup:nth-child(1) {
  flex-basis: 60%;
}

.StockLocationCard__cardHeading {
  font-weight: 500;
  font-size: 14px;
  padding: 0 0 10px 0;
  margin: 0;
}

.StockLocationCard__cardData {
  font-size: 13px;
  font-weight: 300;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
}
