.stockBody {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
}

@media only screen and (max-width: 640px) {
  .stockBody {
    flex: 1 0 auto;
  }
}

@media only screen and (min-width: 641px) {
  .stockBody {
    flex: 1;
    height: 100%;
    width: 720px;
    background-color: #fafafb;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-left: 0;
    padding-right: 0;
  }
}

.stockTransferBody {
  height: 100%;
}

.stockBody__newStockedItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: #fff;
}

.stockBody__newStockedItem--active {
  border: 1px solid #089bab;
}

.stockBody__newStockedItem--withScroll {
  width: 321px;
}

.stockBody__newStockedItem:not(:last-child) {
  margin-bottom: 10px;
}

@media only screen and (min-width: 641px) {
  .stockBody__newStockedItem {
    width: 100%;
  }
}

.stockBody__errorMsg {
  margin-top: 20px;
}

.stockBody__icon {
  height: 48px;
  width: 48px;
  margin-left: 14px;
  margin-right: 18px;
}
