.TruPakDashboard__graphsGroupMobile {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 24px;
}

.TruPakDashboard__graphsGroupMobile:not(:last-child) {
  margin-bottom: 40px;
}

.TruPakDashboard__graphsGroupMobile > * {
  border: 1px solid #e8ecef;
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
}

.TruPakDashboard__graphContainerMobile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins", sans-serif;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.TruPakDashboard__graphContainerMobile--paddingTop {
  padding-top: 24px;
}

.TruPakDashboard__graphContainerMobile--paddingBottom {
  padding-bottom: 12px;
}

.TruPakDashboardMobile__loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 288px;
  top: -30px;
  left: 0;
  right: 0;
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1;
  color: #121a2d;
}

.TruPakDashboardMobile__loader svg {
  margin-top: 40px;
}
