.FunctionModal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(152, 169, 188, 0.3);
  backdrop-filter: blur(46.2108px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.FunctionModal__modal {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 60px;
  width: 926px;
  height: 603px;
  background: #ffffff;
  border-radius: 20px;
}

@media (max-width: 1007px) {
  .FunctionModal__modal {
    width: 100%;
    box-sizing: border-box;
    padding: 60px 6px 60px 6px;
  }
}

.FunctionModal__close {
  position: absolute;
  top: 35px;
  right: 35px;
  cursor: pointer;
}

.FunctionModal__header {
  display: flex;
}

.FunctionModal__header h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 27px;
  color: #121a2d;
}

.FunctionModal__body {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: auto;
}

.FunctionModal__row {
  display: flex;
  align-items: center;
  width: 100%;
}

.FunctionModal__footer {
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FunctionModal__text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #121a2d;
}

.FunctionModal__error {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  color: var(--red);
  padding: 0;
  margin: 0;
}

.FunctionModal__textInputContainer {
  margin-bottom: 20px;
}

@media (max-width: 1007px) {
  .FunctionModal__row input {
    font-size: 14px;
  }
}
