.EV54ManualDispense__column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.EV54ManualDispense_row {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0;
}

.EV54ManualDispense_row h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #121A2D;
    margin: 10px 0;
}

.EV54ManualDispense_row h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #089BAB;
    margin: 0;
}

.EV54ManualDispense_header {
    justify-content: space-between;
}

.EV54ManualDispense_header h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 49px;
    color: #089BAB;
}

.EV54ManualDispense_header span {
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 49px;
    color: #454B60;
}

.EV54ManualDispense_icon {
    color: #121a2d;
    opacity: 0.5;
}
