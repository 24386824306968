.inactiveMixin {
  content: "";
  position: absolute;
  display: block;
}
.beforeAnimation {
  -moz-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  -o-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
}
.afterAnimation {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0px 0 rgba(0, 0, 0, 0.04),
    0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  -moz-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  -o-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
}
.toggleWrapper {
  margin: 0;
  height: 18px;
}
.toggleWrapper input.mobileToggle {
  opacity: 0;
  position: absolute;
}
.toggleWrapper input.mobileToggle + label {
  position: relative;
  display: inline-block;
  user-select: none;
  -moz-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  -webkit-tap-highlight-color: transparent;
  /* height: 30px;
  width: 50px; */
  height: 18px;
  width: 30px;
  /* border: 1px solid #e4e4e4; */
  /* border: 1px solid rgba(123, 129, 139, 0.4); */
  border-radius: 60px;
  background: #d9e1ec;
}
.toggleWrapper input.mobileToggle + label:before {
  content: "";
  position: absolute;
  display: block;
  -moz-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  -o-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  /* height: 30px;
  width: 51px; */
  height: 18px;
  width: 30px;
  top: 0;
  left: 0;
  border-radius: 30px;
}
.toggleWrapper input.mobileToggle + label:after {
  content: "";
  position: absolute;
  display: block;
  /* box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0px 0 rgba(0, 0, 0, 0.04),
    0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05); */

  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 0px 0px 0 rgba(0, 0, 0, 0.04),
    0 0px 0px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  -moz-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  -o-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  background: #fff;
  /* height: 28px;
  width: 28px; */
  height: 15px;
  width: 15px;
  top: 1px;
  left: 0px;
  border-radius: 60px;
}
.toggleWrapper input.mobileToggle:checked + label:before {
  background: var(--green);
  -moz-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  -o-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  -webkit-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}
.toggleWrapper input.mobileToggle:checked + label:after {
  /* left: 24px; */
  left: 14.5px;
  border: 1px solid var(--green);
}
