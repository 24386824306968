.selectButton:active,
.selectButton:focus {
  height: 20px;
  width: 28px;
  background-color: var(--focusColorDropDownIcon);
}

.controlGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 75px;
  margin: 6px 0 0 0;
  position: relative;
}

.controlLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--green);
}

.controlGroupStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--black);
  padding: 3px;
}

.expandingBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  min-height: 38px;
  max-height: 300px;
  overflow: auto
}

.itemStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 24px;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-right: 5px;
  margin-top: 5px;
}

.itemTextStyle {
  margin: 0 0 0 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: #121A2D;
}

.itemDeleteButtonStyle {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.popupStyle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100%;
  height: auto;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px var(--gray);
  background-color: #ffffff;
  overflow: auto;
  margin-top: 10px;
  border-radius: 6px;
  position: relative;
  z-index: 99;
}

@media (max-width: 640px) {
  .controlGroup {
    margin: 0;
  }
}