.PhoneList__container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
}

.PhoneList__body {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}

.PhoneList__footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 10px;
}

@media (max-width: 640px) {
  .PhoneList__footer {
    justify-content: flex-start;
    padding: 0;
    margin: 5px 0 30px 6px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .PhoneList__footer {
    padding: 10px 0 10px 10px;
  }
}

.PhoneList__row {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.PhoneList__column {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.PhoneList__popup {
  z-index: 100;
  position: absolute;
  width: 300px;
  height: 200px;
  background-color: red;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}

.PhoneList__button {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media (max-width: 1007px) {
  .PhoneList__remove_button {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.PhoneList__remove_button {
  width: 40px;
  flex: 0 0 40px;
}

.PhoneList__textbox {
  flex: 1 0;
  margin-top: 17px;
}
