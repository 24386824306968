.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 92px;
  box-sizing: border-box;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
}

@media (max-width: 640px) {
  .card {
    padding: 16px;
    width: 100%;
    border: 1px solid #dcdcdc;
    margin-bottom: 10px;
  }
}

@media (min-width: 641px) {
  .card {
    border: 2px solid #fff;
    margin-bottom: 2px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .card {
    padding: 0 6px;
    width: 280px;
  }
}

@media (min-width: 1008px) {
  .card {
    padding: 16px;
    width: 336px;
  }
}

.card--selected {
  border: 2px solid #ffab2b;
}

.footerIcons {
  display: flex;
  align-items: center;
}

.trayIconContainer {
  cursor: pointer;
}

.trayIconContainer svg {
  fill: var(--green);
  width: 20px;
  height: 20px;
  vertical-align: -6px;
}
