.DropDownList__group {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* border: 1px solid var(--black); */
  margin: 0;
  overflow: visible;
}

@media (max-width: 640px) {
  .DropDownList__group {
    margin-bottom: 24px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .DropDownList__group {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.DropDownList__group label {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--green);
}

.DropDownList__input_group {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 38px;
  width: 100%;
}

.DropDownList__input_group input {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 27px;
  color: var(--black);
  border: none;
  outline: none;
  flex: 1;
  max-height: 36px;
  min-width: 50px;
  background: transparent;
}

@media (max-width: 640px) {
  .DropDownList__input_group input {
    font-size: 14px;
    line-height: 1;
  }
}

.DropDownList__input_group button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.DropDownList__list {
  position: absolute;
  top: 38px;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100%;
  max-height: 300px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px var(--gray);
  background-color: #ffffff;
  overflow: auto;
  /*margin-top: 40px;*/
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 640px) {
  .DropDownList__list {
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
    position: relative;
    top: 10px;
  }
}

@media (max-width: 640px) {
  .DropDownList__list::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }
}

.DropDownList__list p {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: var(--black);
  cursor: pointer;
  margin: 0;
  box-sizing: border-box;
  padding: 7px 10px;
}

@media (max-width: 640px) {
  .DropDownList__list p {
    font-size: 14px;
    line-height: 1;
  }
}

.DropDownList__list p:hover {
  color: var(--green);
}

.DropDownList__control_border {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid var(--black);
}

@media (min-width: 1008px) {
  .DropDownList__control_border {
    margin-bottom: 10px;
  }
}

.DropDownList__validation {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  color: var(--red);
}

.selectButton:active,
.selectButton:focus {
  height: 20px;
  width: 28px;
  background-color: var(--focusColorDropDownIcon);
}
