.NeedCheckRow,
.NotFilledRow,
.CorrectedApprovedRow {
  cursor: pointer;
}

.ApprovedRow {
  background-color: #55EFC4 !important;
}

.ApprovedRow:hover {
  background-color: #75AFA4 !important;
}

.NeedCheckRow {
  background-color: #74B9FF !important;
}

.NeedCheckRow:hover {
  background-color: #64C9EF !important;
}

.NotFilledRow {
  background-color: #FFFFFF !important;
}

.CorrectedApprovedRow {
  background-color: #FDCB6E !important;
}

.CorrectedApprovedRow:hover {
  background-color: #FFEAA7 !important;
}