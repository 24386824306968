.help__pageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* overflow: hidden; */
}

@media (max-width: 640px) {
  .help__pageContainer {
    height: 100%;
    padding: 0 16px 16px 16px;
    box-sizing: border-box;
  }
}

.help__pageContainer header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 640px) {
  .help__pageContainer header {
    position: absolute;
    top: 24px;
    left: 52px;
  }
}

.help__helpList {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  grid-gap: 36px;
  gap: 36px;
  width: 100%;
  list-style: none;
  margin: 24px 0 0 0;
  padding: 0;
  overflow: auto;
}

@media (max-width: 640px) {
  .help__helpList {
    justify-content: center;
    padding-right: 16px;
    padding-left: 16px;
    box-sizing: border-box;
    grid-gap: 16px;
    gap: 16px;
    overflow: auto;
    margin-bottom: auto;
  }
  .help__helpList li {
    width: 100%;
  }
}

.help__pageTitle {
  font-family: "Poppins", sans-serif;
  line-height: 1;
  font-weight: 500;
  font-size: 24px;
  color: var(--black);
}

@media (min-width: 641px) {
  .help__pageTitle {
    margin-right: 16px;
  }
}

@media (max-width: 640px) {
  .help__pageTitle {
    font-size: 14px;
  }
}

.help__headerUtilities {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@media (max-width: 640px) {
  .help__headerUtilities {
    margin-top: 24px;
    justify-content: center;
  }
}

.help__dropdownContainer {
  margin-top: -13px;
  margin-bottom: 13px;
  min-width: 175px;
}

@media (max-width: 640px) {
  .help__dropdownContainer {
    flex-basis: 40%;
    margin: 0;
  }
}

.help__searchBarContainer {
  margin-right: 24px;
}

@media (max-width: 640px) {
  .help__searchBarContainer {
    padding: 0;
    margin-right: 10px;
    margin-top: -24px;
    box-sizing: border-box;
    width: 64vw;
  }
}

@media (min-width: 450px) and (max-width: 500px) {
  .help__searchBarContainer {
    width: 70vw;
  }
}

@media ((min-width: 500px) and (max-width: 640px)) {
  .help__searchBarContainer {
    width: 73vw;
  }
}

@media (min-width: 641px) {
  .help__searchBarContainer {
    flex-grow: 1;
  }
}

@media (min-width: 641px) {
  .help__searchBarContainer div {
    display: flex;
    justify-content: flex-end;
  }
}

@media ((min-width: 641px) and (max-width: 1008px)) {
  .help__searchBarContainer {
    margin-top: 9px;
  }
}
