.inventoryIconMenu {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  height: 82px;
  min-height: 82px;
  margin-top: auto;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  position: relative;
  z-index: 30;
  background-color: #fff;
  filter: brightness(98%);
}

.inventoryIconMenu__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.inventoryIconMenu__icon:hover {
  cursor: pointer;
}

.inventoryIconMenu__fill {
  fill: #778ca2;
}

.inventoryIconMenu__fill--active {
  fill: #089bab;
}

.inventoryIconMenu__stroke {
  stroke: #778ca2;
}

.inventoryIconMenu__stroke--active {
  stroke: #089bab;
}

.inventoryIconMenu__title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  text-align: center;
  color: #778ca2;
  padding-top: 8px;
}

.inventoryIconMenu__title--active {
  font-weight: 500;
  color: #089bab;
}
