@media (max-width: 640px) {
  .CreateProductAccordion__col {
    display: flex;
    flex-direction: column;
  }

  .CreateProductAccordion__col--checkboxSpacing {
    margin-bottom: 10px;
  }

  .CreateProductAccordion__searchIconContainer {
    position: absolute;
    left: 78px;
  }

  .CreateProductAccordion__listBox {
    padding: 0;
    margin: 0 0 24px 0;
  }
}

.CreateProductAccordion__col_left {
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.CreateProductAccordion__row {
  display: flex;
  width: 100%;
  position: relative;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .CreateProductAccordion__row {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 1008px) {
  .CreateProductAccordion__row {
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 641px) {
  .CreateProductAccordion__col {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 10px;
  }

  .CreateProductAccordion__col--checkboxSpacing {
    margin-bottom: inherit;
  }

  .CreateProductAccordion__searchIconContainer {
    position: absolute;
    top: 6px;
    right: 12px;
  }

  .CreateProductAccordion__listBox {
    padding: 0;
    margin: 0;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .CreateProductAccordion__col--checkboxSpacing {
    margin-bottom: 10px;
  }
}

.CreateProductAccordion__viewTransition {
  display: flex;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .CreateProductAccordion__viewTransition:nth-child(1) {
    padding-right: 20px;
    /* border: 1px solid red; */
  }
}

@media (max-width: 1007px) {
  .CreateProductAccordion__viewTransition {
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
  }
  .CreateProductAccordion__viewTransition:nth-child(2) {
    margin-right: 20px;
  }
}

.CreateProductAccordion__viewTransition {
  display: flex;
  width: 100%;
}

@media (max-width: 640px) {
  .CreateProductAccordion__viewTransition {
    flex-direction: column;
  }
}

.CreateProductAccordion__checkboxGroup {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .CreateProductAccordion__checkboxGroup {
    flex-direction: column;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .CreateProductAccordion__checkboxGroup {
    flex-direction: column;
  }
}

@media (min-width: 1008px) {
  .CreateProductAccordion__checkboxGroup {
    flex-direction: row;
    width: 100%;
  }
}

.CreateProductAssociation__transitionGroup {
  display: flex;
  width: 100%;
}

@media (max-width: 640px) {
  .CreateProductAssociation__transitionGroup {
    flex-direction: column;
  }
}

@media (min-width: 640px) and (max-width: 1007px) {
  .CreateProductAssociation__transitionGroup {
    flex-direction: column;
    padding-right: 20px;
  }
}

table {
  border-collapse: collapse;
}

table thead tr {
  border-bottom: 1px solid #089bab;
}

th {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #121a2d;
  padding-bottom: 5px;
}

td {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
}

tr > td:last-child {
  text-align: center;
}
