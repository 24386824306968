.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 640px) {
  .header {
    position: absolute;
    top: 88px;
    left: 16px;
  }
}

@media (min-width: 641px) {
  .header {
    width: 100%;
  }
}

.searchRowContainer {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .searchRowContainer {
    position: absolute;
    top: 12px;
    width: 55%;
    left: 40px;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 0;
    margin-left: 12px;
    margin-right: 12px;
  }
}

@media (min-width: 641px) {
  .searchRowContainer {
    width: 100%;
    margin-top: 34px;
  }
}

.searchRow {
  display: flex;
  width: 100%;
}

@media (max-width: 640px) {
  .searchRow {
    margin-bottom: 16px;
  }
}

.button {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: 123px;
  height: 48px;
  padding: 0 20px;
  color: #ffffff;
  background-color: #089bab;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.deviceTitleRow {
  display: flex;
  align-items: center;
  width: 100%;
}

@media (max-width: 640px) {
  .deviceTitleRow {
    justify-content: center;
    margin-top: 80px;
  }
}

@media (min-width: 641px) {
  .deviceTitleRow {
    margin-top: 34px;
  }
}

.backIcon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.selectedDeviceRow {
  display: flex;
}

@media (min-width: 641px) {
  .selectedDeviceRow {
    width: 100%;
  }
}

.selectedDeviceRowItem {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  color: #252631;
}

@media (max-width: 640px) {
  .selectedDeviceRowItem {
    font-size: 16px;
  }
}

@media (min-width: 641px) {
  .selectedDeviceRowItem {
    font-size: 18px;
  }
}

@media (max-width: 640px) {
  .selectedDeviceRowItem--rightPadded {
    margin-right: 16px;
  }
}

@media (min-width: 641px) {
  .selectedDeviceRowItem--rightPadded {
    margin-right: 30px;
  }
}

.pickList__view {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 640px) {
  .pickList__view {
    flex: 1 1;
    height: 100vh;
  }
}

@media (min-width: 641px) {
  .pickList__view {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100%;
    /* min-height: 1024px; */
    box-sizing: border-box;
  }
}
