.e-dropdownbase .e-list-item,
.e-dropdownbase .e-list-item.e-item-focus {
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  @media (max-width: 640px) {
    font-size: 14px;
  }
  /* color: var(--green); */
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  /* background: var(--green); */
  background: transparent;
}

.e-dropdownbase .e-list-item.e-item-focus,
.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover,
.e-dropdownbase .e-list-item.e-hover {
  /* background-color: #1f9c99; */
  color: var(--green);
  background-color: transparent;
}

.e-ddl.e-input-group.e-control-wrapper .e-input {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  @media (max-width: 640px) {
    font-size: 14px;
  }
}

.e-input-group .e-input-group-icon,
.e-input-group.e-control-wrapper .e-input-group-icon {
  font-size: 16px;
  color: var(--black);
  background: transparent;
  @media (max-width: 640px) {
    padding-right: 6px;
  }
}

.e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
  content: "\e36a";
  font-family: "e-icons";
}

/* @media (max-width: 640px) {
  span .e-input-group {
    position: relative;
  }
  .e-ddl-device,
  .e-popup-open {
    top: 232px !important;
  }
} */
