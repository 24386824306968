.refillReport__modal {
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.refillReport__overlay {
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 75px;
  box-sizing: border-box;
}

.refillReport__view {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.refillReport__searchBarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.refillReport__runReportButtonContainer {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.refillReport__deviceTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  margin-top: 34px;
}

.refillReport__backIcon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.refillReport__selectedDeviceRow {
  display: flex;
}

.refillReport__selectedDeviceRowItem {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #252631;
}

.refillReport__selectedDeviceRowItem--rightPadded {
  margin-right: 0;
}

.refillReport__deviceMultiSelectContainer {
  width: 650px;
  margin-left: 40px;
  margin-top: -10px;
}

.refillReport__dateRangeGroup {
  display: flex;
  flex-direction: column;
}

.refillReport__dateRangeLabel {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #089bab;
}

.refillReport__dateRange {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #121a2d;
  cursor: pointer;
}

.refillReport__batchesStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 0 34px;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #252631;
}

.refillReport__backIconAndReportTitleGroup {
  display: flex;
  align-items: center;
}

.refillReport__noBatchesFound {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #252631;
  margin: 0;
  padding: 0;
}

.refillReport__fileIcon {
  width: 32px;
  height: 32px;
  color: #089bab;
}

.refillReport__iconContainer {
  margin-top: 2px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 0;
}

.refillReport__runReportButtonContainer {
  margin-left: 8px;
}
