.mainData {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 10px 0 0 0;
  padding: 0;
}

.batchRow {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.patientDivider {
  margin-top: 15px;
  height: 3px;
  border-top: 1px dotted black;
  border-bottom: 1px dotted black;
  font-weight: 500;
}

.mainDataLiUl {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0 0 10px 0;
  padding: 0;
}

.mainDataLiUl li:not(last-child) {
  margin-right: 50px;
}

.subData {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.subDataRows {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.subDataRow {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.subDataHeadings {
  border-top: 1px solid #089bab;
  border-bottom: 1px solid #089bab;
}

.fontStyles {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 2.2;
  color: var(--black);
}

.largeFont {
  font-size: 16px;
}

.bold500 {
  font-weight: 500;
}

.alignRight {
  text-align: right;
  padding-right: 22px;
}

.subData li:not(last-child),
.subDataRows li:not(last-child) {
  margin-right: 50px;
}

.patientName {
  width: 300px;
  box-sizing: border-box;
}

.patientId {
  width: 140px;
  box-sizing: border-box;
}

.facility {
  width: 300px;
  box-sizing: border-box;
}

.room {
  width: 160px;
  box-sizing: border-box;
}

.product {
  /* width: 180px; */
  width: 260px;
  box-sizing: border-box;
}

/* .productStrength {
  width: 80px;
  box-sizing: border-box;
} */

.rxNumber {
  width: 100px;
  box-sizing: border-box;
}

.packageId {
  width: 100px;
  box-sizing: border-box;
}

.barcode {
  width: 66px;
  box-sizing: border-box;
}

.physician {
  width: 180px;
  box-sizing: border-box;
}

.totalQty {
  width: 80px;
  box-sizing: border-box;
  white-space: nowrap;
}

.qtyDestroyed {
  width: 104px;
  box-sizing: border-box;
  white-space: nowrap;
}

.qtyDestroyedLine {
  margin-left: 50px;
}

.productComments {
  margin-left: 10px;
  font-size: 12px;
  margin-top: -10px;
}

.printerIconContainer {
  display: flex;
  margin-left: 18px;
  cursor: pointer;
}

.printerIconContainer svg {
  width: 24px;
  stroke: var(--green);
}

.packagedOn {
  margin-left: 24px;
}
