@media (min-width: 376px) {
  .FdaNdcProductSearch__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(152, 169, 188, 0.3);
    backdrop-filter: blur(46.2108px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }

  .FdaNdcProductSearch__modal {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 60px;
    width: 926px;
    height: 603px;
    background: #ffffff;
    border-radius: 20px;
  }

  .FdaNdcProductSearch__close {
    position: absolute;
    top: 35px;
    right: 35px;
    cursor: pointer;
  }

  .FdaNdcProductSearch__header {
    display: flex;
  }

  .FdaNdcProductSearch__header h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 27px;
    color: #121a2d;
  }

  .FdaNdcProductSearch__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }

  .FdaNdcProductSearch__list-header {
    display: flex;
    width: 100%;
    border-bottom: 2px solid #089bab;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .FdaNdcProductSearch__list-header h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #121a2d;
  }

  /*.FdaNdcProductSearch__list-header:nth-child(1), .FdaNdcProductSearch__list-item:nth-child(1) {*/
  /*    min-width: 150px;*/
  /*    max-width: 150px;*/
  /*}*/

  /*.FdaNdcProductSearch__list-header:nth-child(2), .FdaNdcProductSearch__list-item:nth-child(2) {*/
  /*    min-width: 300px;*/
  /*}*/

  /*.FdaNdcProductSearch__list-header:nth-child(3), .FdaNdcProductSearch__list-item:nth-child(3) {*/
  /*    min-width: 250px;*/
  /*}*/

  /*.FdaNdcProductSearch__list-header:nth-child(4), .FdaNdcProductSearch__list-item:nth-child(4) {*/
  /*    min-width: 200px;*/
  /*    text-align: right;*/
  /*    flex: 1;*/
  /*}*/

  .FdaNdcProductSearch__list-body {
    display: flex;
    flex-direction: column;
    bottom: 20px;
    overflow: auto;
    max-height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-right: 5px;
  }

  .FdaNdcProductSearch__list-item {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    background: #ffffff;
    border: 1px solid #e8ecef;
    box-shadow: 0 5px 14px rgba(120, 146, 141, 0.05);
    border-radius: 10px;
    /*justify-content: space-between;*/
    margin: 5px 0;
    min-height: 100px;
  }

  .FdaNdcProductSearch__list-item-row {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .FdaNdcProductSearch__list-item-column {
    display: flex;
    flex-direction: column;
  }

  .FdaNdcProductSearch__list-item-column-large {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .FdaNdcProductSearch__list-item-row p {
    margin-right: 5px;
  }

  .FdaNdcProductSearch__list-item-row span {
    font-weight: 500;
  }

  .FdaNdcProductSearch__add-button {
    width: 100%;
    height: 100%;
    flex: 1;
    text-align: right;
    cursor: pointer;
  }

  .FdaNdcProductSearch__list-item p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #121a2d;
  }
}

@media (max-width: 375px) {
  .FdaNdcProductSearch__overlay {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .FdaNdcProductSearch__modal {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 24px;
    width: 90%;
    height: 504px;
    background: #ffffff;
    border-radius: 20px;
    margin: auto;
  }

  .FdaNdcProductSearch__close {
    position: absolute;
    top: 35px;
    right: 35px;
    cursor: pointer;
  }

  .FdaNdcProductSearch__header {
    display: flex;
    justify-content: flex-start;
    align-self: center;
  }

  .FdaNdcProductSearch__header h1 {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #121a2d;
    padding: 0;
    margin: 0;
  }

  .FdaNdcProductSearch__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }

  .FdaNdcProductSearch__list-header {
    display: flex;
    width: 100%;
    border-bottom: 2px solid #089bab;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .FdaNdcProductSearch__list-header h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #121a2d;
  }

  .FdaNdcProductSearch__list-body {
    display: flex;
    flex-direction: column;
    bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
    max-height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-right: 5px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .FdaNdcProductSearch__list-body::-webkit-scrollbar {
    display: none;
  }

  .FdaNdcProductSearch__list-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    background: #ffffff;
    border: 1px solid #e8ecef;
    box-shadow: 0 5px 14px rgba(120, 146, 141, 0.05);
    border-radius: 10px;
    margin: 5px 0;
    min-height: 100px;
  }

  .FdaNdcProductSearch__list-item-row {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .FdaNdcProductSearch__list-item-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 10px;
  }

  .FdaNdcProductSearch__list-item-column-large {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .FdaNdcProductSearch__list-item-row p {
    margin-right: 5px;
  }

  .FdaNdcProductSearch__list-item-row span {
    font-weight: 500;
  }

  .FdaNdcProductSearch__add-button {
    width: 100%;
    height: 100%;
    flex: 1;
    text-align: right;
    cursor: pointer;
  }

  .FdaNdcProductSearch__list-item p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #121a2d;
  }
}
