.TruPakAlarmsCard__reportList {
  list-style: none;
  padding: 10px;
  margin: 0;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #e8ecef;
  filter: box-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
}

.TruPakAlarmsCard__reportListItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.TruPakAlarmsCard__reportListItem:not(:last-child) {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.TruPakAlarmsCard__dataContainer {
  font-family: "Poppins", sans-serif;
  color: var(--black);
  width: 100%;
  box-sizing: border-box;
}

.TruPakAlarmsCard__dataContainer div {
  margin-bottom: 0;
}

.TruPakAlarmsCard__dataContainer h1 {
  font-size: 14px;
  font-weight: 500;
  padding: 0 0 10px 0;
  margin: 0;
  line-height: 1;
}

.TruPakAlarmsCard__dataContainer p {
  font-size: 13px;
  font-weight: 300;
  padding: 0;
  margin: 0;
  line-height: 1;
}
