.EV54CanisterManagement_column {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*box-sizing: border-box;*/
    /*padding: 20px;*/
}

.EV54CanisterManagement_row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.EV54CanisterManagement_header {
    justify-content: space-between;
}

.EV54CanisterManagement_header h1 {
    font-weight: 600;
    font-size: 25px;
    line-height: 49px;
    color: #089BAB;
    margin: 0;
}

.EV54CanisterManagement_icon {
    color: #121a2d;
    opacity: 0.5;
}

.EV54CanisterManagement_columnCard {
    background-color: #089bab;
    color: white;
    box-sizing: border-box;
    padding: 5px 10px;
    cursor: pointer;
    min-width: 150px;
}

.EV54CanisterManagement_columnCard p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}

.EV54CanisterManagement_canisterCard {
    background-color: #089bab;
    box-sizing: border-box;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    min-width: 150px;
}

.EV54CanisterManagement_canisterCard p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    margin: 0;
}

.EV54CanisterManagement_canisterButton {
    background-color: #E7E7E8;
    box-sizing: border-box;
    padding: 5px 10px;
    cursor: pointer;
}

.EV54CanisterManagement_canisterButton p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #121A2D;
    margin: 0;
}
