.VBMDevice__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

@media (min-width: 641px) {
  .VBMDevice__container {
    max-height: 700px;
  }
}

.VBMDevice__container p {
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #121a2d;
}
