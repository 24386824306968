.column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

h1 {
    margin: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    color: var(--jfcBlack);
}

h2 {
    margin: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: var(--jfcBlack);
}

.primaryButton {
    background-color: var(--jfcOrange);
    color: white;
    width: 150px;
}