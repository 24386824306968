.TrayGridLabelContainer {
  rotate: -90deg;
  text-align: center;
  vertical-align: middle;
}

.TrayGridLabel {
  font-size: 16px;
  margin-top: -20%;
  font-weight: bold;
  padding: 10% 50% 10% 40%;
  border: 1px solid black;
}