@media (min-width: 641px) {
  .changePassword__overlay {
    display: block;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    background: rgba(152, 169, 188, 0.3);
    backdrop-filter: blur(46.2108px);
    z-index: 2;
  }
}

@media (max-width: 640px) {
  .changePassword__container {
    height: 100%;
  }
}

.changePassword__view {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 50;
}

@media (max-width: 639px) {
  .changePassword__view {
    margin: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

@media (min-width: 640px) {
  .changePassword__view {
    margin: 100px auto;
    width: 1000px;
    height: 739px;
    border: 1px solid #fff;
    border-radius: 20px;
  }
}

.changePassword__header {
  display: flex;
}

@media (max-width: 639px) {
  .changePassword__header {
    flex-direction: row;
    box-sizing: border-box;
    padding-top: 24px;
    padding-left: 24px;
    width: 100%;
    min-height: 120px;
    background-color: #089bab;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

@media (min-width: 640px) {
  .changePassword__header {
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 66px;
    height: 100px;
    background-color: transparent;
  }
}

.changePassword__body {
  display: flex;
  flex-direction: column;
  width: 91.5%;
  height: 100%;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 640px) {
  .changePassword__body {
    width: auto;
    height: 100%;
    margin-top: 0;
    margin-right: 66px;
    margin-left: 66px;
  }
}

.changePassword__col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.changePassword__title {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
  line-height: 33px;
}

@media (min-width: 640px) {
  .changePassword__title {
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 27px;
    color: #121a2d;
  }
}

.changePassword__closeIcon {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.changePassword__footer {
  display: flex;
}

@media (max-width: 640px) {
  .changePassword__footer {
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 30px;
    padding-top: 25px;
  }
}

@media (min-width: 641px) {
  .changePassword__footer {
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 38px;
    margin-left: 50px;
    margin-right: 108px;
    margin-bottom: 51px;
  }
}

.changePassword__backArrow {
  padding-right: 15px;
  margin-top: 4px;
}
