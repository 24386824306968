.Devices__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

@media (max-width: 640px) {
  .Devices__headerContainer {
    display: flex;
    align-items: center;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 641px) {
  .Devices__headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .Devices__header {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #121a2d;
  }
}

.Devices__row {
  display: flex;
  width: 100%;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .Devices__header {
    margin-bottom: 12px;
  }
}

@media (max-width: 640px) {
  .Devices__row {
    margin-top: 24px;
    white-space: nowrap;
  }
}

.Devices__devices {
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  height: 100%;
  overflow: auto;
  margin-top: 30px;
}

@media (max-width: 640px) {
  .Devices__title {
    position: absolute;
    top: 25px;
    left: 52px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 1;
    color: #121a2d;
  }
}

.Devices__noSitesMessage {
  background-color: #d1ecf1;
  justify-content: center;
  padding: 0.75rem 1.25rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  border: 1px solid #bee5eb;
  border-radius: 0.25rem;
}

@media (max-width: 640px) {
  .Devices__noSitesMessage {
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }
}

.Devices__noSitesMessage p {
  color: #0c5460;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
