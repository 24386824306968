.input {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  width: 100%;
  height: 30px;
  color: #121a2d;
  background-color: #fff;
  border: 2px solid #089bab;
  border-radius: 4px;
  box-sizing: border-box;
  padding-left: 8px;
  outline: none;
}
