.Table__container {
  width: 100%;
  height: 100%;
  /* border-collapse: collapse; */
}

.Table__container table {
  width: 100%;
}

.Table__container th:after,
.Table__container th:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

.Table__container th:before {
  /* border-top: 2px solid #089bab; */
  top: -1px;
}

.Table__container th:after {
  bottom: -1px;
  /* border-bottom: 2px solid #089bab; */
}

.Table__container .Table__main th:after {
  border-top: 1px solid #089bab;
}

.Table__container .Table__main th:before {
  border-bottom: 1px solid #089bab;
}

.Table__container .Table__sub th:after {
  border-top: 1px solid rgb(255, 171, 43);
}

.Table__container .Table__sub th:before {
  border-bottom: 1px solid rgb(255, 171, 43);
}

.Table__container thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #121a2d;
  background-color: #fff;
  cursor: pointer;
}

@media (max-width: 1007px) {
  .Table__container thead th {
    font-size: 13px;
    padding: 6px 6px;
  }
}

@media (min-width: 1008px) {
  .Table__container thead th {
    font-size: 14px;
    padding: 10px 10px;
  }
}

.Table__container tbody td {
  text-align: left;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #121a2d;
}

@media (max-width: 1007px) {
  .Table__container tbody td {
    font-size: 13px;
    padding: 6px 6px;
  }
}

@media (min-width: 1008px) {
  .Table__container tbody td {
    font-size: 14px;
    padding: 10px 10px;
  }
}

.Table__container tbody {
  border: 1px solid #089bab;
}

.Table__mainRow--light {
  background-color: #fff;
}

.Table__mainRow--dark {
  background-color: rgba(123, 129, 139, 0.2);
}

.Table__mainRow td {
  border-right: 1px solid #089bab;
}

.Table__subRow td,
.Table__subRow th {
  border-right: 1px solid rgb(255, 171, 43);
}

.Table__subRow--light {
  background-color: #fff;
}

.Table__subRow--dark {
  background-color: rgba(123, 129, 139, 0.1);
}

.Table__row:hover {
  cursor: cell;
}

.Table__main {
  border: 1px solid #089bab;
}

.Table__sub {
  border: 1px solid rgb(255, 171, 43);
}

.Table__main tr {
  border: 1px solid #089bab;
}

.Table__main th {
  border-right: 1px solid #089bab;
}

.Table__sub tr {
  border: 1px solid rgb(255, 171, 43);
}

.Table__sub th {
  border-right: 1px solid rgb(255, 171, 43);
}

.Table__expandIcon,
.Table__collapseIcon {
  display: flex;
  align-items: center;
}

.Table__expandIcon span,
.Table__collapseIcon span {
  margin-left: 3px;
}

.Table__collapseIcon:first-child {
  margin-right: 3px;
}

.Table__expandIcon rect,
.Table__collapseIcon rect {
  stroke: #089bab;
  fill: transparent;
}

.Table__collapseIcon rect {
  stroke-width: 4;
}

.Table__expandIcon svg,
.Table__collapseIcon svg {
  width: 16px;
  height: 16px;
}

.Table__collapseIcon svg {
  border-radius: 50%;
}

.Table__showHoverPointer {
  cursor: pointer;
}

.Table__main th,
.Table__sub th {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #121a2d;
  padding-bottom: 5px;
}

.Table__main td,
.Table__sub td {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
}

.Table__main tr > td:last-child,
.Table__main tr > td:nth-last-child(2),
.Table__sub tr > td:last-child,
.Table__sub tr > td:nth-last-child(2),
.Table__sub tr > td:nth-last-child(3),
.Table__sub tr > td:nth-last-child(4),
.Table__main th:last-child,
.Table__main th:nth-last-child(2),
.Table__sub th:last-child,
.Table__sub th:nth-last-child(2),
.Table__sub th:nth-last-child(3),
.Table__sub th:nth-last-child(4) {
  text-align: right;
}
