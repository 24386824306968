.input {
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  font-size: 24px;
  font-weight: 300;
  color: #089bab;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-top: 5px;
  padding-top: 3px;
}
