.column {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.headerRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 49px;
    color: #454B60;
}

h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #121A2D;
    margin: 5px 0;
}

h2 span {
    color: #089BAB;
}

h1 span {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 49px;
    color: #089BAB;
    margin: 0 10px;
}

.backButton {
    cursor: pointer;
    margin-right: 10px;
}

tbody {
    padding: 0;
    margin: 0;
}

tr,
td {
    vertical-align: middle;
    padding: 0;
    margin: 0;
}

.DescriptionParagraph {
    margin-top: -5px;
    font-size: 12px;
    color: #A0A0A0;
}