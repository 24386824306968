.DatePickerComponent__wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 10px;
}

.DatePickerComponent__wrapper--alternateFormat {
  padding-bottom: 0;
}

.DatePickerCompoent__wrapper--alternateFormat {
  padding-bottom: 10px;
}

.DatePickerComponent__wrapper > label {
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--green);
  padding-bottom: 7px;
}

.DatePickerComponent__wrapper input[type="text"] {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 1px;
  outline: none;
  border-bottom: 1px solid #121a2d;
}

.DatePickerComponent__wrapper--alternateFormat input[type="text"] {
  font-size: 14px;
  line-height: 1;
  padding: 0;
  outline: none;
  border: none;
  width: 90px;
}
