.input {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border: none;
  outline: none;
  flex: 1;
  flex-direction: row;
  max-height: 32px;
  background: transparent;
}

.controlGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 45px;
  margin-bottom: 8px;
}
