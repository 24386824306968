.SFDropDownList__labelContainer {
  padding-bottom: 0;
  margin-top: -4px;
}

.SFDropDownlist__label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--green);
  padding: 0 0 4px 0;
  margin: 0 0 0px 0;
}
