.EV54Alarms_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.EV54Alarms_row {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    box-sizing: border-box;
    border: #089bab 1px solid;
    border-radius: 16px;
    cursor: pointer;
}

.EV54Alarms_row p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #089BAB;
    margin-left: 5px;
}
