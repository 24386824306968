.BarcodeComponent__barcodeContainer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BarcodeComponent__defaultValue {
  padding: 0;
  margin: -22px 0 0 0;
  line-height: 2;
  font-size: 11px;
}
