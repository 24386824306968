.DeviceRouteSelector__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 50;
    background: rgba(152, 169, 188, 0.3);
    backdrop-filter: blur(20.2108px);
}

.DeviceRouteSelector__modal {
    background: #FFFFFF;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.DeviceRouteSelector__modal header {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.DeviceRouteSelector__modal_body h1 {
    margin: 40px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 27px;
    text-align: center;
    color: #121A2D;
}

.DeviceRouteSelector__modal_body {
    display: flex;
    flex-direction: column;
    align-content: center;
    box-sizing: border-box;
    padding: 0 20px;
}

.DeviceRouteSelector__modal footer {
    margin-top: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
}

.DeviceRouteSelector__modal footer button {
    border: none;
    background: #089BAB;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    height: 48px;
    padding: 0 40px;
    cursor: pointer;
}

.DeviceRouteSelector__modal footer button[disabled]{
    opacity: 0.5;
}
