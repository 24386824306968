.scrollArea {
  overflow-x: hidden;
  overflow-y: auto;
}

@media only screen and (max-width: 640px) {
  .scrollArea {
    padding: 4px 3px 3px 3px;
    height: 0;
    flex: 1 0 auto;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }

  .scrollArea::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width: 641px) {
  .scrollArea {
    padding: 4px 10px 3px 3px;
    height: 270px;
  }
}

@media only screen and (min-width: 641px) {
  .locationsData {
    margin-left: 60px;
    margin-right: 63px;
  }
}

.locationsDisplay {
  display: flex;
  flex-direction: column;
  margin-top: 43px;
}

@media only screen and (max-width: 640px) {
  .locationsDisplay {
    flex: 1 0 auto;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 641px) {
  .locationsDisplay {
    padding-bottom: 43px;
    margin-top: 0;
  }
}

.locationsDisplay__heading {
  font-family: "Poppins", sans-serif;
  color: #093a64;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (min-width: 641px) {
  .locationsDisplay__heading {
    margin-left: 60px;
  }
}

.locationsData {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  margin-top: 16px;
}
