.confirmation__barcode {
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
  border-radius: 6px;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  padding-left: 10px;
  color: #363f4c;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.44;
}

@media only screen and (min-width: 641px) {
  .confirmation__barcode {
    margin-bottom: 40px;
    width: auto;
  }
}
