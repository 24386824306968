.editPAForm__container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 100px auto;
  width: 1000px;
  height: 100%;
  background-color: white;
  border: 1px solid white;
  border-radius: 20px;
  z-index: 50;
  padding: 40px 60px;
  box-sizing: border-box;
}

.editPAForm__title {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 27px;
  color: #121a2d;
}

.editPAForm__description {
  margin: 0;
  padding-top: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  color: #121a2d;
}

.editPAForm__closeIcon {
  position: absolute;
  right: 20px;
  top: 20px;
}

.editPAForm__closeIcon:hover {
  cursor: pointer;
}

.editPAForm__quantities {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.editPAForm__qohGroup {
  display: flex;
  align-items: center;
}

.editPAForm__qoh {
  padding-left: 5px;
}

.editPAForm__input {
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #000000;
  outline: none;
  width: 100%;
  margin-bottom: 40px;
}

.editPAForm__buttonGroup {
  margin-top: 250px;
  display: flex;
  justify-content: center;
}

.editPAForm__submitBtn {
  margin-left: 20px;
}

.editPAForm__cancelBtn {
  margin-right: 20px;
}

.editPAForm__label {
  font-family: Poppins;
  color: #089bab;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.editPAForm__quantitiesErrorMsg {
  padding-top: 16px;
}
