.stockLocationOption {
  padding: 10px;
  margin-top: 16px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  border-radius: 6 px;
}

.stockLocationOption__message {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #363f4c;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.stockLocationOption__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stockLocationOption__button {
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 34px;
  margin-right: 0;
  margin-left: 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.stockLocationOption__button--create {
  color: rgb(255, 255, 255);
  background-color: rgb(8, 155, 171);
  margin-bottom: 14px;
}

.stockLocationOption__button--cancel {
  background-color: rgb(231, 231, 232);
}

.stockLocationOption__stockLocation {
  font-weight: 600;
  font-style: italic;
}

.stockLocationOption__messageStyling {
  font-weight: 600;
}
