.EV54CanisterAudit_column {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    /*padding: 10px;*/
    z-index: 1;
}

.EV54CanisterAudit_column__main {
    border: 1px solid #d2d5e1;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
}

.EV54CanisterAudit_row {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0;
}

.EV54CanisterAudit_row p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #121A2D;
    margin: 10px 0;
}

.EV54CanisterAudit_row h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #121A2D;
    margin: 10px 0;
}

.EV54CanisterAudit_row h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #089BAB;
    margin: 0;
}

.EV54CanisterAudit_divider {
    border-bottom: 2px solid #089bab;
}

.EV54CanisterAudit_header {
    justify-content: space-between;
}

.EV54CanisterAudit_header h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 49px;
    color: #089BAB;
}

.EV54CanisterAudit_header span {
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 49px;
    color: #454B60;
}

.EV54CanisterAudit_icon {
    color: #121a2d;
    opacity: 0.5;
}

.EV54CanisterAudit_button__primary {
    background-color: #089bab;
    color: white;
    opacity: 1;
    border: none;
    padding: 15px 40px;
    border-radius: 10px;
    cursor: pointer;
}

.EV54CanisterAudit_button__primary:disabled, .EV54CanisterAudit_button__secondary:disabled {
    opacity: 0.5;
}


.EV54CanisterAudit_button__secondary {
    background-color: #dedede;
    color: #121a2d;
    opacity: 1;
    border: none;
    padding: 15px 40px;
    border-radius: 10px;
    cursor: pointer;
}

