.associateProductBarcode {
  padding: 10px;
  margin-top: 16px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  border-radius: 6px;
}

@media only screen and (max-width: 640px) {
  .associateProductBarcode {
    padding: 14px 10px 18px 10px;
  }
}

@media only screen and (min-width: 641px) {
  .associateProductBarcode {
    padding: 30px 20px;
    margin-top: 40px;
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 43px;
  }
}

.associateProductBarcode__message {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #363f4c;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

@media only screen and (min-width: 641px) {
  .associateProductBarcode__message {
    margin-bottom: 20px;
  }
}

.associateProductBarcode__buttons {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 640px) {
  .associateProductBarcode__buttons {
    flex-direction: row-reverse;
    justify-content: center;
  }
}

@media only screen and (min-width: 641px) {
  .associateProductBarcode__buttons {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }
}

.associateProductBarcode__button {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 34px;
  margin-right: 0;
  margin-left: 0;
  border: none;
  border-radius: 6px;
}

@media only screen and (max-width: 640px) {
  .associateProductBarcode__button {
    height: 48px;
    width: 122px;
  }
}

@media only screen and (min-width: 641px) {
  .associateProductBarcode__button {
    height: 48px;
    border-radius: 10px;
    cursor: pointer;
  }
}

.associateProductBarcode__button--create {
  color: rgb(255, 255, 255);
  background-color: rgb(8, 155, 171);
}

@media only screen and (max-width: 640px) {
  .associateProductBarcode__button--create {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 641px) {
  .associateProductBarcode__button--create {
    width: 213px;
  }
}

.associateProductBarcode__button--cancel {
  color: #121a2d;
  background-color: rgb(231, 231, 232);
}

@media only screen and (min-width: 641px) {
  .associateProductBarcode__button--cancel {
    width: 138px;
    margin-right: 20px;
  }
}

.associateProductBarcode__stockLocation {
  font-weight: 600;
  font-style: italic;
}

.associateProductBarcode__messageStyling {
  font-weight: 600;
}
