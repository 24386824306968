@media (min-width: 641px) {
  .addEquiv__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(152, 169, 188, 0.3);
    backdrop-filter: blur(46.2108px);
    z-index: 210;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .addEquiv__overlay {
    position: fixed;
    left: 0;
  }
}

@media (min-width: 1008px) {
  .addEquiv__overlay {
    left: -120px; /* Needed to cover vertical menu */
  }
}

.addEquiv__view {
  display: flex;
  flex-direction: column;
  position: relative;
}

@media (max-width: 640px) {
  .addEquiv__view {
    color: #fff;
    margin: 12px 12px 0 12px;
    flex: 1 1;
    max-height: 100vh;
    overflow: hidden;
  }
}

@media (min-width: 641px) {
  .addEquiv__view {
    margin: auto;
    max-height: 1100px;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    min-height: 500px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .addEquiv__view {
    padding: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
  }
}

@media (min-width: 1008px) {
  .addEquiv__view {
    margin-top: 60px;
    margin-bottom: 30px;
    width: 1000px;
  }
}

@media (max-width: 640px) {
  .addEquiv__container {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 640px) {
  .addEquiv__header {
    display: flex;
    flex-wrap: wrap;
    margin-left: 18px;
    margin-top: 1px;
    margin-bottom: 18px;
    margin-right: 22px;
  }
}

@media (min-width: 641px) {
  .addEquiv__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .addEquiv__header {
    margin-bottom: 50px;
  }
}

@media (min-width: 1008px) {
  .addEquiv__header {
    margin-bottom: 50px;
  }
}

@media (max-width: 640px) {
  .addEquiv__headerGroup {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .addEquiv__backArrow {
    display: flex;
    align-items: center;
    padding-right: 4px;
  }
}

@media (max-width: 640px) {
  .addEquiv__goBackTitle {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(18, 26, 45, 0.5);
    line-height: 1;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 640px) {
  .addEquiv__breakToNewRow {
    flex-basis: 100%;
    height: 0;
  }
}

@media (max-width: 640px) {
  .addEquiv__addEquivalencyLabel {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-weight: 400;
    color: #ffffff;
  }
}

@media (max-width: 640px) {
  .addEquiv__title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #121a2d;
    font-size: 20px;
    line-height: 1;
    margin: 24px 0 0 2px;
    padding: 0;
  }
}

@media (min-width: 641px) {
  .addEquiv__title {
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 27px;
    color: #121a2d;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .addEquiv__title {
    margin: 0;
  }
}

@media (min-width: 1008px) {
  .addEquiv__title {
    margin-left: 66px;
    margin-top: 64px;
    margin: 64px 0 0 66px;
  }
}

@media (max-width: 640px) {
  .addEquiv__searchBarContainer {
    flex-grow: 1;
    margin-left: 12px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .addEquiv__searchBarContainer {
    margin-bottom: 58px;
  }
}

@media (min-width: 1008px) {
  .addEquiv__searchBarContainer {
    margin-left: 66px;
    margin-bottom: 58px;
  }
}

@media (max-width: 640px) {
  .addEquiv__searchBar {
    width: 737px;
    height: 48px;
  }
}

@media (max-width: 640px) {
  .addEquiv__productsHeadingsContainer {
    margin-left: 66px;
    margin-right: 66px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .addEquiv__productsHeadingsContainer {
    margin-bottom: 20px;
  }
}

@media (min-width: 1008px) {
  .addEquiv__productsHeadingsContainer {
    margin-left: 66px;
    margin-right: 66px;
    margin-bottom: 20px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .addEquiv__closeIconContainer {
    position: absolute;
    top: 35px;
    right: 35px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

@media (min-width: 1008px) {
  .addEquiv__closeIconContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-top: 35px;
    margin-right: 36px;
  }
}

@media (min-width: 641px) {
  .addEquiv__closeIcon {
    fill: #121a2d;
    cursor: pointer;
  }
}
