.timeRangeContainer {
  position: absolute;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1;
  color: #121a2d;
  bottom: -6px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
}

@media (max-width: 640px) {
  .timeRangeContainer {
    justify-content: center;
    margin-left: -10px;
  }
}

@media (min-width: 641px) {
  .timeRangeContainer {
    justify-content: space-between;
  }
}

.timeRangeOption {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 640px) {
  .timeRangeOption:not(:last-child) {
    padding-right: 7px;
  }
}

.squareContainer svg {
  width: 16px;
  fill: transparent;
  stroke: var(--green);
  padding-right: 3px;
}

.squareContainer--active svg {
  fill: var(--green);
}
