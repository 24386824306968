.TrayCheckFinishedModalBackground {
  background-color: #F8EFBA !important;
}

.TrayCheckFinishedModalContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.TrayCheckFinishedModalFooter {
  gap: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.TrayCheckFinishedRow {
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TrayCheckFinishedData {
  font-weight: bold;
}