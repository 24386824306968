@media (max-width: 640px) {
  .createSite__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

@media (min-width: 641px) {
  .createSite__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(152, 169, 188, 0.3);
    backdrop-filter: blur(46.2108px);
    z-index: 210;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .createSite__overlay {
    width: 100vw;
    left: -70px;
    position: absolute;
    min-height: 670px;
    height: 100%;
    top: 0;
    right: 0;
  }
}

@media (min-width: 1008px) {
  .createSite__overlay {
    left: -120px; /* Needed to cover vertical menu */
    height: 100%;
    min-height: 1068px;
  }
}

.createSite__row {
  display: flex;
}

.createSite__col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (max-width: 640px) {
  .createSite__row {
    flex-direction: column;
    width: 100%;
  }
}

@media (min-width: 641px) {
  .createSite__row {
    flex-direction: row;
  }
}

.createSite__view {
  display: flex;
  flex-direction: column;
  position: relative;
}

@media (max-width: 640px) {
  .createSite__view {
    height: 100%;
    color: #fff;
    margin: 12px 12px 0 12px;
  }
}

@media (min-width: 641px) {
  .createSite__view {
    margin: 100px auto;
    max-height: 1000px;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .createSite__view {
    height: 100vh;
    max-height: 600px;
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
  }
}

@media (min-width: 1008px) {
  .createSite__view {
    width: 1000px;
    min-height: 600px;
    max-height: 900px;
  }
}

@media (max-width: 640px) {
  .createSite__header {
    margin-left: 18px;
    margin-top: 1px;
    margin-bottom: 24px;
  }
}

@media (min-width: 641px) {
  .createSite__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .createSite__header {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 0;
  }
}

@media (min-width: 1008px) {
  .createSite__header {
    margin-top: 64px;
    margin-right: 20px;
    margin-left: 66px;
    margin-bottom: 59px;
  }
}

@media (max-width: 640px) {
  .createSite__backArrow {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .createSite__goBackTitle {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(18, 26, 45, 0.5);
    line-height: 1;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 640px) {
  .createSite__backArrow__icon {
    padding-right: 4px;
  }
}

.createSite__title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #121a2d;
}

@media (max-width: 640px) {
  .createSite__title {
    font-size: 20px;
    line-height: 1;
    margin: 24px 0 0 2px;
    padding: 0;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .createSite__title {
    margin: 0 0 30px 0;
    font-size: 30px;
    line-height: 1;
  }
}

@media (min-width: 1008px) {
  .createSite__title {
    margin: 0;
    font-size: 34px;
    line-height: 27px;
  }
}

@media (max-width: 640px) {
  .createSite__scrollArea {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }
}

@media (min-width: 641px) {
  .createSite__scrollArea {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
    margin-right: 20px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .createSite__scrollArea {
    height: 100%;
  }
}

@media (min-width: 1008px) {
  .createSite__scrollArea {
    height: 100%;
  }
}

@media (max-width: 640px) {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .createSite__scrollArea::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 641px) {
  .createSite__scrollArea::-webkit-scrollbar-track {
    margin-top: 40px;
  }
}

.createSite__formBody {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-right: 24px;
}

.createSite__groupedAccordion {
  position: relative;
}

.createSite__groupedAccordion--noBottomMargin {
  margin-bottom: -40px;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .createSite__groupedAccordion--noBottomMargin {
    margin-bottom: 0;
  }
}

@media (max-width: 640px) {
  .createSite__accordions {
    width: 100%;
    padding-right: 12px;
    box-sizing: border-box;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .createSite__accordions {
    margin-left: 20px;
    margin-right: 10px;
  }
}

@media (min-width: 1008px) {
  .createSite__accordions {
    width: 878px;
    margin-left: 64px;
  }
}

.createSite__accordion {
  position: relative;
  margin-bottom: 20px;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .createSite__accordion {
    margin-bottom: 20px;
  }
}

@media (min-width: 1008px) {
  .createSite__accordion {
    margin-bottom: 40px;
  }
}

.createSite__accordion:last-child {
  position: relative;
  margin-bottom: 30px;
}

.createSite__accordion--0 {
  z-index: 100;
}

.createSite__accordion--1 {
  z-index: 90;
}

.createSite__accordion--2 {
  z-index: 80;
}

.createSite__accordion--3 {
  z-index: 70;
}

@media (min-width: 641px) {
  .createSite__accordion:last-child {
    margin-bottom: 0;
  }
}

.createSite__formHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 66px;
  margin-top: 64px;
  margin-bottom: 81px;
}

.createSite__formFooter {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 43px;
  margin-left: 50px;
  margin-right: 108px;
  margin-bottom: 86px;
}

.createSite__footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .createSite__footer {
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 30px;
    padding-top: 25px;
  }
}

@media (min-width: 641px) {
  .createSite__footer {
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 38px;
    margin-left: 50px;
    margin-right: 108px;
    margin-bottom: 51px;
  }
}

.createSite__formCloseContainer {
  position: absolute;
  top: 35px;
  right: 36px;
}

.createSite__formCloseContainer:hover {
  cursor: pointer;
}

@media (max-width: 640px) {
  .createSite__checkboxContainer {
    position: absolute;
    top: 7px;
    right: 0;
  }
}

@media (min-width: 641px) {
  .createSite__checkboxContainer {
    position: absolute;
    top: 4px;
    right: 0;
  }
}

.createSite__checkboxContainer--2 {
  z-index: 85;
}

.createSite__checkboxContainer--3 {
  z-index: 75;
}

@media only screen and (max-width: 640px) {
  .createSite__errorBannerContainer {
    margin: 10px;
    position: relative;
    z-index: 210;
  }
}

@media only screen and (min-width: 641px) {
  .createSite__errorBannerContainer {
    width: 100%;
    margin-bottom: 12px;
    z-index: 250;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1007px) {
  .createSite__errorBannerContainer {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1008px) {
  .createSite__errorBannerContainer {
    margin-top: 10px;
  }
}
