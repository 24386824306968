.prodList__container {
  color: #121a2d;
  font-family: Poppins;
  font-weight: 300;
  font-size: 14px;
  margin-top: 21px;
}

.prodList__container--red {
  color: red;
}

.prodList__row {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e8ecef;
  box-shadow: 0px 5px 14px rgba(120, 146, 141, 0.05);
  border-radius: 10px;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .prodList__row {
    height: 48px;
  }
}

@media (min-width: 1008px) {
  .prodList__row {
    height: 79px;
  }
}

.productList__row--withScroll {
  padding-right: 10px;
}

.prodList__row:not(:last-child) {
  margin-bottom: 10px;
}

.prodList__id {
  margin-left: 20px;
  padding-right: 20px;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .prodList__id {
    width: 135px;
    min-width: 135px;
  }
}

@media (min-width: 1008px) {
  .prodList__id {
    width: 169px;
    min-width: 169px;
  }
}

.prodList__description {
  padding-right: 20px;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .prodList__description {
    width: 320px;
    min-width: 320px;
  }
}

@media (min-width: 1008px) {
  .prodList__description {
    width: 440px;
    min-width: 440px;
  }
}

.prodList__manufacturer {
  padding-right: 20px;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .prodList__manufacturer {
    width: 168px;
    min-width: 168px;
  }
}

@media (min-width: 1008px) {
  .prodList__manufacturer {
    width: 210px;
    min-width: 210px;
  }
}

.prodList__deleteIcon {
  height: auto;
}

@media (min-width: 641px) {
  .prodList__deleteIconContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 29px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .prodList__deleteIcon {
    width: 30px;
  }
}

@media (min-width: 1008px) {
  .prodList__deleteIcon {
    width: 40px;
  }
}

@media (max-width: 640px) {
  .prodList__deleteIcon {
    width: 30px;
  }
}

.prodList__deleteIcon:hover {
  cursor: pointer;
}

.prodList__deleteInProgress {
  height: auto;
}

@media (min-width: 641px) {
  .prodList__deleteInProgress {
    width: 40px;
  }
}

@media (max-width: 640px) {
  .prodList__deleteInProgress {
    width: 30px;
  }
}

.prodList__deleteInProgress:hover {
  cursor: not-allowed;
}

.prodList__selectedDeleteIcon {
  height: auto;
  stroke: #df4759;
  background: #df4759;
  border-radius: 50%;
}
@media (min-width: 641px) {
  .prodList__selectedDeleteIcon {
    width: 40px;
  }
}

@media (max-width: 640px) {
  .prodList__selectedDeleteIcon {
    width: 30px;
  }
}

.prodList__selectedDeleteIcon:hover {
  cursor: not-allowed;
}

@media (max-width: 640px) {
  .prodList__scrollArea {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .prodList__scrollArea::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 641px) {
  .prodList__scrollArea {
    color: #121a2d;
    font-family: Poppins;
    font-weight: 300;
    margin-top: 21px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    margin-top: 20px;
    padding-right: 10px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .prodList__scrollArea {
    font-size: 13px;
    max-height: 500px;
  }
}

@media (min-width: 1008px) {
  .prodList__scrollArea {
    font-size: 14px;
    max-height: 600px;
  }
}
