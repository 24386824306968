.pickList__subHeading {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.pickList__subContainer {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
}

.pickList__subColumnName {
  font-weight: 500;
  font-size: 14px;
  color: #089bab;
  margin: 0;
  padding: 0;
}

.pickList__subColumnName--item1 {
  margin-left: 40px;
  margin-right: 270px;
}

.pickList__subColumnName--item2 {
  margin-right: 258px;
}

.pickList__subColumnName--item3 {
  margin-right: 150px;
}

.pickList__subColumnName--item4 {
  width: 100%;
  text-align: right;
  margin-right: 22px;
}
