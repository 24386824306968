.inventoryEquivProductsHeadings__col {
  display: flex;
  flex-direction: column;
}

.inventoryEquivProductsHeadings__tableHeadings {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid #089bab;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .inventoryEquivProductsHeadings__tableHeadings {
    padding-bottom: 13px;
    font-size: 13px;
  }
}

@media (min-width: 1008px) {
  .inventoryEquivProductsHeadings__tableHeadings {
    padding-bottom: 14px;
    font-size: 14px;
  }
}

.inventoryEquivProductsHeadings {
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #121a2d;
  margin-left: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .inventoryEquivProductsHeadings {
    font-size: 13px;
  }
}

@media (min-width: 1008px) {
  .inventoryEquivProductsHeadings {
    font-size: 14px;
  }
}

.inventoryEquivProductsHeadings__ID {
  margin-left: 20px;
  padding-right: 20px;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .inventoryEquivProductsHeadings__ID {
    width: 135px;
    min-width: 135px;
  }
}

@media (min-width: 1008px) {
  .inventoryEquivProductsHeadings__ID {
    width: 169px;
    min-width: 169px;
  }
}

.inventoryEquivProductsHeadings__description {
  padding-right: 20px;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .inventoryEquivProductsHeadings__description {
    width: 320px;
    min-width: 320px;
  }
}

@media (min-width: 1008px) {
  .inventoryEquivProductsHeadings__description {
    width: 440px;
    min-width: 440px;
  }
}

.inventoryEquivProductsHeadings__manufacturer {
  padding-right: 20px;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .inventoryEquivProductsHeadings__manufacturer {
    width: 168px;
    min-width: 168px;
  }
}

@media (min-width: 1008px) {
  .inventoryEquivProductsHeadings__manufacturer {
    width: 210px;
    min-width: 210px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .inventoryEquivProductsHeadings__actions {
    display: none;
  }
}

@media (min-width: 1008px) {
  .inventoryEquivProductsHeadings__actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 29px;
  }
}

.inventoryEquivProductsHeadings__overflowHidden {
  overflow: hidden;
  white-space: nowrap;
}
