.AddRetailScript__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.AddRetailScript__header {
  margin: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.AddRetailScript__header h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1;
  color: #089bab;
  margin: 0;
}

.AddRetailScript__header span {
  display: block;
  padding-top: 5px;
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 1;
  color: #454b60;
}

.AddRetailScript__colGroup {
  display: flex;
  flex-direction: column;
  padding: 5px 16px;
  /* overflow: auto; */
}

.AddRetailScript__col {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.AddRetailScript__row {
  margin-bottom: 5px;
}

.AddRetailScript__footer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  margin-top: auto;
  margin-bottom: 24px;
}
.AddRetailScript__quantityAndPriorityContainer {
  display: flex;
  align-items: center;
}

.AddRetailScript__backArrow {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.AddRetailScript__backTitle {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: rgba(18, 26, 45, 0.5);
  line-height: 1;
  margin: 0;
  padding: 0;
}

.AddRetailScript__backArrowIcon {
  padding-right: 4px;
}

.AddRetailScript__quantityInputContainer {
  margin-right: 12px;
}

.AddRetailScript__quantityInputContainer input {
  width: 100px;
  padding-right: 16px;
  padding-bottom: 7px;
}

.AddRetailScript__errorBannerContainer {
  padding: 0;
}
