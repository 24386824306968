.Report2Header__view {
  display: flex;
  width: 100%;
  justify-content: center;
}

.Report2Header__leftPane {
  display: flex;
  flex-direction: column;
  min-width: 334px;
  box-sizing: border-box;
  padding: 30px 20px;
}

.Report2Header__header {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  width: 100%;
  height: 75px;
  min-height: 75px;
  width: 100%;
  text-align: center;
}

.Report2Header__titleLeft {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
  width: 100%;
  text-align: center;
}

.Report2Header__buttonGroup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Report2Header__previewButtonContainer {
  margin-top: 24px;
}
