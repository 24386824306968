@media (min-width: 641px) {
  .EV54InventoryTable__mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .EV54InventoryTable__tableContainer {
    margin-bottom: 24px;
    width: 100%;
    min-height: 90%;
  }

  .EV54InventoryTable__buttonGroup {
    margin-top: auto;
    text-align: center;
  }

  .EV54InventoryTable__dataButton button {
    width: 140px;
    line-height: 1;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }

  .EV54InventoryTable__priceFileContainer {
    position: absolute;
    top: 34px;
    right: 0;
    display: flex;
    align-items: center;
    padding: 2px;
    cursor: pointer;
  }

  .EV54InventoryTable__priceFileContainer p {
    margin: 0;
    padding: 0 8px 0 0;
    line-height: 1;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
  }

  .EV54InventoryTable__priceFileContainer svg {
    fill: #778ca2;
    height: 24px;
    width: 24px;
  }

  .EV54InventoryTable__actionsContainer {
    display: flex;
    justify-content: center;
    flex: 1;
    text-align: right;
    position: relative;
  }

  .EV54InventoryTable__popupContainer {
    position: absolute;
    right: 68px;
    top: 20px;
  }

  .EV54InventoryTable__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
    cursor: pointer;
    line-height: 1;
    font-family: "Poppins", sans-serif;
    color: #121a2d;
  }

  .EV54InventoryTable__header h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
  }

  .EV54InventoryTable__header p {
    margin: 0;
    padding: 0;
    font-size: 16px;
  }

  .EV54InventoryTable__actionsHeadingContainer {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 640px) {
  .EV54InventoryTable__mainContainer {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 0 24px 0;
  }

  .EV54InventoryTable__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }

  .EV54InventoryTable__header p {
    padding: 0;
    margin: 0;
    font-size: 16px;
  }

  .EV54InventoryTable__backButton {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .EV54InventoryTable__backButton img {
    width: 24px;
    height: 24px;
  }

  .EV54InventoryTable__runReportButtonContainer {
    margin: 8px 0 0 0;
  }

  .EV54InventoryTable__cardListContainer {
    overflow: auto;
  }

  .EV54InventoryTable__actionsContainer {
    position: absolute;
    right: 0;
    top: 0;
  }
}
