.NDCProduct__box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
  border-radius: 6px;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (min-width: 641px) {
  .NDCProduct__box {
    width: auto;
  }
  .NDCProduct__box:hover {
    cursor: pointer;
  }
}

.NDCProduct__box:not(:last-child) {
  margin-bottom: 10px;
}

.NDCproduct__imageContainer {
  width: 57px;
  overflow: none;
  margin-left: 10px;
  margin-right: 10px;
}

.NDCproduct__imageContainer--default {
  border: 2px solid #a7bbcf;
  box-sizing: border-box;
  padding: 1.5px;
}

.NDCproduct__image {
  width: 57px;
  height: auto;
  max-height: 57px;
}

.NDCProduct__productInfo {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.NDCProduct__productDescr {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #87909e;
  line-height: 1.44;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.NDCProduct__packageId {
  color: #363f4c;
  font-size: 14px;
  letter-spacing: 0px;
  padding: 0;
  margin: 0;
  line-height: 1.44;
}
