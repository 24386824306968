.tabItem__text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  text-align: center;
}

@media (max-width: 640px) {
  .tabItem__text {
    font-weight: 300;
    font-size: 14px;
    line-height: 1;
    color: #121a2d;
    opacity: 0.5;
    padding: 0 0 10px 0;
    margin: 0;
  }
}

@media (min-width: 641px) {
  .tabItem__text {
    font-weight: 300;
    font-size: 14px;
    color: var(--black);
    opacity: 0.5;
    margin-top: 0;
    margin-block-end: 5px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .tabItem__text {
    line-height: 14px;
  }
}

@media (min-width: 1007px) {
  .tabItem__text {
    line-height: 27px;
    padding-top: 8px;
  }
}

.tabItem__text--selected {
  opacity: 1;
  /* corrects for selected border width */
  margin-bottom: 3px;
}

@media (min-width: 641px) {
  .tabItem__text--selected {
    color: var(--black);
    /* corrects for selected border width */
    margin-bottom: 3px;
  }
}

@media (max-width: 640px) {
  .tabItem__text--selected {
    /* corrects for selected border width */
    margin-bottom: -2px;
  }
}

.tabItem__container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: none;
  cursor: pointer;
  outline: none;
  padding-left: 4px;
  padding-right: 4px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}

.tabItem__container:focus {
  border-left: 3px solid var(--focusColor);
  border-right: 3px solid var(--focusColor);
}

@media (max-width: 640px) {
  .tabItem__container {
    align-items: center;
  }
}

.tabItem__tabSelected {
  /* opacity: 1; */
  border-bottom: 2px solid var(--orange);
}

.tabItem__icon {
  fill: #778ca2;
}

.tabItem__iconSelected {
  fill: #089bab;
}

.tabItem_badge {
  position: absolute;
  top: 0;
  right: -20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabItem_badge p {
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 10px;
  margin: 0;
}
