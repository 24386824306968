.SiteHeader__noDisplay {
  display: none;
}

.SiteHeader__container {
  position: relative;
  display: flex;
  flex-direction: column;
  user-select: none;
  box-sizing: border-box;
  background-color: white;
}

@media (max-width: 640px) {
  .SiteHeader__container {
    width: 100%;
    border-bottom: 1px solid #121a2d;
    padding: 9px 0 9px 0;
    margin-bottom: 10px;
  }
}

@media (min-width: 641px) {
  .SiteHeader__container {
    width: 360px;
    min-width: 360px;
    margin-right: 20px;
    border: 1px solid #e2e5ed;
    border-radius: 16px;
    color: white;
    background-color: white;
    padding: 30px 20px 20px 20px;
  }
}

@media (min-width: 1008px) {
  .SiteHeader__container {
    width: 360px;
    min-width: 360px;
  }
}

.SiteHeader__container_selected {
  background-color: #089bab;
}

.SiteHeader__container h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3e3f42;
  margin-bottom: 5px;
}

.SiteHeader__container_selected h1 {
  color: #ffffff;
}

.SiteHeader__iconContainer {
  position: absolute;
  left: 30px;
  top: -21px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c1c1c1;
  border: none;
}

.SiteHeader__iconContainer_selected {
  background-color: #089bab;
  border: 2px solid #ffffff;
}

.SiteHeader__row {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  /*padding: 10px 20px;*/
  /*justify-content: space-between;*/
  align-items: center;
}

.SiteHeader__deviceNeed_col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
  cursor: pointer;
}

.SiteHeader__replenishment_indicator {
  border: 2px solid white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
}

.SiteHeader__deviceNeed_col h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: white;
  margin: 0;
}
