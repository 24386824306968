@media (max-width: 640px) {
  .userProfile__picFrameHeaderContainer {
    position: absolute;
    top: 18px;
    right: 20px;
    z-index: 6;
  }

  .userProfile__picFrameHeader {
    width: 28px;
    height: 28px;
    border-radius: 30px;
  }
}
