/* DispensingOrdersMobile */

.noDisplay {
  display: none;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.selectPharmacy {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3e3f42;
  padding-top: 16px;
  padding-bottom: 22px;
  border-bottom: 1px solid #121a2d;
}

.view {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  padding: 20px 16px 20px 16px;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  border-bottom: 1px solid #121a2d;
}

.title {
  position: absolute;
  top: 25px;
  left: 52px;
  font-size: 14px;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #121a2d;
  margin: 0;
  cursor: pointer;
}

.title span {
  font-size: 14px;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #121a2d;
  margin: 0;
  cursor: pointer;
}

.sites {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
  /* Hide scrollbar for IE, Edge add Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  padding-top: 12px;
}
.sites::-webkit-scrollbar {
  display: none;
}

.siteDispensingOrderDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding-bottom: 5px;
}

.dispensingOrderQueue {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  user-select: none;
}

.processModeHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.errorBannerContainer {
  margin: 10px 10px 0 10px;
}

.rotate180Degrees {
  /* Needed for Safari */
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.backArrowMobile {
  cursor: pointer;
  padding: 0mobile_5rem;
}

.packagingMethodWidgetContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .diaglog-content {
} */

/* .dialogText {
} */
