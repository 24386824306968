.Table__container {
  width: 100%;
  overflow-y: auto;
  height: 100%;
}

.Table__container--rightPadding {
  padding-right: 10px;
}

.Table__container table {
  width: 100%;
  border-collapse: collapse;
}

.Table__container th:after,
.Table__container th:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  /* border-bottom: 1px solid #089bab;
  border-top: 1px solid #089bab; */
}

.Table__container th:before {
  top: -1px;
  /*border-bottom: 1px solid #089BAB;*/
}

.Table__container th:after {
  bottom: -1px;
  /*border-bottom: 1px solid #089BAB;*/
}

.Table__container thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #121a2d;
  background-color: #fff;
  /* border-left: 1px solid #089bab;
  border-right: 1px solid #089bab; */
  cursor: pointer;
}

@media (max-width: 1007px) {
  .Table__container thead th {
    font-size: 13px;
    padding: 6px 6px;
  }
}

@media (min-width: 1008px) {
  .Table__container thead th {
    font-size: 14px;
    padding: 10px 10px;
  }
}

.Table__container tbody td {
  text-align: left;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #121a2d;
}

@media (max-width: 1007px) {
  .Table__container tbody td {
    font-size: 13px;
    padding: 6px 6px;
  }
}

@media (min-width: 1008px) {
  .Table__container tbody td {
    font-size: 14px;
    padding: 10px 10px;
  }
}

.Table__unSelectedRow:nth-child(even) {
  background: rgba(123, 129, 139, 0.2);
}
.Table__unSelectedRow:nth-child(odd) {
  background: #fff;
}

.Table__row:hover {
  cursor: cell;
}

.Table__selectedRow {
  background-color: rgb(255, 171, 43);
}

.Table__container .Table__table th:after {
  border-top: 1px solid #089bab;
}

.Table__container .Table__table th:before {
  border-bottom: 1px solid #089bab;
}

.Table__dataCell {
  border-right: 1px solid #089bab;
}

.Table__table {
  border: 1px solid #089bab;
}

.Table__table th {
  border-right: 1px solid #089bab;
}

.Table__table th {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #121a2d;
  padding-bottom: 5px;
}

.Table__table td {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
}

.Table__row {
  border-top: 1px solid #089bab;
  border-bottom: 1px solid #089bab;
}
