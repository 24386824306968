.appContainer {
  margin: 39px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.buttonGroup {
  display: flex;
  margin-top: 20px;
}

.buttonGroup__button {
  padding: 10px;
}

.buttonGroup__button--1 {
  margin-right: 0;
}

.buttonGroup__button--2 {
  margin-left: 0;
}

.buttonPadRight {
  padding-right: 10px;
}

.buttonPadLeft {
  padding-left: 10px;
}

.photoArea {
  display: block;
}

.photoArea--hide {
  display: none;
}

.photoArea__image {
  border-radius: 50%;
}

.photoArea__heading {
  margin: 0 0 12px 0;
  padding: 0;
  font-size: 16px;
}

.showCameraStreaming {
  display: block;
}

.hideCameraStreaming {
  display: none;
}

.header {
  margin-left: 18px;
  margin-top: 19px;
}

.backArrow {
  display: flex;
  align-items: center;
}

.backArrow__icon {
  padding-right: 4px;
}

.goBackTitle {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: rgba(18, 26, 45, 0.5);
  line-height: 1;
  margin: 0;
  padding: 0;
}

.title {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #121a2d;
  line-height: 1;
  margin: 24px 0 0 2px;
  padding: 0;
}
