.PackagingMethod__noDisplay {
  display: none;
}

.PackagingMethod__row {
  display: flex;
  box-sizing: border-box;
  user-select: none;
}

@media (max-width: 640px) {
  .PackagingMethod__row {
    box-sizing: border-box;
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 6px;
    gap: 6px;
  }
}

@media (min-width: 641px) {
  .PackagingMethod__row {
    width: 100%;
    margin-left: 20px;
  }
}

.PackagingMethod__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: #ffffff;
  user-select: none;
}

@media (max-width: 640px) {
  .PackagingMethod__container {
    padding-left: 12px;
    padding-right: 12px;
  }

  /* .PackagingMethod__container:nth-child(1) {
    border: 1px solid #121a2d;
    flex-basis: 33%;
    margin-right: 4px;
  }
  .PackagingMethod__container:nth-child(2) {
    border-top: 1px solid #121a2d;
    border-bottom: 1px solid #121a2d;
    flex-basis: 33%;
    margin-right: 4px;
  }
  .PackagingMethod__container:nth-child(3) {
    border: 1px solid #121a2d;
    flex-basis: 33%;
  } */
}

@media (min-width: 641px) {
  .PackagingMethod__container {
    cursor: pointer;
    justify-content: center;
    border: 1px solid #e2e5ed;
    border-radius: 6px;
    width: 100px;
    margin-right: 10px;
    padding: 0 10px;
  }
}

.PackagingMethod__container_selected {
  background-color: #089bab;
}

.PackagingMethod__container h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
  line-height: 24px;
  color: #3e3f42;
  text-align: center;
  margin: 0;
  user-select: none;
}

.PackagingMethod__container_selected h1 {
  color: #ffffff;
}
