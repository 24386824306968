.prodList__container {
  color: #121a2d;
  font-family: Poppins;
  font-weight: 300;
  font-size: 14px;
  margin-top: 21px;
}

.prodList__container--red {
  color: red;
}

.prodList__listItem {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 0;
  margin-bottom: 10px;
  border: 1px solid #e8ecef;
  box-sizing: border-box;
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  color: #121a2d;
  padding: 0;
  list-style: none;
}

@media (max-width: 640px) {
  .prodList__listItem {
    font-size: 14px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .prodList__listItem {
    height: 48px;
    min-height: 48px;
    font-size: 13px;
  }
}

@media (min-width: 1008px) {
  .prodList__listItem {
    min-height: 80px;
    height: 80px;
    font-size: 14px;
  }
}

.prodList__id {
  margin-left: 20px;
  margin-right: 68px;
  width: 121px;
  overflow-wrap: anywhere;
}

.prodList__description {
  width: 256px;
  margin-right: 100px;
  overflow-wrap: anywhere;
}

.prodList__manufacturer {
  width: 141px;
  margin-right: 76px;
  overflow-wrap: anywhere;
}

.prodList__AddEquivIcon {
  height: auto;
  fill: #e5eaed80;
}

@media (min-width: 641px) {
  .prodList__AddEquivIcon {
    width: 40px;
  }
  .prodList__AddEquivIcon:hover {
    cursor: pointer;
  }
}

@media (max-width: 640px) {
  .prodList__AddEquivIcon {
    width: 30px;
  }
}

@media (max-width: 640px) {
  .prodList__scrollArea {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
  }
  .prodList__scrollArea::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .prodList__scrollArea::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 641px) {
  .prodList__scrollArea {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    padding-right: 10px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .prodList__scrollArea {
    max-height: 500px;
  }
}

@media (min-width: 1008px) {
  .prodList__scrollArea {
    max-height: 600px;
    margin-left: 66px;
    margin-right: 66px;
  }
}
