.locationCard__nameMinMaxNeedGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 27px 20px 27px 16px;
}

@media only screen and (min-width: 641px) {
  .locationCard__nameMinMaxNeedGroup {
    padding: 16px 20px 16px 16px;
  }
}

.locationCard__nameMinMaxNeedGroup:not(:last-child) {
  margin-bottom: 10px;
}

/* .locationCard__NameMinMaxGroup:not(:last-child) {
    margin-top: 10px;
  } */

.locationCard__nameMinMaxGroup {
  display: flex;
  flex-direction: column;
}

.locationCard__name {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

@media only screen and (min-width: 641px) {
  .locationCard__name {
    margin-bottom: 3px;
  }
}

.locationCard__minAndMax {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  padding-top: 3px;
}

.locationCard__max {
  padding-left: 22px;
}

.locationCard__needGroup {
  font-family: "Poppins", sans-serif;
  color: #089bab;
  /* min-width: 102px; */
}

.locationCard__need {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.locationCard__numBottles {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
}
