.scrollArea {
  overflow-x: hidden;
  overflow-y: auto;
  height: 350px;
  scroll-padding: 0 0 0 3px;
}

@media only screen and (max-width: 640px) {
  .scrollArea {
    padding: 4px 3px 3px 3px;
    height: 0;
    flex: 1 0 auto;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }

  .scrollArea::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width: 641px) {
  .scrollArea {
    height: 260px;
    padding: 4px 10px 3px 3px;
  }
}

@media only screen and (max-width: 640px) {
  .NDCProductList {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
}

@media only screen and (min-width: 641px) {
  .NDCProductList {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .NDCProductList__body {
    height: 0;
    flex: 1 0 auto;
  }
}

@media only screen and (min-width: 641px) {
  .NDCProductList__body {
    margin-top: 16px;
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 43px;
  }
}

@media only screen and (min-width: 641px) {
  .NDCProductList__clickMessage {
    margin-top: 28px;
    margin-left: 60px;
    margin-bottom: 16px;
  }
}

.NDCProductList__clickMessage {
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  color: #093a64;
  font-weight: 500;
  font-size: 16px;
}
