@media (min-width: 641px) {
  .OptiSortDailyReport__mainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .OptiSortDailyReport__tableContainer {
    margin-bottom: 24px;
    /* max-width: 1600px; */
  }

  .OptiSortDailyReport__buttonGroup {
    margin-top: auto;
    text-align: center;
  }

  .OptiSortDailyReport__dataButton button {
    width: 140px;
    line-height: 1;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }

  .OptiSortDailyReport__priceFileContainer {
    position: absolute;
    top: 34px;
    right: 0;
    display: flex;
    align-items: center;
    padding: 2px;
    cursor: pointer;
  }

  .OptiSortDailyReport__priceFileContainer p {
    margin: 0;
    padding: 0 8px 0 0;
    line-height: 1;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
  }

  .OptiSortDailyReport__priceFileContainer svg {
    fill: #778ca2;
    height: 24px;
    width: 24px;
  }

  .OptiSortDailyReport__actionsContainer {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    text-align: right;
    position: relative;
    margin-right: 30px;
  }

  .OptiSortDailyReport__popupContainer {
    position: absolute;
    right: 68px;
    top: 20px;
  }

  .OptiSortDailyReport__reportUtility {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .OptiSortDailyReport__reportUtilityCol1 {
    display: flex;
    align-items: center;
  }

  .OptiSortDailyReport__reportName {
    font-family: "Poppins", sans-serif;
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 1;
    color: #454b60;
  }

  .OptiSortDailyReport__backButton {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }

  .OptiSortDailyReport__overlay {
    position: fixed;
    z-index: 99;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .OptiSortDailyReport__modal {
    position: absolute;
    z-index: 110;
    left: 50%;
    transform: translate(-50%, 0%);
    box-sizing: border-box;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }

  .OptiSortDailyReport__dateRangeGroup {
    display: flex;
    flex-direction: column;
  }

  .OptiSortDailyReport__dateRangeLabel {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #089bab;
  }

  .OptiSortDailyReport__dateRange {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #121a2d;
    cursor: pointer;
  }

  .OptiSortDailyReport__buttonGroup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 304px;
    min-width: 304px;
  }
  .OptiSortDailyReport__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #121a2d;
  }

  .OptiSortDailyReport__loader svg {
    margin-top: 40px;
  }

  .OptiSortDailyReport__fileIconContainer {
    margin-top: 2px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 0;
  }

  .OptiSortDailyReport__fileIcon {
    width: 32px;
    height: 32px;
    color: #089bab;
  }
}

@media (max-width: 640px) {
  .OptiSortDailyReport__mainContainer {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    margin-top: -24px;
  }

  .OptiSortDailyReport__overlay {
    position: absolute;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    margin-top: -95px;
    padding: 75px;
    box-sizing: border-box;
  }

  .OptiSortDailyReport__modal {
    box-sizing: border-box;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }

  .OptiSortDailyReport__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: inherit;
    height: 75px;
    min-height: 75px;
  }

  .OptiSortDailyReport__titleGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 304px;
    min-width: 304px;
    margin-top: 24px;
    margin-bottom: 6px;
  }

  .OptiSortDailyReport__backButton {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .OptiSortDailyReport__backButton img {
    width: 26px;
    height: 26px;
  }

  .OptiSortDailyReport__reportTitle {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
    color: #454b60;
  }

  .OptiSortDailyReport__dateRangeGroup {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .OptiSortDailyReport__dateRangeLabel {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #089bab;
  }

  .OptiSortDailyReport__dateRange {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    color: #121a2d;
    cursor: pointer;
  }

  .OptiSortDailyReport__buttonGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
    margin-bottom: 24px;
  }

  .OptiSortDailyReport__runReportButtonContainer {
    margin: 8px 0 0 0;
  }

  .OptiSortDailyReport__modal {
    padding: 0 0 20px 0;
  }

  .OptiSortDailyReport__modal > div > div:first-child {
    display: none;
  }

  .OptiSortDailyReport__modal > div > div:nth-child(2) > div:nth-child(1) {
    max-width: 305px;
  }

  .OptiSortDailyReport__modal > div > div:nth-child(2) > div:nth-child(2) {
    max-width: 305px;
  }

  .OptiSortDailyReport__modal > div > div:nth-child(2) > div:nth-child(3) {
    max-width: 305px;
  }

  .OptiSortDailyReport__modal
    > div
    > div:nth-child(2)
    > div:nth-child(3)
    > div:nth-child(1) {
    max-width: 283px;
  }

  .rdrMonths {
    border: 1px solid blue;
  }

  .OptiSortDailyReport__cardListContainer {
    overflow: auto;
    height: 100%;
  }

  .OptiSortDailyReport__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 288px;
    top: 80px;
    left: 0;
    right: 0;
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 1;
    color: #121a2d;
  }

  .OptiSortDailyReport__loader svg {
    margin-top: 40px;
  }
}
