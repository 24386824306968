/* DispensingOrdersDesktop */

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.view {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  color: #121a2d;
  margin: 0;
  cursor: pointer;
}

.sites {
  display: flex;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding-bottom: 10px;
  overflow: -moz-scrollbars-horizontal; /* horizontal scroll */
}

.siteDispensingOrderDetails {
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 5px;
}

.errorBannerContainer {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0;
}

.bottomSpacer {
  margin-bottom: auto;
  height: 50px;
  background-color: transparent;
}

/* .dialog-content {
} */

/* .dialogText {

} */

/* DispensingOrders2 */
