.scannedProductDisplay {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

@media only screen and (min-width: 641px) {
  .scannedProductDisplay {
    margin-top: 0;
  }
}

.scannedProductDisplay__descriptionGroup {
  display: flex;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 640px) {
  .scannedProductDisplay__descriptionGroup {
    align-items: center;
  }
}

@media only screen and (min-width: 641px) {
  .scannedProductDisplay__descriptionGroup {
    align-items: flex-start;
    margin-bottom: 40px;
  }
}

.scannedProductDisplay__imageContainer {
  width: 57px;
  overflow: none;
}

@media only screen and (min-width: 641px) {
  .scannedProductDisplay__imageContainer {
    margin-top: 40px;
    margin-left: 46px;
  }
}

@media only screen and (min-width: 641px) {
  .scannedProductDisplay__imageContainer {
    margin-right: 13px;
  }
}

.scannedProductDisplay__imageContainer--default {
  border: 2px solid #a7bbcf;
  box-sizing: border-box;
  padding: 1.5px;
}

.scannedProductDisplay__image {
  width: 57px;
  height: auto;
  max-height: 57px;
}

.scannedProductDisplay__productInfoGroup {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.scannedProductDisplay__packageDescriptionContainer {
  font-family: "Poppins", sans-serif;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 640px) {
  .scannedProductDisplay__packageDescriptionContainer {
    padding-left: 5px;
    margin-top: 0;
  }
}

.scannedProductDisplay__descriptionContainer {
  padding-left: 5px;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 641px) {
  .scannedProductDisplay__descriptionContainer {
    margin-top: 40px;
  }
}

.scannedProductDisplay__description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  color: #363f4c;
}

@media only screen and (max-width: 640px) {
  .scannedProductDisplay__description {
    width: 95%;
  }
}

@media only screen and (min-width: 641px) {
  .scannedProductDisplay__description {
    margin-bottom: 3px;
    line-height: 24px;
  }
}

.scannedProductDisplay__packageDescription {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0px;
  color: #87909e;
}

@media only screen and (max-width: 640px) {
  .scannedProductDisplay__packageDescription {
    width: 95%;
  }
}
