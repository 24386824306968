.Alert__modal {
  background: #ffffff;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #eaeaea;
  box-shadow: 0 5px 14px rgba(120, 146, 141, 0.05);
}

@media (max-width: 640px) {
  .Alert__modal {
    margin-left: 16px;
    margin-right: 16px;
    max-width: 560px;
  }
}

@media (min-width: 641px) {
  .Alert__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .Alert__modal {
    width: 560px;
  }
}

@media (min-width: 1008px) {
  .Alert__modal {
    max-width: 40%;
  }
}

.Alert__modal h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #121a2d;
}

.Alert__modal p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #121a2d;
}

.Alert__modal button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  background: #e7e7e8;
  border-radius: 10px;
  color: #121a2d;
  outline: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 640px) {
  .Alert__modal button {
    width: 122px;
  }
}

@media (min-width: 641px) {
  .Alert__modal button {
    padding: 15px 60px;
    line-height: 21px;
  }
}

.Alert__modal button.Alert__btn__primary {
  background: #089bab;
  color: white;
}

@media (max-width: 640px) {
  .Alert__modal button.Alert__btn__primary {
    margin-left: 20px;
  }
}

@media (min-width: 641px) {
  .Alert__modal button.Alert__btn__primary {
    margin-left: 20px;
  }
}

@media (max-width: 640px) {
  .Alert__btn__alert {
    width: 122px;
  }
}

@media (min-width: 641px) {
  .Alert__btn__alert {
    width: 235px;
  }
}

@media (max-width: 640px) {
  .Alert__btn__cancel {
    width: 122px;
  }
}

@media (min-width: 641px) {
  .Alert__btn__cancel {
    width: 135px;
  }
}

.Alert__buttonsContainer {
  display: flex;
}

@media (max-width: 640px) {
  .Alert__buttonsContainer {
    min-height: 48px;
    justify-content: center;
  }
}

@media (min-width: 641px) {
  .Alert__buttonsContainer {
    min-height: 48px;
  }
}

.Alert__heading {
  text-align: center;
}
