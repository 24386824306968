.ReportViewer__main {
  display: flex;
  width: 100%;
  height: 96vh;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 20px;
}

@media (max-width: 640px) {
  .ReportViewer__main {
    padding: 0 0 24px 0;
  }
}

.ReportViewer__mainWithCustomPDF {
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
}

.ReportViewer__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 87vw;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #a7bbcf;
}

@media (max-width: 640px) {
  .ReportViewer__header {
    flex-direction: column;
    align-items: center;
    width: inherit;
    border: none;
  }
}

.ReportViewer__row {
  display: flex;
  width: 100%;
}

.ReportViewer__reportTitle {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
}

@media (max-width: 640px) {
  .ReportViewer__reportTitle {
    font-size: 16px;
    line-height: 1;
  }
}

@media (min-width: 641px) {
  .ReportViewer__reportTitle {
    white-space: nowrap;
  }
}

.ReportViewer__titleGroup {
  display: flex;
  align-items: center;
  width: 304px;
  min-width: 304px;
}

@media (max-width: 640px) {
  .ReportViewer__titleGroup {
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 6px;
  }
}

.ReportViewer__buttonGroup {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 640px) {
  .ReportViewer__buttonGroup {
    justify-content: center;
    margin-top: 18px;
    margin-bottom: 24px;
  }
}

.ReportViewer__backButton {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

@media (max-width: 640px) {
  .ReportViewer__backButton {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .ReportViewer__backButton img {
    width: 26px;
    height: 26px;
  }
}

.ReportViewer__dateRangeGroup {
  display: flex;
  flex-direction: column;
}

@media ((min-width: 1151px) and (max-width: 1567px)) {
  .ReportViewer__dateRangeGroup {
    position: absolute;
    left: 770px;
  }
}

@media (max-width: 640px) {
  .ReportViewer__dateRangeGroup {
    align-items: center;
  }
}

.ReportViewer__dateRangeLabel {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #089bab;
}

.ReportViewer__dateRange {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #121a2d;
  cursor: pointer;
}

@media (max-width: 640px) {
  .ReportViewer__dateRange {
    font-size: 14px;
    line-height: 1;
  }
}

.ReportViewer__overlay {
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 75px;
  box-sizing: border-box;
}

.ReportViewer__reportHeader {
  display: flex;
  align-items: center;
  height: 50px;
  min-height: 50px;
  border-bottom: 1px solid #089bab;
  box-sizing: border-box;
  padding: 0 20px;
}

.ReportViewer__reportHeader--grouping {
  padding: 0 30px;
}

.ReportViewer__reportFooter {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  min-height: 60px;
  border-bottom: 1px solid #089bab;
  border-top: 1px solid #089bab;
  box-sizing: border-box;
  padding: 10px 20px 10px 20px;
  margin-top: 20px;
  margin-bottom: auto;
}

.ReportViewer__reportHeaderGroup {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.ReportViewer__reportHeaderText {
  margin: 0 8px 0 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #121a2d;
  cursor: pointer;
}

.ReportViewer__reportDataScrollView {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.ReportViewer__reportDataContainer1 {
  display: flex;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e8ecef;
  box-shadow: 0 5px 14px rgba(120, 146, 141, 0.05);
  border-radius: 10px;
  margin-top: 10px;
  box-sizing: border-box;
}

.ReportViewer__reportDataContainer2 {
  display: flex;
  padding-left: 20px;
  width: 100%;
  background: #ffffff;
  padding: 0 20px;
  min-height: 80px;
}

.ReportViewer__reportDataContainerGrouping {
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 5px 14px rgba(120, 146, 141, 0.05);
  border-radius: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 20px;
}

.ReportViewer__reportData {
  display: flex;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e8ecef;
  box-shadow: 0 5px 14px rgba(120, 146, 141, 0.05);
  border-radius: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 20px;
}

.ReportViewer__groupIcon {
  padding: 6px;
  cursor: pointer;
}

.ReportViewer__groupIcon svg {
  display: flex;
  width: 16px;
  fill: rgb(119, 140, 162);
}

.ReportViewer__groupIcon--active svg {
  fill: var(--green);
}

.ReportViewer__expandOrCollapseIconContainer {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  left: -20px;
  top: 0;
}

.ReportViewer__expandOrCollapseIconContainer svg {
  width: 14px;
  height: 14px;
}

.ReportViewer__resetButtons {
  border: none;
  padding: 6px 8px;
  border-radius: 4px;
  margin-left: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: var(--black);
  background-color: var(--blackExtraLight);
  white-space: nowrap;
  cursor: pointer;
}

.ReportViewer__dataRow {
  position: relative;
  display: flex;
  padding-top: 14px;
  padding-bottom: 14px;
}

.ReportViewer__dataRow--grouping {
  margin-left: 10px;
}

.ReportViewer__reportDataText {
  display: flex;
  align-items: center;
  height: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #121a2d;
  margin-right: 10px;
  word-break: break-word;
}

.ReportViewer__modal {
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.ReportViewer__tooltip {
  position: relative;
  display: inline-block;
}

.ReportViewer__tooltip .ReportViewer__tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: var(--black);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -110px;
}

.ReportViewer__tooltip .ReportViewer__tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--black) transparent transparent transparent;
}

.ReportViewer__tooltip:hover .ReportViewer__tooltiptext {
  visibility: visible;
}

.ReportViewer__tooltipSpan {
  display: block;
}

.ReportViewer__tooltipSpan:first-child {
  padding-top: 8px;
  padding-bottom: 2px;
}

.ReportViewer__tooltipSpan:last-child {
  padding-top: 2px;
  padding-bottom: 8px;
}

.ReportViewer__columnSumGroup {
  display: flex;
  flex-direction: column;
}

.ReportViewer__columnSumGroupHeading {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-left: -15px;
  margin-right: 4px;
  padding-right: 6px;
  min-width: 40px;
  display: inline-block;
}

.ReportViewer__columnSumGroupData {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-left: -10px;
}

.ReportViewer__fileIconContainer {
  margin-top: 2px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 0;
}

.ReportViewer__fileIcon {
  width: 32px;
  height: 32px;
  color: #089bab;
}

.ReportViewer__runReportButtonContainer {
  margin-left: 8px;
}

@media (max-width: 640px) {
  .ReportViewer__runReportButtonContainer {
    margin: 8px 0 0 0;
  }
}

.ReportViewer__reportPreviewTitle {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  color: var(--green);
}

.ReportViewer__tableContainer {
  position: relative;
  width: 87vw;
  overflow-x: auto;
  overflow-y: hidden;
}

@media (max-width: 640px) {
  .ReportViewer__modal {
    padding: 0 0 20px 0;
  }

  .ReportViewer__modal > div > div:first-child {
    display: none;
  }

  .ReportViewer__modal > div > div:nth-child(2) > div:nth-child(1) {
    max-width: 305px;
  }

  .ReportViewer__modal > div > div:nth-child(2) > div:nth-child(2) {
    max-width: 305px;
  }

  .ReportViewer__modal > div > div:nth-child(2) > div:nth-child(3) {
    max-width: 305px;
  }

  .ReportViewer__modal
    > div
    > div:nth-child(2)
    > div:nth-child(3)
    > div:nth-child(1) {
    max-width: 283px;
  }

  .rdrMonths {
    border: 1px solid blue;
  }
}
