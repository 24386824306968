.stockFooter {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  height: 82px;
  min-height: 82px;
  margin-top: auto;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  position: relative;
  z-index: 30;
  background-color: #fff;
  filter: brightness(98%);
}

@media only screen and (min-width: 641px) {
  .imageContainer {
    margin-right: 13px;
  }
}
