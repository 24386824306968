.transferSelection__group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .transferSelection__group {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media only screen and (min-width: 641px) {
  .transferSelection__group {
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 59px;
  }
}

.transferSelection__item {
  display: flex;
  align-items: center;
  height: 80px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

@media only screen and (max-width: 640px) {
  .transferSelection__item {
    width: 100%;
  }
}

@media only screen and (min-width: 641px) {
  .transferSelection__item {
    width: 280px;
    transition: all ease-in-out 0.3s;
  }
  .transferSelection__item:hover {
    cursor: pointer;
  }
}

.transferSelection__item:first-child {
  margin-top: 40px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 641px) {
  .transferSelection__item:first-child {
    margin: 0;
    margin-right: 20px;
  }
}

@media only screen and (min-width: 641px) {
  .transferSelection__item:last-child {
    margin: 0;
    margin-left: 20px;
  }
}

.transferSelection__title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #11243d;
}

.transferSelection__icon {
  width: 48px;
  height: 48px;
  margin-left: 14px;
  margin-right: 18px;
}
