.RectangleProperties__container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 6px;
}

.RectangleProperties__positionContainer,
.RectangleProperties__topAndRightPaddingContainer,
.RectangleProperties__bottomAndLeftPaddingContainer,
.RectangleProperties__sizeContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

/* general boxes container */
.RectangleProperties__boxesContainer {
  display: flex;
  align-items: center;
}

.RectangleProperties__xBoxContainer,
.RectangleProperties__topPaddingBoxContainer,
.RectangleProperties__bottomPaddingBoxContainer,
.RectangleProperties__widthBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 24px;
  margin-right: 12px;
}

.RectangleProperties__yBoxContainer,
.RectangleProperties__rightPaddingBoxContainer,
.RectangleProperties__leftPaddingBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RectangleProperties__heightBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RectangleProperties__borderContainer {
  display: flex;
  flex-direction: column;
}

.RectangleProperties__borderBoxesContainer {
  display: flex;
  margin-top: 6px;
}

.RectangleProperties__borderOffBox {
  width: 22px;
  height: 22px;
  border: 1px solid var(--black);
  /* padding-bottom: 2px; */
  margin-right: 4px;
  box-sizing: border-box;
}

.RectangleProperties__borderOffBox svg {
  width: 100%;
  height: 100%;
  fill: var(--black);
  padding-bottom: 2px;
}

.RectangleProperties__borderOnBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border: 1px solid var(--black);
  box-sizing: border-box;
}

.RectangleProperties__borderOnBox svg {
  width: 80%;
  height: 80%;
  fill: var(--black);
}

.RectangleProperties__borderColorContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.RectangleProperties__container p {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  padding: 0;
  margin: 0;
  line-height: 1;
  color: var(--black);
}

.RectangleProperties__container p span {
  display: block;
  font-size: 12px;
  margin-top: 3px;
}

.RectangleProperties__topAndRightPaddingContainer p,
.RectangleProperties__bottomAndLeftPaddingContainer p,
.RectangleProperties__positionContainer p,
.RectangleProperties__sizeContainer p {
  margin-top: 8px;
}

.RectangleProperties__inputContainer {
  width: 54px;
}

.RectangleProperties__inputContainer input {
  text-align: right;
  padding-right: 5px;
}

.RectangleProperties__trashIconContainer {
  position: absolute;
  right: 8px;
  top: -43px;
  cursor: pointer;
}

.RectangleProperties__trashIconContainer svg {
  fill: #d9534f;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in-out;
}

.RectangleProperties__trashIconContainer:hover svg {
  transform: scale(1.1);
}

.RectangleProperties__sizeError {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  line-height: 1;
  color: #d9534f;
  padding: 0 0 0 0;
  margin: -8px 0 0 0;
  text-align: right;
}
