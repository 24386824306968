.topicCard__topicCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--green);
  border: 1px solid #078a98;
  border-radius: 5px;
  cursor: pointer;
}

@media (min-width: 641px) {
  .topicCard__topicCard {
    width: 340px;
    height: 209px;
  }
}

@media (min-width: 1008px) {
  .topicCard__topicCard {
    width: 420px;
    height: 258px;
  }
}

@media (max-width: 640px) {
  .topicCard__topicCard {
    height: 258px;
    width: 100%;
  }
}

.topicCard__topicCard h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-align: center;
  padding: 0;
  margin: 0;
}

@media (min-width: 641px) {
  .topicCard__topicCard h2 {
    font-size: 18px;
  }
}

@media (min-width: 1008px) {
  .topicCard__topicCard h2 {
    font-size: 20px;
  }
}

.topicCard__iconContainer {
  width: 120px;
  margin-bottom: 14px;
}

@media (min-width: 641px) {
  .topicCard__iconContainer {
    width: 97px;
    margin-bottom: 11px;
  }
}

@media (min-width: 1008px) {
  .topicCard__iconContainer {
    width: 120px;
    margin-bottom: 14px;
  }
}
