.EV54DeviceCleaning_column {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*box-sizing: border-box;*/
    /*padding: 20px;*/
}

.EV54DeviceCleaning_row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.EV54DeviceCleaning_header {
    justify-content: space-between;
}

.EV54DeviceCleaning_header h1 {
    font-weight: 600;
    font-size: 25px;
    line-height: 49px;
    color: #089BAB;
    margin: 0;
}

.EV54DeviceCleaning_icon {
    color: #121a2d;
    opacity: 0.5;
}

.EV54DeviceCleaning_columnCard {
    background-color: #089bab;
    color: white;
    box-sizing: border-box;
    padding: 5px 10px;
    cursor: pointer;
    min-width: 150px;
}

.EV54DeviceCleaning_columnCard p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}

.EV54DeviceCleaning_canisterCard {
    background-color: #089bab;
    box-sizing: border-box;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    min-width: 150px;
}

.EV54DeviceCleaning_canisterCard p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    margin: 0;
}

.EV54DeviceCleaning_canisterButton {
    background-color: #E7E7E8;
    box-sizing: border-box;
    padding: 5px 10px;
    cursor: pointer;
    color: white;
}

.EV54DeviceCleaning_canisterButton p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #121A2D;
    margin: 0;
}
.EV54DeviceCleaning__table {
    border-collapse: collapse;
}

.EV54DeviceCleaning__table th {
    text-align: left;
    font-size: 15px;
    padding: 10px 5px;
    border-bottom: 1px solid #089bab;
    border-top: 1px solid #089bab;
}

td button {
    padding: 5px 10px;
}

.EV54DeviceCleaning__table tbody {
    border-bottom: 1px solid #089bab;
    border-left: 1px solid #089bab;
}

.EV54DeviceCleaning__table tbody tr {
    border-bottom: 1px solid #089bab;
}
.EV54DeviceCleaning__table tbody td {
    box-sizing: border-box;
    padding: 10px 5px;
    text-align: left;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #121a2d;
    border-right: 1px solid #089bab;
}

.EV54DeviceCleaning__table thead th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 2;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #121a2d;
    background-color: #fff;
    border-left: 1px solid #089bab;
    border-right: 1px solid #089bab;
    cursor: pointer;
}

.EV54DeviceCleaning_overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
}

td span {
    box-sizing: border-box;
    padding: 5px;
}
