.PrintLabels__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.PrintLabels__container main {
  width: 334px;
  margin-top: 32px;
}

.PrintLabels__container main h1 {
  margin: 0 0 50px 0;
  font-family: "Poppins", sans-serif;
  color: #454b60;
  line-height: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  width: 100%;
  text-align: center;
}

.PrintLabels__buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.PrintLabels__dropdowns {
  display: flex;
  flex-direction: column;
}

.PrintLabels__dropdown {
  margin-bottom: 24px;
}

.PrintLabels__checkboxes {
  display: flex;
  flex-direction: column;
  position: relative;
}

.PrintLabels__checkbox {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
}

.PrintLabels__checkbox label {
  white-space: nowrap;
}
.PrintLabels__checkboxes h2,
.PrintLabels__inputFieldGroup h2 {
  font-family: "Poppins", sans-serif;
  color: var(--green);
  line-height: 1;
  font-size: 12px;
  padding-bottom: 10px;
  margin: 0;
}

.PrintLabels__checkboxes--printLabelRows h2 {
  margin-top: 12px;
}

.PrintLabels__printOptionOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(152, 169, 188, 0.05);
  /* backdrop-filter: blur(46.2108px); */
  z-index: 210;
}

.PrintLabels__inputFieldGroup h2 {
  padding: 0;
}

.PrintLabels__devicePositionInputs {
  display: flex;
  flex-direction: column;
}

.PrintLabels__inputFieldGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 228px; */
}

.PrintLabels__inputFieldGroup:first-child {
  margin-left: 8px;
  margin-right: 6px;
}

.PrintLabels__inputFieldGroup:nth-child(3) {
  margin-left: 3px;
}

.PrintLabels__rangeDevicePositionInputsGroup {
  display: flex;
  align-items: center;
}

.PrintLabels__error {
  font-family: "Poppins", sans-serif;
  line-height: 1.2;
  font-size: 12px;
  color: #d9534f;
  padding: 0;
  margin: 2px 0 16px 0;
}

.PrintLabels__labelNameContainer {
  margin-bottom: 20px;
}

.PrintLabels__labelNameContainer p {
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}

.PrintLabels__labelNameContainer p:nth-child(1) {
  font-size: 12px;
  color: var(--green);
  padding: 0;
  margin: 0 0 10px 0;
}

.PrintLabels__labelNameContainer p:nth-child(2) {
  font-size: 18px;
  color: var(--black);
  padding: 0;
  margin: 0;
}
