.container {
  background-color: #fff;
}

@media only screen and (max-width: 640px) {
  .container {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }
  .errorBannerContainer {
    margin: 10px;
    position: relative;
    z-index: 210;
  }
}

@media only screen and (min-width: 641px) {
  .container {
    position: relative;
    border-radius: 20px;
    width: 720px;
  }

  .container--showBody {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fafafa;
  }

  .errorBannerContainer {
    position: absolute;
    top: -88px;
    width: 100%;
  }
}

.buttonHoverCursor:hover button {
  cursor: pointer;
}

.buttonNoHoverCursor:hover button {
  cursor: auto;
}
