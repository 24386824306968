.cardFlexCol {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 641px) {
  .cardFlexCol {
    background-color: #fff;
    border-radius: 20px;
  }
}
