.inventoryList__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

@media (min-width: 640px) and (max-width: 1007px) {
  .inventoryList__container {
    height: 100%;
    /* max-height: 500px; */
  }
}

@media (min-width: 1008px) {
  .inventoryList__container {
    height: 100%;
  }
}
