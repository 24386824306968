.formContainer {
  margin-top: auto;
  margin-bottom: 16px;
  padding: 30px 30px;
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
  border-radius: 6px;
  background-color: #fff;
}

.form {
  display: flex;
  flex-direction: column;
}

.title {
  padding: 0 0 6px 0;
  margin: 4px 0 0 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.7px;
  color: #089bab;
  text-align: center;
}

.logInButton {
  font-family: Poppins, "Sans-serif";
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 34px;
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 4px;
  border: none;
  border-radius: 6px;
  color: #fff;
  background-color: #089bab;
  outline: none;
}

@media only screen and (min-width: 641px) {
  .logInButton {
    width: 213px;
    height: 48px;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 16px;
  }
}
