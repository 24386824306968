.button {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 34px;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 14px;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  background: #089bab;
}

.button:first-child {
  margin-bottom: 16px;
}

@media only screen and (min-width: 641px) {
  .button {
    width: 213px;
    height: 48px;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    transition: all ease-in-out 0.3s;
  }
  .button:first-child {
    margin-right: 20px;
  }
  .button:last-child {
    margin-left: 20px;
  }
  .button:hover {
    cursor: pointer;
  }
}

.button--inverse {
  background: #f2f2f2;
  color: #089bab;
}
