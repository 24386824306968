.TrayItem {
  width: 80%;
  display: flex;
  cursor: pointer;
  border: 1px solid black;
}

.CorrectedTrayItem {
  width: 80%;
  display: flex;
  cursor: pointer;
  border: 5px solid #FDCB6E;
}

.TrayItemNumber {
  width: 50%;
  min-width: 32px;
  display: flex;
  color: white;
  font-size: 14px;
  align-items: center;
  aspect-ratio: 1 / 1;
  justify-content: center;
  background-color: rgb(169, 169, 169);
  border-right: 1px solid black;
}

.TrayItemTotalQty {
  width: 50%;
  min-width: 32px;
  display: flex;
  color: white;
  font-size: 14px;
  align-items: center;
  aspect-ratio: 1 / 1;
  justify-content: center;
  background-color: rgb(0, 120, 255);
}

.TrayItemQuantity {
  width: 50%;
  min-width: 32px;
  display: flex;
  color: black;
  font-size: 14px;
  align-items: center;
  aspect-ratio: 1 / 1;
  justify-content: center;
  background-color: rgb(255, 255, 0);
}

.TrayItemPartialQuantity {
  width: 50%;
  min-width: 32px;
  display: flex;
  color: black;
  font-size: 14px;
  align-items: center;
  aspect-ratio: 1 / 1;
  justify-content: center;
  background-color: rgb(250, 128, 114);
}

.TrayItemControlQuantity {
  width: 50%;
  min-width: 32px;
  display: flex;
  color: black;
  font-size: 14px;
  align-items: center;
  aspect-ratio: 1 / 1;
  justify-content: center;
  background-color: rgb(255, 0, 0);
}

.TrayItemQuantityZero {
  width: 50%;
  min-width: 32px;
  display: flex;
  color: black;
  font-size: 14px;
  align-items: center;
  aspect-ratio: 1 / 1;
  justify-content: center;
  background-color: transparent;
}