.Dashboard {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.Dashboard__headerRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

@media (max-width: 640px) {
  .Dashboard__headerRow {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px 0 16px;
  }
}

.dashboard__lightContainer {
  position: absolute;
  top: 0;
  width: 78%;
  padding-left: 12px;
  box-sizing: border-box;
  z-index: 5;
}

.Dashboard__defaultSiteName {
  position: relative;
  font-style: normal;
  color: #121a2d;
}

@media (max-width: 640px) {
  .Dashboard__defaultSiteName {
    font-size: 20px;
    line-height: 1;
    margin: 4px 0 0 0;
    padding: 0;
  }
}

@media (min-width: 641px) {
  .Dashboard__defaultSiteName {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    margin-right: 20px;
  }
}

.Dashboard__widgets {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

@media (max-width: 640px) {
  .Dashboard__widgets {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    padding-top: 24px;
  }
}

@media (min-width: 641px) {
  .Dashboard__widgets {
    height: 100%;
    margin-top: 44px;
  }
  .Dashboard__widgets--details {
    margin-top: 0;
  }
}

.Dashboard__row {
  display: flex;
  align-items: center;
}

.dashboard__tabBar {
  display: flex;
}

.Dashboard__dropdownMultiSelectContainer {
  width: 300px;
}

.Dashboard__dropdownMultiSelectContainer--noShow {
  height: 81px;
}

.Dashboard__dropdownMultiSelectContainer--noShow div:first-child {
  display: none;
}

@media (max-width: 640px) {
  .dashboard__searchBarContainer {
    position: absolute;
    top: 0;
    width: 78%;
    padding-left: 12px;
    box-sizing: border-box;
    z-index: 5;
  }
}

[tooltip] {
  position: relative;
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none;
  font-size: 0.9em;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: "";
  border: 5px solid transparent;
  z-index: 1001;
}
[tooltip]::after {
  content: attr(tooltip);

  font-family: Helvetica, sans-serif;
  text-align: right;
  padding-left: 5px;

  min-width: 100px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  padding: 1ch 2ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000;
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}

[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

.xAxisLabel span {
  padding: 0.5em 1em;
  margin: 0.5em;
  display: inline-block;
  background-color: #dedede;
}

.Dashboard__backdropHide {
  display: none;
}

.Dashboard__backdropShow {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  /* background-color: rgba(152, 169, 188, 0.9); */
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.Dashboard__dateRangePickerContainer {
  position: relative;
  z-index: 250;
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.Dashboard__startAndEndDateHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Dashboard__startAndEndDateHeader p {
  padding: 0;
  margin: 0;
  line-height: 1;
}

.Dashboard__startAndEndDateHeader p:first-child {
  padding-bottom: 6px;
}

.Dashboard__startAndEndDateTitle {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #089bab;
  padding-bottom: 8px;
}

.Dashboard__startAndEndDateValue {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #121a2d;
  cursor: pointer;
}

.Dashboard__pillSortViewShow {
  display: block;
}

.Dashboard__pillSortViewHide {
  display: none;
}

.Dashboard__waitIndicatorContainer {
  margin-left: -60px;
}

.Dashboard__accordionContainer {
  display: block;
}

.Dashboard__accordionContainer--hide {
  display: none;
}

.graphsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
  object-fit: fill;
  max-width: 760px;
}

.graphsContainer--smallView {
  max-width: 760px;
}

.Dashboard__dropDownInputContainer {
  width: 200px;
  margin-left: 0;
  margin-right: 40px;
}

@media (max-width: 640px) {
  .Dashboard__dropDownInputContainer {
    width: 100%;
    margin: 18px 0 6px 0;
  }
}

.Dashboard__searchGroup {
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  transition: all ease-in-out 0.1s;
  margin-right: 8px;
  padding-left: 8px;
}

.Dashboard__searchGroup--inactive {
  cursor: pointer;
}

.Dashboard__searchGroup--inactive:active,
.Dashboard__searchGroup--inactive:hover {
  background-color: #e6e9ef;
}

.Dashboard__searchGroup--active {
  border: 1px solid #089bab;
  width: 216px;
  max-width: 216px;
  margin-right: 14px;
}

.Dashboard__searchGroup--activeWithContent {
  background-color: #ceebee;
}

.Dashboard__searchGroup--activeWithContent > input {
  background-color: #ceebee;
}

.Dashboard__searchGroupTitle {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: var(--black);
  line-height: 1;
  margin: 0;
  padding-left: 2px;
  padding-right: 10px;
}

.Dashboard__clearSearchBarContainer {
  margin-left: auto;
  padding: 3px 5px 0 3px;
  cursor: pointer;
}

.Dashboard__filterContainer {
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  padding-left: 8px;
  transition: all ease-in-out 0.1s;
}

.Dashboard__filterContainer--active {
  background-color: #ceebee;
}

.Dashboard__filterContainer:hover {
  background-color: #e6e9ef;
}

.Dashboard__filterContainer .Dashboard__filterGroupAdvanced:hover {
  background-color: #e6e9ef;
}

.Dashboard__filterGroupTitle {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: var(--black);
  line-height: 1;
  margin: 0;
  padding-left: 2px;
  padding-right: 4px;
}

.Dashboard__filterGroupQuick {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Dashboard__filterGroupAdvanced {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 24px;
  height: 100%;
}

.Dashboard__cheveronDownIconDivisor {
  width: 2px;
  background: #fff;
  height: 100%;
}

.Dashboard__cheveronDownIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 100%;
  cursor: pointer;
}

.Dashboard__cheveronDownIconContainer:hover {
  background-color: #c3c6d4;
}

.Dashboard__syncFusionDropdownContainer:not(:last-child) {
  display: flex;
  flex-direction: column;
  width: 210px;
  margin-right: 36px;
}

@media (max-width: 640px) {
  .Dashboard__syncFusionDropdownContainer {
    width: 100%;
    margin-right: 0;
    box-sizing: border-box;
  }
}

/* .Dashboard__multiSelectDropdownContainer {
  position: absolute;
  top: 0;
  left: 730px;
} */
