.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background-color: transparent;
}

.modal {
  position: fixed;
  top: 21vh;
  width: 100%;
  background-color: transparent;
  z-index: 200;
}

@media only screen and (min-width: 641px) {
  .backdrop {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .modal {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 720px;
    top: 40px;
  }
}
