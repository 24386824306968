.SuctionCupLifeCardList__container {
  padding: 0 16px 0 16px;
  /* margin-bottom: 16px; */
}

.SuctionCupLifeCardList__hr {
  height: 1px;
  width: 100%;
  border-width: 0;
  color: var(--black);
  background-color: var(--black);
  margin-top: 18px;
  margin-bottom: 18px;
}

.SuctionCupLifeCardList__noDataFoundMessage {
  display: flex;
  justify-content: center;
  width: 100%;
}

.SuctionCupLifeCardList__noDataFoundMessage p {
  font-family: "Poppins", sans-serif;
  color: var(--black);
  font-size: 16px;
  line-height: 1;
  margin: 24px 0 0 0;
  padding: 0;
}
