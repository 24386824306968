.helpVideos__buttonGroup {
  padding-top: 30px;
  margin-top: auto;
  text-align: center;
}

@media (max-width: 640px) {
  .helpVideos__buttonGroup {
    padding: 0;
  }
}

.helpVideos__dataButton button {
  width: 140px;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

.helpVideos__dataButton button {
  width: 140px;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

.helpVideos__helpVideosList {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  grid-gap: 36px;
  gap: 36px;
  width: 100%;
  list-style: none;
  margin: 24px 0 36px 0;
  padding: 0;
  overflow-y: auto;
}

@media (max-width: 640px) {
  .helpVideos__helpVideosList {
    justify-content: center;
    grid-gap: 16px;
    gap: 16px;
    margin-bottom: 24px;
  }
}

@media (max-width: 640px) {
  .helpVideos__helpVideosList li {
    width: 100%;
  }
}

.helpVideos__topicCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 420px;
  height: 258px;
  border: 1px solid var(--green);
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
  border-radius: 5px;
  cursor: pointer;
}

.helpVideos__topicCard h1 {
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  line-height: 1;
  color: #fff;
  background-color: var(--green);
  text-align: center;
  padding: 10px 0;
  width: 100%;
}

.helpVideos__cardImageContainer {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  background: var(--blackExtraLight);
}
