.scrollArea {
  overflow-x: hidden;
  overflow-y: auto;
}

@media only screen and (max-width: 640px) {
  .scrollArea {
    padding: 4px 3px 3px 3px;
    height: 0;
    flex: 1 0 auto;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }

  .scrollArea::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width: 641px) {
  .newStockedItems {
    height: 100%;
    max-height: 260px;
    padding: 4px 10px 3px 3px;
    scroll-padding: 0 0 0 3px;
    margin-top: 16px;
    margin-left: 60px;
    margin-right: 60px;
    /* margin-bottom: 43px; */
  }
}
