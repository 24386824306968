@media (max-width: 640px) {
  .CreateProduct__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

@media (min-width: 641px) {
  .CreateProduct__overlay {
    position: absolute;
    min-height: 100%;
    top: 0;
    right: 0;
    background-color: rgba(152, 169, 188, 0.3);
    backdrop-filter: blur(46.2108px);
    z-index: 210;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .CreateProduct__overlay {
    /* position: fixed;
    left: 0; */
    width: 100vw;
    left: -70px;
    position: absolute;
    min-height: 670px;
    height: 100%;
    top: 0;
    right: 0;
  }
}

@media (min-width: 1008px) {
  .CreateProduct__overlay {
    left: -120px; /* Needed to cover vertical menu */
    height: 100%;
    min-height: 996px;
  }
}

.CreateProduct__view {
  display: flex;
  position: relative;
  flex-direction: column;
}

@media (max-width: 640px) {
  .CreateProduct__view {
    height: 100%;
    color: #fff;
    margin: 12px 12px 0 12px;
  }
}

@media (min-width: 641px) {
  .CreateProduct__view {
    margin: 100px auto;
    max-height: 1080px;
    padding-right: 20px;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .CreateProduct__view {
    height: 100vh;
    max-height: 600px;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    box-sizing: border-box;
  }
}

@media (min-width: 1008px) {
  .CreateProduct__view {
    max-width: 1100px;
    padding-left: 64px;
    padding-right: 64px;
    min-height: 600px;
    max-height: 900px;
  }
}

@media (max-width: 640px) {
  .CreateProduct__header {
    margin-left: 18px;
    margin-top: 1px;
    margin-bottom: 24px;
  }
}

@media (min-width: 641px) {
  .CreateProduct__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .CreateProduct__header {
    margin-top: 20px;
  }
}

@media (min-width: 1008px) {
  .CreateProduct__header {
    margin-top: 64px;
    margin-bottom: 59px;
  }
}

@media (max-width: 640px) {
  .CreateProduct__backArrow {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .CreateProduct__goBackTitle {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(18, 26, 45, 0.5);
    line-height: 1;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 640px) {
  .CreateProduct__backArrowIcon {
    padding-right: 4px;
  }
}

.CreateProduct__title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #121a2d;
}

@media (max-width: 640px) {
  .CreateProduct__title {
    font-size: 20px;
    line-height: 1;
    margin: 24px 0 0 2px;
    padding: 0;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .CreateProduct__title {
    margin: 0 0 30px 0;
    font-size: 30px;
    line-height: 1;
  }
}

@media (min-width: 1008px) {
  .CreateProduct__title {
    margin: 0;
    font-size: 34px;
    line-height: 27px;
  }
}

@media (max-width: 640px) {
  .CreateProduct__scroll {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }
}

@media (min-width: 641px) {
  .CreateProduct__scroll {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .CreateProduct__scroll {
    height: 100%;
  }
}

@media (min-width: 1008px) {
  .CreateProduct__scroll {
    height: 100%;
  }
}

@media (max-width: 640px) {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .CreateProduct__scroll::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 641px) {
  .CreateProduct__scroll::-webkit-scrollbar-track {
    margin-top: 40px;
  }
}

.CreateProduct__close {
  position: absolute;
  top: 35px;
  right: 35px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.CreateProduct__view main {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 20px 0;
  overflow: auto;
}

@media (max-width: 640px) {
  .CreateProduct__productCategory_row {
    display: block;
  }
}

@media (min-width: 641px) {
  .CreateProduct__productCategory_row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

.CreateProduct__productCategory_row--center {
  justify-content: center;
}

.CreateProduct__productCategory_row_end {
  justify-content: end;
  outline: none;
}

.CreateProduct__productCategory_col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.CreateProduct__view footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .CreateProduct__view footer {
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 30px;
    padding-top: 25px;
  }
}

@media (min-width: 641px) {
  .CreateProduct__view footer {
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 38px;
    margin-left: 50px;
    margin-right: 108px;
    margin-bottom: 51px;
  }
}
@media (max-width: 640px) {
  .CreateProduct__utilities {
    margin-left: 12px;
    margin-right: 12px;
  }
}

@media only screen and (max-width: 640px) {
  .CreateProduct__errorBannerContainer {
    margin: 10px;
    position: relative;
    z-index: 210;
  }
}

@media only screen and (min-width: 641px) {
  .CreateProduct__errorBannerContainer {
    width: 100%;
    margin-bottom: 12px;
    z-index: 250;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1007px) {
  .CreateProduct__errorBannerContainer {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1008px) {
  .CreateProduct__errorBannerContainer {
    margin-top: 10px;
  }
}
