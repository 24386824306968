.prodList__container {
  color: #121a2d;
  font-family: Poppins;
  font-weight: 300;
  font-size: 14px;
  margin-top: 21px;
}

.prodList__container--red {
  /* color: red; */
}

.prodList__row {
  display: flex;
  align-items: center;
  height: 79px;
  background: #ffffff;
  border: 1px solid #e8ecef;
  box-shadow: 0px 5px 14px rgba(120, 146, 141, 0.05);
  border-radius: 10px;
}

.productList__row--withScroll {
  padding-right: 10px;
}

.prodList__row:not(:last-child) {
  margin-bottom: 21px;
}

.prodList__id {
  margin-left: 20px;
  margin-right: 46px;
  min-width: 121px;
  overflow-wrap: anywhere;
}

.prodList__description {
  min-width: 256px;
  margin-right: 58px;
  overflow-wrap: anywhere;
}

.prodList__manufacturer {
  min-width: 141px;
  margin-right: 140px;
  overflow-wrap: anywhere;
}

.prodList__AddAPIcon {
  width: 40px;
  height: auto;
  fill: #e5eaed80;
}

.prodList__AddAP:hover {
  cursor: pointer;
}

.prodList__scrollArea {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  height: 390px;
  margin-left: 66px;
  margin-right: 66px;
}
