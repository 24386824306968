@media (min-width: 641px) {
  .pickList__scrollArea {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    height: 720px;
    padding-right: 10px;
  }
}

@media (max-width: 640px) {
  .pickList__scrollArea {
    height: 100%;
    overflow: scroll;
    overflow-x: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-top: 18px;
    margin-bottom: auto;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .pickList__scrollArea::-webkit-scrollbar {
    display: none;
  }
}

.pickList__view {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

@media (max-width: 640px) {
  .pickList__view {
    flex: 1 1;
    height: 100vh;
  }
}

@media (min-width: 641px) {
  .pickList__view {
    max-width: 100%;
    height: 100%;
    /* min-height: 1024px; */
  }
}

.pickList__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 34px;
  height: 100%;
}

@media (min-width: 640px) {
  .pickList__mainContainer {
    width: inherit;
  }
}

@media (min-width: 641px) {
  .pickList__mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    box-shadow: 0px 5px 14px rgb(120 146 141 / 5%);
    min-height: 79px;
    margin-bottom: 10px;
    border: 1px solid #e8ecef;
    border-radius: 10px;
    cursor: pointer;
  }
}

.pickList__mainContainer:last-child {
  margin-bottom: 0;
}

.pickList__mainContainer--expanded {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.pickList__productInfo {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
}

.pickList__productInfo--expanded {
  box-shadow: 0px 5px 14px rgb(120 146 141 / 5%);
  border: 1px solid #e8ecef;
  border-radius: 10px;
  margin-bottom: 20px;
}

.pickList__boxIconContainer {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: -32px;
}

.pickList__boxIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 16px;
  height: 16px;
  margin: 0;
  min-height: 50px;
  font-size: 12px;
  color: rgb(8, 155, 171);
  border-top: 2px solid rgba(217, 225, 236, 0.3);
}

.pickList__subContainer {
  display: flex;
  flex-direction: column;
}

@media (max-width: 640px) {
  .pickList__subContainer {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (min-width: 641px) {
  .pickList__subContainer {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.pickList__mainColumnVal--item1 {
  margin-left: 40px;
  margin-right: 50px;
  min-width: 152px;
  white-space: nowrap;
}

.pickList__mainColumnVal--item2 {
  margin-right: 50px;
  min-width: 260px;
  white-space: nowrap;
}

.pickList__mainColumnVal--item3 {
  margin-right: 116px;
  min-width: 200px;
  white-space: nowrap;
}

.pickList__mainColumnVal--item4 {
  margin-right: 50px;
  text-align: right;
  min-width: 70px;
  white-space: nowrap;
}

.pickList__mainColumnVal--item5 {
  width: 100%;
  text-align: right;
  margin-right: 52px;
}

.pickList__subColumnVal--item1 {
  margin-left: 30px;
  margin-right: 50px;
  min-width: 260px;
  white-space: nowrap;
}

@media (max-width: 640px) {
  .pickList__subColumnVal--item2 {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (min-width: 641px) {
  .pickList__subColumnVal--item2 {
    margin-right: 50px;
    min-width: 252px;
    white-space: nowrap;
  }
}

.pickList__subColumnVal--item3 {
  margin-right: 50px;
  min-width: 250px;
  white-space: nowrap;
}

.pickList__subColumnVal--item4 {
  width: 100%;
  text-align: right;
  margin-right: 20px;
}

.pickList__stockedItemContainer {
  display: flex;
  align-items: center;
  color: rgb(8, 155, 171);
  font-size: 14px;
  font-weight: 300;
  border: 1px solid #e8ecef;
  border-radius: 10px;
  box-shadow: 0px 5px 14px rgb(120 146 141 / 5%);
  margin-bottom: 10px;
}

@media (max-width: 640px) {
  .pickList__locationsTitle {
    margin: 0 0 0 0;
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    color: #121a2d;
    font-family: "Poppins", sans-serif;
    line-height: 1;
    font-weight: 400;
    font-size: 16px;
  }
}
