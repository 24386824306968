.locationScanned__stockLocationName {
  font-family: "Poppins", sans-serif;
  color: #093a64;
  font-weight: 500;
  font-size: 16px;
}

@media only screen and (max-width: 640px) {
  .locationScanned__stockLocationName {
    margin-top: 12px;
  }
}

@media only screen and (min-width: 641px) {
  .locationScanned__stockLocationName {
    margin: 28px 0 0 0;
    padding-left: 60px;
  }
  .locationScanned__stockLocationName--withBottomPadding {
    padding-bottom: 43px;
  }
}
