@media (max-width: 640px) {
  .inventory__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

@media (min-width: 641px) {
  .inventory__overlay {
    display: flex;
    position: absolute;
    min-height: 100%;
    top: 0;
    right: 0;
    background-color: rgba(152, 169, 188, 0.3);
    backdrop-filter: blur(46.2108px);
    z-index: 210;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .inventory__overlay {
    width: 100vw;
    left: -70px;
    position: absolute;
    min-height: 810px;
    height: 100%;
    top: 0;
    right: 0;
  }
}

@media (min-width: 1008px) {
  .inventory__overlay {
    left: -120px; /* Needed to cover vertical menu */
    height: 100%;
    min-height: 996px;
  }
}

.inventory__view {
  display: flex;
  position: relative;
  flex-direction: column;
}

@media (max-width: 640px) {
  .inventory__view {
    height: 100%;
    color: #fff;
    margin: 12px 12px 0 12px;
  }
}

@media (min-width: 641px) {
  .inventory__view {
    align-items: center;
    align-self: center;
    margin: 100px auto;
    max-height: 1080px;
    padding-right: 20px;
    background-color: transparent;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .inventory__view {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    box-sizing: border-box;
  }
}

@media (min-width: 1008px) {
  .inventory__view {
    max-width: 1100px;
    padding-left: 64px;
    padding-right: 64px;
  }
}

@media screen and (max-width: 640px) {
  .inventory {
    padding: 0 16px 0 16px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    height: 100vh;
  }
}

@media screen and (min-width: 641px) {
  .inventory {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
  }
}

@media screen and (min-width: 641px) and (max-width: 1007px) {
  .inventory {
    padding: 0;
  }
}

@media screen and (max-width: 640px) {
  .inventory__scroll {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
    padding: 0 16px 0 16px;
  }
}

@media screen and (max-width: 640px) {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .inventory__scroll::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .inventory__headerRow {
    min-height: 64px;
    max-height: 64px;
    border-bottom: 1px solid #eceff8;
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 641px) {
  .inventory__headerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .inventory__dropdowns {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 641px) {
  .inventory__dropdowns {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

div .inventory__row:first-child {
  margin-right: 20px;
}

.inventory__dropdownContainer {
  display: flex;
  flex-direction: column;
  outline: none;
}

@media screen and (max-width: 640px) {
  .inventory__dropdownContainer {
    margin-top: 24px;
  }
}

@media screen and (min-width: 641px) and (max-width: 1007px) {
  .inventory__dropdownContainer {
    max-width: 250px;
  }

  .inventory__dropdownContainer:not(:last-child) {
    margin-right: 20px;
  }

  .inventory__dropdownContainer:last-child {
    max-width: 200px;
  }
}

.inventory__title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #121a2d;
}

@media screen and (max-width: 640px) {
  .inventory__title {
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: 641px) {
  .inventory__title {
    margin: 0;
  }
}

@media screen and (max-width: 640px) {
  .inventory__body {
    display: block;
  }
}

@media screen and (min-width: 641px) {
  .inventory__body {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 1007px) {
  .inventory__body {
    margin-top: 234px;
  }
}

@media screen and (min-width: 1008px) {
  .inventory__body {
    margin-top: 300px;
    max-height: 100%;
  }
}

.inventory__tabBar {
  display: flex;
}

.inventory__date {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #888c96;
}

.inventory__dateDot {
  margin-left: 2px;
  margin-right: 2px;
  font-size: 28px;
  vertical-align: middle;
}

.inventory__heading {
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 49px;
  color: #454b60;
  padding-bottom: 52px;
}

.inventory__heading--pharmacy {
  font-weight: 400;
}

.inventory__multiSelectContainer {
  width: 400px;
  padding-top: 20px;
  padding-bottom: 50px;
}

.inventory__iconsGroup {
  position: absolute;
  top: 80px;
  right: 0;
  display: flex;
  /*flex-direction: column;*/
  z-index: 100;
}

.inventory__iconBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 67px;
  height: 57px;
  background-color: #ffffff;
  border: 1px solid #e8ecef;
  box-shadow: 0 5px 14px rgba(120, 146, 141, 0.05);
  border-radius: 10px;
}

.inventory__iconBox:not(:last-child) {
  margin-right: 3px;
}

.inventory__iconBox:hover {
  cursor: pointer;
}

.inventory__iconBox:active,
.inventory__iconBox:focus {
  outline: 3px solid var(--focusColor);
}

.inventory__iconBox:hover .inventory__icon,
.inventory__iconBox:active .inventory__icon,
.inventory__iconBox:focus .inventory__icon {
  fill: #089bab;
}

.inventory__iconBox:hover .inventory__iconText,
.inventory__iconBox:active .inventory__iconText,
.inventory__iconBox:focus .inventory__iconText {
  color: #089bab;
}

.inventory__icon {
  fill: #778ca2;
  height: 24px;
  width: 24px;
  transition: all ease-in-out 0.2s;
}

.inventory__iconText {
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0;
  text-align: center;
  color: #778ca2;
  padding: 0;
  margin: 0;
  margin-top: 2px;
  transition: all ease-in-out 0.3s;
}

.inventory__iconText--extraMargin {
  margin-top: 3px;
}

@media only screen and (min-width: 376px) {
  .inventory__closeModalIcon {
    fill: #fff;
    height: 20.7px;
    width: 19.5px;
    position: absolute;
    right: -34px;
    top: -34px;
    mix-blend-mode: normal;
  }
}

@media only screen and (max-width: 640px) {
  .inventory__mobileHeader {
    position: relative;
  }

  .inventory__alarmDateTimeContainer {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 640px) {
  .inventory__searchBarContainer {
    position: absolute;
    top: 0;
    width: 78%;
    padding-left: 32px;
    box-sizing: border-box;
    z-index: 5;
  }
}

@media (max-width: 640px) {
  .inventory__dropdownsContainer {
    padding: 0 16px 0 16px;
  }
}

.inventory__noSitesMessage {
  background-color: #d1ecf1;
  justify-content: center;
  padding: 0.75rem 1.25rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  border: 1px solid #bee5eb;
  border-radius: 0.25rem;
}

@media (max-width: 640px) {
  .inventory__noSitesMessage {
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .inventory__noSitesMessage p {
    color: #0c5460;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }

  .inventory__pageTitleForNoSites {
    position: absolute;
    top: 25px;
    left: 52px;
    font-size: 14px;
    line-height: 1;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #121a2d;
    margin: 0;
  }
}
