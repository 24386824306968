.TruPakDashboard__viewContainer {
  overflow-x: hidden;
  overflow-y: auto;
}

.TruPakDashboard__graphsGroup {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 36px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.TruPakDashboard__graphsGroup:not(:last-child) {
  margin-bottom: 40px;
}

.TruPakDashboard__graphsGroup > * {
  border: 1px solid #e8ecef;
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
}

.TruPakDashboard__graphContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins", sans-serif;
  width: 450px;
  height: 290px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
}

.TruPakDashboard__graphContainer--paddingTop {
  padding-top: 24px;
}

.TruPakDashboard__graphContainer--paddingBottom {
  padding-bottom: 12px;
}

.TruPakDashboard__loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 450px;
  height: 288px;
  left: 0;
  right: 0;
  top: 0;
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #121a2d;
}

@media (min-width: 1008px) {
  .TruPakDashboard__sitesAndDevicesContainer {
    width: 100%;
    box-sizing: border-box;
    margin-top: -134px;
    margin-left: 240px;
    margin-bottom: 20px;
  }
}

@media (max-width: 640px) {
  .TruPakDashboard__sitesAndDevicesContainer {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: -44px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .TruPakDashboard__sitesAndDevicesContainer {
    width: 100%;
    box-sizing: border-box;
    margin-top: -44px;
    margin-bottom: 20px;
  }
}

.TruPakDashboard__sitesSelectionListContainer,
.TruPakDashboard__devicesSelectionListContainer {
  display: flex;
  min-height: 53px;
}

@media (max-width: 1007px) {
  .TruPakDashboard__sitesSelectionListContainer,
  .TruPakDashboard__devicesSelectionListContainer {
    flex-direction: column;
  }
}

.TruPakDashboard__devicesSelectionListContainer {
  margin-top: -20px;
}

.TruPakDashboard__sitesSelectionListContainer p,
.TruPakDashboard__devicesSelectionListContainer p {
  line-height: 1;
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  color: var(--green);
  margin-top: 20px;
}

.TruPakDashboard__sitesSelectionList,
.TruPakDashboard__devicesSelectionList {
  display: flex;
  align-items: center;
  padding: 0;
  list-style-type: none;
  flex-wrap: wrap;
}

@media (max-width: 1007px) {
  .TruPakDashboard__sitesSelectionList {
    margin-left: 0;
  }
}

@media (min-width: 1008px) {
  .TruPakDashboard__sitesSelectionList {
    margin-left: 33px;
  }
}

.TruPakDashboard__devicesSelectionList {
  margin-left: 14px;
}

@media (max-width: 1007px) {
  .TruPakDashboard__devicesSelectionList {
    margin-left: 0;
  }
}

.TruPakDashboard__siteListItem,
.TruPakDashboard__deviceListItem {
  padding-bottom: 4px;
}

.TruPakDashboard__siteListItem:not(:last-child),
.TruPakDashboard__deviceListItem:not(:last-child) {
  padding-right: 24px;
}
