.TrayCellModalBackground {
  background-color: #EAEAEA !important;
}

.TrayCellCorrectedApprovalModalBackground {
  background-color: #EAEAEA !important;
}

.TrayCellModalContainer {
  gap: 10px;
  display: grid;
  padding: 30px;
  grid-template-columns: repeat(2, 1fr);
}

.TrayCellModalHeader {
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.TrayCellModalFooter {
  gap: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.TrayCellSelectedItem {
  padding: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 3px solid #a00;
}

.TrayCellItem {
  padding: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #000;
}

.CorrectedTrayCellItem {
  padding: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #FDCB6E;
}

.TableWrapper {
  width: 70%;
  min-width: 70%;
  max-width: 70%;
}

.TrayCellItemInfo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MedicineInfoTable {
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;
}

.MedicineCorrectedInfoTable {
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;
}

.MedicineInfoLabelCell {
  text-align: right;
}

.MedicineInfoCell {
  font-weight: bold;
  text-align: left !important;
}

.MedicineImageWrapper {
  border: 1px solid blue;
  min-width: 30%;
  max-width: 30%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.MedicineImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1/1;
  border: 1px solid #A7BBCF;
  box-sizing: border-box;
  border-radius: 4px;
  object-fit: contain;
  overflow: hidden;
}

.MedicineImage {
  object-fit: contain;
  width: 100%;
}

.NoImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1/1;
  border: 1px solid #A7BBCF;
  box-sizing: border-box;
  border-radius: 4px;
}

.CorrectedApprovalModalRadioGroupContainer {
  width: 100%;
  margin-bottom: 20px;
}

.CorrectedApprovalModalRadioOptionsRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.CorrectedApprovalModalHeader {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  background-color: #EAEAEA !important;
}