.stock__card {
  background-color: #ffffff;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .stock__card {
    height: 100vh;
  }
}

@media only screen and (min-width: 641px) {
  .stock__card {
    height: inherit;
  }
}

.stock__moveUp150px {
  margin-top: -150px;
}

/* For Modal 2 */
.stock__backdrop2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgba(152, 169, 188, 0.3);
  backdrop-filter: blur(46.2108px);
}

@media only screen and (min-width: 641px) {
  .stock__card {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.stock__modal2 {
  background-color: transparent;
  z-index: 60;
  width: inherit;
  height: inherit;
}

.stock__errorClassName {
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 60px;
}

.stock__modalTransparentBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent;
  box-sizing: border-box;
  z-index: 200;
}

@media only screen and (min-width: 641px) {
  .stock__modalTransparentBackdrop {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}
