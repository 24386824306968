.inventoryListCard__container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #e8ecef;
  margin-bottom: 16px;
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
}

.inventoryListCard__container--dark {
  /* background-color: rgba(245, 245, 245, 0.8); */
  background-color: transparent;
}

.inventoryListCard__container--light {
  background-color: transparent;
}

.inventoryListCard__rowGroup {
  display: flex;
  justify-content: flex-start;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

/* @media (max-width: 440px) {
  .inventoryListCard__rowGroup:nth-child(3) {
    flex-direction: column;
  }
} */

.inventoryListCard__rowGroup:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.inventoryListCard__dataList {
  padding: 0;
  margin: 10px;
}

.inventoryListCard__colGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  color: #121a2d;
  font-family: "Poppins", sans-serif;
  line-height: 16px;
}

.inventoryListCard__colGroup--1 {
  width: 100%;
  margin-right: 20px;
}

.inventoryListCard__colGroup--2 {
  width: 150px;
}

.inventoryListCard__colGroup--3Equal {
  flex-basis: 33.3%;
  flex-grow: 0;
}

@media (max-width: 440px) {
  .inventoryListCard__emailGroup {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

.inventoryListCard__dataItem {
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin-left: 0 !important;
  color: #121a2d;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

.inventoryListCard__dataItem:not(:last-child) {
  margin-bottom: 4px;
}

.inventoryListCard__heading {
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 10px;
}

.inventoryListCard__value {
  font-size: 13px;
  font-weight: 300;
  overflow: hidden;
  width: 100%;
}

.inventoryListCard__actions {
  position: absolute;
  top: 0;
  right: 0;
}
