.usersDataCard__container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #e8ecef;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
}

.usersDataCard__rowGroup {
  display: flex;
  justify-content: flex-start;
  position: relative;
}

@media (max-width: 440px) {
  .usersDataCard__rowGroup:nth-child(2),
  .usersDataCard__rowGroup:nth-child(3) {
    flex-direction: column;
  }
}

.usersDataCard__rowGroup:nth-child(2),
.usersDataCard__rowGroup:nth-child(3) {
  padding-bottom: 10px;
}

.usersDataCard__rowGroup:first-child,
.usersDataCard__rowGroup:nth-child(2),
.usersDataCard__rowGroup:nth-child(3) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.usersDataCard__dataList {
  padding: 0;
  margin: 10px;
}

.usersDataCard__colGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  color: #121a2d;
  font-family: "Poppins", sans-serif;
  line-height: 16px;
}

.usersDataCard__colGroup--1 {
  width: 100%;
  margin-right: 20px;
}

@media (max-width: 440px) {
  .usersDataCard__emailGroup {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

.usersDataCard__colGroup--2 {
  width: 150px;
}

.usersDataCard__colGroup--actions {
  margin-left: auto;
}

.usersDataCard__dataItem:not(:last-child) {
  margin-bottom: 4px;
}

.usersDataCard__heading {
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 10px;
}

.usersDataCard__value {
  font-size: 13px;
  font-weight: 300;
  overflow: hidden;
  width: 100%;
}

.usersDataCard__userGroup {
  display: flex;
  align-items: center;
}

.usersDataCard__fullName {
  margin-bottom: 10px;
}

.usersDataCard__actions {
  position: absolute;
  top: 0;
  right: 0;
}

.usersDataCard__actions:hover {
  cursor: pointer;
}

.usersDataCard__rolesContainer {
  display: flex;
  align-items: center;
}
