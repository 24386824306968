.numStockedItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
  height: 64px;
  border-radius: 6px;
  background-color: #f2f4f6;
  margin-right: 8px;
  margin-left: 3px;
  flex-shrink: 0;
  flex-grow: 0;
}

.numContainers {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0px;
  color: #87909e;
}
