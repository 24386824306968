.stock {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

@media only screen and (min-width: 641px) {
  .stock {
    height: inherit;
  }
}

.stock__getSiteContainer {
  padding-left: 16px;
  padding-right: 16px;
}

.stock__getSite {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

@media only screen and (max-width: 640px) {
  .stock__errorBannerContainer {
    margin: 10px;
    position: relative;
    z-index: 210;
  }
}

@media only screen and (min-width: 641px) {
  .stock__errorBannerContainer {
    width: 720px;
    margin-bottom: 12px;
    z-index: 250;
  }
}
