.EV54Replenishment_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.EV54Replenishment_header_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.EV54Replenishment_button__primary {
    background-color: #089bab;
    color: white;
    opacity: 1;
    border: none;
    padding: 15px 40px;
    border-radius: 10px;
    cursor: pointer;
}

.EV54Replenishment_button__primary:disabled, .EV54Replenishment_button__secondary:disabled {
    opacity: 0.5;
}
