@media (min-width: 641px) {
  .NDCTable__mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .NDCTable__tableContainer {
    margin-bottom: 24px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 10px;
  }

  .NDCTable__buttonGroup {
    margin-top: auto;
    padding-top: 30px;
    text-align: center;
  }

  .NDCTable__dataButton button {
    width: 140px;
    line-height: 1;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }

  .NDCTable__priceFileContainer {
    position: absolute;
    top: 34px;
    right: 0;
    display: flex;
    align-items: center;
    padding: 2px;
    cursor: pointer;
  }

  .NDCTable__priceFileContainer p {
    margin: 0;
    padding: 0 8px 0 0;
    line-height: 1;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
  }

  .NDCTable__priceFileContainer svg {
    fill: #778ca2;
    height: 24px;
    width: 24px;
  }

  .NDCTable__dropdownInputContainer {
    width: 300px;
  }

  .NDCTable__inputContainer {
    width: 74px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .NDCTable__processEditBox {
    position: absolute;
    left: 88px;
    top: 6px;
    width: 16px;
    height: 16px;
    border: 1px solid #5cb85c;
    cursor: pointer;
  }

  .NDCTable__revertBackBox {
    position: absolute;
    left: 114px;
    top: 6px;
    width: 16px;
    height: 16px;
    border: 1px solid red;
    cursor: pointer;
  }

  .NDCTable__revertBackBox svg {
    opacity: 1;
  }

  .NDCTable__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
    cursor: pointer;
    line-height: 1;
    font-family: "Poppins", sans-serif;
    color: #121a2d;
  }

  .NDCTable__header h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
  }

  .NDCTable__costBoxesContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .NDCTable__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #121a2d;
  }

  .NDCTable__loader svg {
    margin-top: 40px;
  }
}

@media (max-width: 640px) {
  .NDCTable__mainContainer {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 0 24px 0;
  }

  .NDCTable__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }

  .NDCTable__header p {
    padding: 0;
    margin: 0;
  }

  .NDCTable__backButton {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .NDCTable__backButton img {
    width: 24px;
    height: 24px;
  }

  .NDCTable__runReportButtonContainer {
    margin: 8px 0 0 0;
  }

  .NDCTable__cardListContainer {
    overflow: auto;
    height: 100%;
  }

  .NDCTable__inputContainer {
    width: 100%;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .NDCTable__inputContainer input {
    width: 100%;
  }

  .NDCTable__processEditBox {
    width: 16px;
    height: 16px;
    border: 1px solid #5cb85c;
    cursor: pointer;
  }

  .NDCTable__processEditBox svg {
    padding-bottom: 3px;
    padding-left: 2px;
  }

  .NDCTable__revertBackBox {
    width: 16px;
    height: 16px;
    border: 1px solid red;
    cursor: pointer;
    margin-left: 24px;
    margin-right: 6px;
  }

  .NDCTable__revertBackBox svg {
    opacity: 1;
    padding-bottom: 2px;
  }
  .NDCTable__costBoxesContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 24px;
  }
  .NDCTable__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 288px;
    top: -30px;
    left: 0;
    right: 0;
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 1;
    color: #121a2d;
  }

  .NDCTable__loader svg {
    margin-top: 40px;
  }
}
