.selectSiteWithDevice__scrollArea {
  overflow-x: hidden;
  overflow-y: auto;
  scroll-padding: 0 0 0 3px;
  margin-bottom: 0;
}

@media only screen and (max-width: 640px) {
  .selectSiteWithDevice__scrollArea {
    padding: 4px 3px 3px 3px;
    height: 0;
    flex: 1 0 auto;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .selectSiteWithDevice__scrollArea::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width: 641px) {
  .selectSiteWithDevice__scrollArea {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    height: 260px;
    padding: 4px 10px 3px 3px;
    scroll-padding: 0 0 0 3px;
    margin-bottom: 43px;
  }
}

@media only screen and (max-width: 640px) {
  .selectSiteWithDevice__container {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 641px) {
  .selectSiteWithDevice__container {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 720px;
    background-color: #ffffff;
    border-radius: 20px;
    padding-bottom: 43px;
    position: relative;
    z-index: 50;
  }
}

@media only screen and (max-width: 640px) {
  .selectSiteWithDevice {
    margin: 0 16px;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
}

@media only screen and (min-width: 641px) {
  .selectSiteWithDevice {
    width: 720px;
    box-sizing: border-box;
    margin-left: 60px;
    margin-right: 63px;
    width: 720px;
    box-sizing: border-box;
    background-color: #fff;
    padding-left: 63px;
    padding-right: 63px;
    padding-top: 20px;
  }
}

.selectSiteWithDevice__header {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  width: 375px;
  min-height: 120px;
  background-color: #089bab;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

@media only screen and (min-width: 641px) {
  .selectSiteWithDevice__header {
    flex-direction: row;
    align-items: flex-start;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 141px;
    width: inherit;
    padding-top: 64px;
    padding-left: 60px;
    padding-right: 60px;
    box-sizing: border-box;
  }
}

.selectSiteWithDevice__headerTitle {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
  line-height: 33px;
}

@media only screen and (min-width: 641px) {
  .selectSiteWithDevice__headerTitle {
    margin: 0;
    padding: 0 0 0 15px;
    font-size: 34px;
    font-weight: 500;
    line-height: 23px;
  }
}

.selectSiteWithDevice__backArrow:hover {
  cursor: pointer;
}

.selectSiteWithDevice__closeIcon {
  fill: #fff;
  height: 20.7px;
  width: 19.5px;
  position: absolute;
  right: 45px;
  top: 35px;
  mix-blend-mode: normal;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.selectSiteWithDevice__closeIcon:hover {
  cursor: pointer;
}

.selectSiteWithDevice__heading {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
}

@media only screen and (max-width: 640px) {
  .selectSiteWithDevice__heading {
    margin-top: 0;
    margin-bottom: 12px;
    line-height: 1;
  }
}

@media only screen and (min-width: 641px) {
  .selectSiteWithDevice__heading {
    margin-top: 28px;
  }
}

.selectSiteWithDevice__list {
  padding-left: 0;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 640px) {
  .selectSiteWithDevice__list {
    margin-top: 0;
  }
}

@media only screen and (min-width: 641px) {
  .selectSiteWithDevice__list {
    margin-top: 16px;
    margin-bottom: 0;
  }
}

.selectSiteWithDevice__list--withScroll {
  margin-top: 0;
  padding-bottom: 0;
}

@media only screen and (min-width: 641px) {
  .selectSiteWithDevice__list--withScroll {
    margin-top: 0;
    padding-bottom: 0;
  }
}

.selectSiteWithDevice__item {
  display: flex;
  align-items: center;
  list-style-type: none;
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
  padding-left: 14px;
}

.selectSiteWithDevice__item--withScroll {
  margin-bottom: -15px;
}

@media only screen and (min-width: 641px) {
  .selectSiteWithDevice__item:hover {
    cursor: pointer;
  }

  @media only screen and (min-width: 641px) {
    .selectSiteWithDevice__item:last-child {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 640px) {
  .selectSiteWithDevice__headingGroup {
    margin-left: 18px;
    margin-top: 13px;
    margin-bottom: 24px;
  }
}

@media (max-width: 640px) {
  .selectSiteWithDevice__backArrow {
    padding-right: 15px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .selectSiteWithDevice__goBackTitle {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(18, 26, 45, 0.5);
    line-height: 1;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 640px) {
  .selectSiteWithDevice__backArrow__icon {
    padding-right: 4px;
  }
}

.selectSiteWithDevice__title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #121a2d;
}

@media (max-width: 640px) {
  .selectSiteWithDevice__title {
    font-size: 20px;
    line-height: 1;
    margin: 24px 0 0 2px;
    padding: 0;
  }
}

.selectSiteWithDevice__modal {
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.selectSiteWithDevice__noSitesFound {
  display: flex;
  justify-content: center;
  width: 720px;
  background-color: #fff;
  padding: 20px 0 20px 0;
  border-radius: 10px;
}
