.input {
  font-family: "Poppins", sans-serif;
  color: #121a2d;
}

.input::placeholder {
  font-size: 15px;
  line-height: 22px;
  color: #7b818b;
  opacity: 0.5;
}

@media only screen and (max-width: 640px) {
  .input {
    margin: 0;
    padding-left: 32px;
    height: 37px;
    width: 100%;
    background-color: #fafcfe;
    border: 1px solid #d2d5e1;
    border-radius: 10px;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    outline: none;
  }
}

@media only screen and (min-width: 641px) {
  .input {
    width: inherit;
    height: 60px;
    font-size: 18px;
    line-height: 27px;
    padding-left: 62px;
    background-color: #fdfdff;
    border: 2px solid #089bab;
    border-radius: 8px;
    box-sizing: border-box;
  }
}

.inputContainer {
  display: flex;
  align-items: center;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .inputContainer {
    width: 100%;
    margin-top: 12px;
    /* margin-left: 18px;
    margin-right: 18px; */
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 641px) {
  .inputContainer {
    width: 100%;
    margin: 0 auto;
    margin-top: 36px;
  }
}

.magnifyingGlassIcon {
  position: absolute;
  /* centered vertically by flex align-items */
  left: 10px;
  width: 16px;
  height: 16px;
}

@media only screen and (min-width: 641px) {
  .magnifyingGlassIcon {
    top: 20px;
    left: 25px;
    width: 23px;
    height: 23px;
  }
}

.defaultStyles::placeholder {
  font-size: 15px;
  line-height: 22px;
  color: #7b818b;
  opacity: 0.5;
}
