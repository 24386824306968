.TimeCodes__antButtonContainer button {
    font-family: "Poppins", sans-serif;
  }
  
  .TimeCodes__antButtonContainer .ant-btn-default {
    display: flex;
    justify-content: flex-start;
    color: rgb(125, 144, 160) !important;
    font-size: 16px !important;
    font-weight: 300;
    border: 1px solid rgb(125, 144, 160) !important;
  }