.DeviceCoverage__row {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    margin-left: 20px;
}

.DeviceCoverage__deviceContainer {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border: 1px solid #e2e5ed;
    border-radius: 6px;
    width: 100px;
    margin-right: 10px;
    box-sizing: border-box;
    padding: 10px;
}

.DeviceCoverage__deviceContainer h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-transform: capitalize;
    line-height: 24px;
    color: #3e3f42;
    text-align: center;
    margin: 0;
}

.DeviceCoverage__deviceContainer h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    text-transform: capitalize;
    line-height: 12px;
    color: #3e3f42;
    text-align: center;
    margin: 5px 0 0 0;
}

