.Report2Functions__pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.Report2Functions__headerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  /* margin-bottom: 14px; */
}

.Report2Functions__header {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #454b60;
  /* padding-bottom: 24px; */
}

.Report2Functions__buttonGroup {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.Report2Functions__functionsContainer {
  display: flex;
  justify-content: center;
}

.Report2Functions__functionFieldsContainer {
  box-sizing: border-box;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* height: 100%; */
  width: 254px;
  padding: 0;
  margin-top: 0;
  margin-bottom: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px 25px 10px 25px;
}

.Report2Functions__functionFieldsContainer--noOverflow {
  height: 100%;
}

@media (max-width: 1007px) {
  .Report2Functions__functionFieldsContainer {
    padding: 10px 4px 10px 4px;
  }
}

@media (min-width: 720px) {
  .Report2Functions__functionFieldsContainer {
    width: 250px;
  }
}

@media (min-width: 850px) {
  .Report2Functions__functionFieldsContainer {
    width: 300px;
  }
}

@media (min-width: 1008px) {
  .Report2Functions__functionFieldsContainer {
    width: 350px;
  }
}

@media (max-width: 1007px) {
  .Report2Functions__functionFieldsContainer:first-child {
    margin-right: 20px;
  }
}

@media (min-width: 1008px) {
  .Report2Functions__functionFieldsContainer:first-child {
    margin-right: 38px;
  }
}

.Report2Functions__functionFieldsAll {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 35px;
  background-color: #ffffff;
  margin-bottom: 6px;
  border: 1px solid #e8ecef;
  box-shadow: 0 5px 14px rgb(120 146 141 / 5%);
  border-radius: 2px;
  padding: 0 7px;
}

.Report2Functions__functionFieldsAll--function {
  min-height: 92px;
}

@media (max-width: 1007px) {
  .Report2Functions__functionFieldsAll {
    box-sizing: border-box;
  }
}

.Report2Functions__functionFieldsAll--showMouseCursor {
  cursor: pointer;
}

.Report2Functions__functionFieldsAll--selected {
  border: 1px solid #089bab;
}

.Report2Functions__functionFieldsGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Report2Functions__functionFields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* height: 35px; */
  height: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #089bab;
}

.Report2Functions__functionFields--createdFunctions {
  padding-top: 15px;
  padding-bottom: 10px;
}

.Report2Functions__functionFieldsHeading {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: var(--black);
  margin: 0 0 0 -6px;
  padding: 0;
}

@media (max-width: 1007px) {
  .Report2Functions__functionFieldsHeading {
    margin-left: 5px;
  }
}

.Report2Functions__operatorsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: -170px;
  top: 0;
  min-width: 60px;
  margin-right: 10px;
  background: #ffffff;
  box-shadow: 0 2px 16px rgb(153 155 168 / 34%);
  border-radius: 10px;
  box-sizing: border-box;
}

.Report2Functions__operationsCard {
  /* position: absolute; */
  right: -460px;
  top: 0;
  width: 450px;
  margin-right: 10px;
  background: #ffffff;
  box-shadow: 0 2px 16px rgb(153 155 168 / 34%);
  border-radius: 10px;
  box-sizing: border-box;
}

.Report2Functions__operationsCard--hide {
  display: none;
}

.Report2Functions__functionBuilderCardContainer {
  background: #ffffff;
  box-shadow: 0 2px 16px rgb(153 155 168 / 34%);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
}

.Report2Functions__functionBuilderCardHeading {
  font-size: 16px;
  color: var(--black);
}

.Report2Functions__functionBuilderCardOperatorContainerLabels {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.Report2Functions__functionBuilderCardOperatorContainerValues {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}

.Report2Functions__functionBuilderNameInput {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
  border: none;
  border-bottom: 1px solid var(--blackLight);
  outline: none;
  flex: 1;
  flex-direction: row;
  max-height: 36px;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
}

.Report2Functions__functionBuilderCardDataContainerLabel {
  font-size: 12px;
  color: var(--green);
  width: 120px;
}

.Report2Functions__functionBuilderCardDataContainerLabel--field1 {
  margin-right: 50px;
}

.Report2Functions__functionBuilderCardDataContainerLabel--field2 {
  margin-left: 20px;
}

.Report2Functions__functionBuilderCardDataContainerValue {
  font-size: 12px;
  color: var(--black);
  width: 120px;
}

.Report2Functions__functionBuilderCardDataContainerValue--field1 {
  margin-right: 50px;
}

.Report2Functions__functionBuilderCardDataContainerValue--field2 {
  margin-left: 20px;
}

.Report2Functions__trashIconContainer {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 8px;
  margin-left: 12px;
  cursor: pointer;
  transition: ease-in-out all 0.3s;
}

.Report2Functions__trashIconContainer:active,
.Report2Functions__trashIconContainer:hover {
  background-color: #ffcccb;
}

.Report2Functions__trashIconContainer > svg {
  width: 14px;
  height: 14px;
  cursor: pointer;
  fill: rgb(8, 155, 171);
  margin: 0;
}

.Report2Functions__functionIconContainer {
  padding-top: 8px;
  padding-bottom: 8px;
}

.Report2Functions__selectStatement {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--black);
  padding: 0;
  margin: 10px 0 31px 0;
}

.Report2Functions__toggleSwitchesContainer {
  display: flex;
  margin-bottom: 15px;
}

.Report2Functions__toggleSwitchGroup {
  display: flex;
  align-items: center;
  padding-left: 30px;
}

@media (min-width: 1008px) {
  .Report2Functions__toggleSwitchGroup {
    padding-right: 30px;
  }
}

.report2calcs__toggleswitchgroup:not(last-child) {
  margin-bottom: 10px;
}

.Report2Functions__toggleSwitchLabel {
  font-size: 12px;
  color: var(--black);
  margin: 0 10px 0 0;
  padding: 0;
  line-height: 1;
}

.Report2Functions__previewButtonContainer {
  margin-bottom: 34px;
}

.Report2Function__scroll {
  display: flex;
  height: 100%;
  overflow: auto;
}
