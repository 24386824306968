.pharmacySites__row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.pharmacySites__row--spaceBetween {
  justify-content: space-between;
}

.pharmacySites__row--addNewSites {
  margin-top: 3px;
}

.pharmacySites__row--borderBottom {
  border-bottom-color: #089bab;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.pharmacySites__row--marginTop {
  margin-top: 24px;
}

.pharmacySites__scroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

@media (max-width: 640px) {
  .pharmacySites__scroll {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }
}

@media (min-width: 641px) {
  .pharmacySites__scroll {
    padding-right: 10px;
  }
}

@media (max-width: 640px) {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .pharmacySites__scroll:-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 640px) {
  .pharmacySites__actionsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    position: relative;
    height: 30px;
  }
}

.pharmacySites__dataCardsContainer {
  height: 100%;
  overflow: scroll;
  overflow-x: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-top: 18px;
  margin-bottom: auto;
}

.pharmacySites__dataCardsContainer:last-child {
  margin-bottom: auto;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.pharmacySites__dataCardsContainer::-webkit-scrollbar {
  display: none;
}

@media (max-width: 640px) {
  .pharmacySites__createButtonContainer {
    margin: 0 24px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .pharmacySites__createButtonContainer {
    margin: 20px 0 50px 0;
  }
}

@media (min-width: 1008px) {
  .pharmacySites__createButtonContainer {
    margin: 70px 0 100px 0;
  }
}

/*************************************************************/

.pharmacySites__col {
  display: flex;
  flex-direction: column;
}

.pharmacySites__col--width100Per {
  width: 100%;
  flex: 1;
  justify-content: flex-end;
}

.pharmacySites__listItem {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid #e8ecef;
  box-sizing: border-box;
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
}

@media (min-width: 641px) and (max-width: 1007px) {
  .pharmacySites__listItem {
    height: 48px;
    min-height: 48px;
  }
}

@media (min-width: 1008px) {
  .pharmacySites__listItem {
    min-height: 80px;
    height: 80px;
  }
}

.pharmacySites__listItem--lightGray {
  background-color: lightgray;
  opacity: 0.5;
}

.pharmacySites__listItem--leftMargin20 {
  margin-left: 20px;
}

.pharmacySites__listItem--siteEnabled {
  background-color: lightgray;
  opacity: 0.5;
}

.pharmacySites__listItemText {
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  color: #121a2d;
  padding: 0;
  margin: 0;
  /* white-space: nowrap;
  text-overflow: ellipsis; */
  /* overflow-wrap: anywhere; */
  overflow-wrap: normal;
}

@media (min-width: 640px) {
  .pharmacySites__listItemText {
    font-size: 14px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .pharmacySites__listItemText {
    font-size: 13px;
  }
}

@media (min-width: 1008px) {
  .pharmacySites__listItemText {
    font-size: 14px;
  }
}

.pharmacySites__text {
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #121a2d;
  margin-left: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .pharmacySites__text {
    font-size: 13px;
  }
}

@media (min-width: 1008px) {
  .pharmacySites__text {
    font-size: 14px;
  }
}

.pharmacySites__text--leftMargin20 {
  margin-left: 20px;
}

.pharmacySites__text--rightMargin20 {
  margin-right: 20px;
}

.pharmacySites__text--alignRight {
  text-align: right;
}

@media (max-width: 640px) {
  .pharmacySites__tableHeader {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .pharmacySites__tableHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    border-bottom: 2px solid #089bab;
    margin-left: 1px;
    padding-bottom: 13px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .pharmacySites__siteName {
    min-width: 150px;
    width: 150px;
    margin-right: 30px;
    margin-left: 20px;
  }

  .pharmacySites__location {
    min-width: 220px;
    width: 220px;
    margin-right: 10px;
  }

  .pharmacySites__phoneNumber {
    min-width: 120px;
    width: 120px;
    margin-right: 10px;
  }

  .pharmacySites__type {
    min-width: 130px;
    width: 130px;
    margin-right: 10px;
  }

  .pharmacySites__actionsHeader {
    text-align: right;
    margin-right: 20px;
  }

  .pharmacySites__actionsContainer {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    text-align: right;
    position: relative;
    padding-right: 14px;
  }
}

@media (min-width: 1008px) {
  .pharmacySites__tableHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 2px solid #089bab;
    padding-bottom: 14px;
  }
}

.pharmacySites__overflowHidden {
  overflow: hidden;
  white-space: nowrap;
}

@media (min-width: 1008px) {
  .pharmacySites__siteName {
    min-width: 170px;
    width: 170px;
    margin-right: 30px;
    margin-left: 20px;
  }

  .pharmacySites__location {
    min-width: 300px;
    width: 300px;
    margin-right: 30px;
  }

  .pharmacySites__phoneNumber {
    min-width: 220px;
    width: 220px;
    margin-right: 30px;
  }

  .pharmacySites__type {
    min-width: 170px;
    width: 170px;
    margin-right: 30px;
  }

  .pharmacySites__actionsHeader {
    text-align: right;
    margin-right: 20px;
  }

  .pharmacySites__actionsContainer {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    text-align: right;
    position: relative;
    margin-right: 30px;
  }
}

.pharmacySites__actionsContainer:hover {
  cursor: pointer;
}

.pharmacySites__popupContainer {
  position: relative;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .pharmacySites__popupContainer {
    top: var(--popupTopMd);
    right: var(--popupRightMd);
  }
}

@media (min-width: 1008px) {
  .pharmacySites__popupContainer {
    top: var(--popupTopLg);
    right: var(--popupRightLg);
  }
}

@media only screen and (max-width: 640px) {
  .pharmacySites__errorBannerContainer {
    margin: 0 10px 10px 10px;
    position: relative;
    z-index: 210;
  }
}

@media only screen and (min-width: 641px) {
  .pharmacySites__errorBannerContainer {
    width: 100%;
    margin-bottom: 12px;
    z-index: 250;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1007px) {
  .pharmacySites__errorBannerContainer {
    margin-top: 35px;
  }
}

@media only screen and (min-width: 1008px) {
  .pharmacySites__errorBannerContainer {
    margin-top: 70px;
  }
}

@media (max-width: 640px) {
  .pharmacySites__searchBarContainer {
    position: absolute;
    top: 0;
    width: 78%;
    padding-left: 32px;
    box-sizing: border-box;
    z-index: 5;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .pharmacySites__searchBarContainer {
    flex-grow: 1;
    margin-right: 20px;
  }
}

@media (min-width: 1008px) {
  .pharmacySites__searchBarContainer {
    width: 100%;
  }
}
