.ImageProperties__container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 6px;
}

/* general input container */
.ImageProperties__inputContainer {
  width: 54px;
}

/* general input */
.ImageProperties__inputContainer input {
  text-align: right;
  padding-right: 5px;
}

/* general boxes container */
.ImageProperties__boxesContainer {
  display: flex;
  align-items: center;
}

.ImageProperties__boxesContainer--textStyles {
  margin-top: 5px;
}

/* position */
.ImageProperties__positionContainer {
  display: flex;
  justify-content: space-between;
}

.ImageProperties__xBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
}

.ImageProperties__xPositionInputContainer {
  width: 54px;
}

.ImageProperties__yBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ImageProperties__yPositionInputContainer {
  width: 54px;
}

/* size */
.ImageProperties__sizeContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.ImageProperties__widthBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
}

.ImageProperties__widthInputContainer {
  width: 54px;
}

.ImageProperties__heightBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ImageProperties__heightInputContainer {
  width: 54px;
}

/* general p */
.ImageProperties__container p {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  padding: 0;
  margin: 0;
  line-height: 1;
  color: var(--black);
}

/* general p span */
.ImageProperties__container p span {
  display: block;
  font-size: 10px;
  margin-top: 3px;
}

.ImageProperties__positionContainer p,
.ImageProperties__sizeContainer p {
  margin-top: 8px;
}

.ImageProperties__trashIconContainer {
  position: absolute;
  right: 8px;
  top: -43px;
  cursor: pointer;
}

.ImageProperties__trashIconContainer svg {
  fill: #d9534f;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in-out;
}

.ImageProperties__trashIconContainer:hover svg {
  transform: scale(1.1);
}

.ImageProperties__checkboxesAndTextContainer {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.ImageProperties__checkboxContainer {
  width: 18px;
  height: 1px;
  display: flex;
  align-items: center;
  margin-right: 3px;
  cursor: pointer;
}

.ImageProperties__checkboxContainer svg {
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0;
}
