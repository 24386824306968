.scanInput__container {
  display: flex;
  align-items: center;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .scanInput__container {
    margin-top: 12px;
    margin-left: 18px;
    margin-right: 18px;
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 641px) {
  .scanInput__container {
    width: 100%;
    margin-top: 36px;
  }
}

.scanInput__container--white {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
}

@media only screen and (min-width: 641px) {
  .scanInput__container--white {
    padding: 15px 30px;
  }
}

.scanInput__field::placeholder {
  font-size: 15px;
  line-height: 22px;
  color: #7b818b;
  opacity: 0.5;
}

@media only screen and (max-width: 640px) {
  .scanInput__field {
    margin: 0;
    padding-left: 32px;
    width: inherit;
    height: 37px;
    width: 100%;
    color: #121a2d;
    background-color: #fafcfe;
    border: 1px solid #d2d5e1;
    border-radius: 10px;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    outline: none;
  }
}

@media only screen and (min-width: 641px) {
  .scanInput__field {
    margin: 0;
    width: inherit;
    color: #121a2d;
    background-color: #fdfdff;
    border: 2px solid #089bab;
    border-radius: 8px;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    height: 60px;
    font-size: 18px;
    line-height: 27px;
    padding-left: 62px;
  }
}

.scanInput__magnifyingGlassIcon {
  position: absolute;
  /* centered vertically by flex align-items */
  left: 10px;
  width: 16px;
  height: 16px;
}

@media only screen and (min-width: 641px) {
  .scanInput__magnifyingGlassIcon {
    top: 20px;
    left: 25px;
    width: 23px;
    height: 23px;
  }
}

.backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(152, 169, 188, 0.3);
  backdrop-filter: blur(46.2108px);
}

.modal {
  position: fixed;
  top: 21vh;
  width: 100%;
  background-color: transparent;
  z-index: 25;
}

.addAP__errorBannerContainer {
  position: absolute;
  width: 1000px;
}

/* .scanInput__datePickerButton {
  padding-top: 14px;
  margin-left: 150px;
} */
