.pickList__mainHeading {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
  border-bottom: 2px solid rgb(8, 155, 171);
}

.pickList__mainColumnName {
  font-weight: 500;
  font-size: 14px;
  color: rgb(18, 26, 45);
  margin: 0;
  padding: 0;
}

.pickList__mainColumnName--item1 {
  margin-left: 42px;
  margin-right: 264px;
}

.pickList__mainColumnName--item2 {
  margin-right: 278px;
}

.pickList__mainColumnName--item3 {
  margin-right: 260px;
}

.pickList__mainColumnName--item4 {
  margin-right: 150px;
}

.pickList__mainColumnName--item5 {
  width: 100%;
  text-align: right;
  margin-right: 70px;
}

.pickList__mainColumnVal--item1 {
  margin-left: 40px;
  margin-right: 50px;
  min-width: 150px;
  white-space: nowrap;
}

.pickList__mainColumnVal--item2 {
  margin-right: 50px;
  min-width: 406px;
  white-space: nowrap;
}

.pickList__mainColumnVal--item3 {
  margin-right: 116px;
  min-width: 70px;
  text-align: right;
  white-space: nowrap;
}

.pickList__mainColumnVal--item4 {
  margin-right: 50px;
  text-align: right;
  min-width: 70px;
  white-space: nowrap;
}
