.TrayCheckViewContainer {
  gap: 30px;
  display: flex;
  min-width: 100%;
  padding-top: 100px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.TrayCheckTitleContainer {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.TrayCheckTitle {
  font-size: 22px;
  font-weight: 500;
}

.TrayDataHeader {
  width: 100%;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
}

.TrayInfoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.TrayInfoTable {
  font-size: 12;
  border-collapse: collapse;
}

.TrayInfoLabelCell {
  border: none;
  text-align: right;
}

.TrayInfoCell {
  border: none;
  font-weight: bold;
  text-align: left !important;
}

.MedicineInfoContainer {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
}

.MedicineInfoTableContainer {
  padding: 8px;
  border-radius: 5px;
  width: max-content;
  height: max-content;
}

.MedicineInfoTable {
  font-size: 12;
  border-collapse: collapse;
}

.MedicineInfoLabelCell {
  text-align: right;
}

.MedicineInfoCell {
  font-weight: bold;
  text-align: left !important;
}

.MedicineImageContainer {
  max-width: 15%;
  margin-right: 20px;
}

.MedicineImage {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: 134px;
  width: 134px;
  height: 120px;
  border: 1px solid #A7BBCF;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 16px;
  object-fit: contain;
  overflow: hidden;
}

.NoImageContainer {
  width: 134px;
  height: 120px;
  display: flex;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}

.TrayDataContainer {
  gap: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ButtonsContainer {
  display: flex;
  align-items: center;
}

.TotalQtyButtonContainer {
  width: 30%;
  display: flex;
  justify-content: flex-start;
}

.MedTagContainer {
  gap: 10px;
  width: 40%;
  display: flex;
  justify-content: center;
}

.ApproveButtonContainer {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}