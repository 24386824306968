.product {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.product__stockLocationName {
  margin-top: 30px;
}

.product__imageContainer {
  width: 57px;
  overflow: none;
  margin-left: 10px;
  flex-shrink: 0;
  flex-grow: 0;
}

.product__imageContainer--default {
  border: 2px solid #a7bbcf;
  box-sizing: border-box;
  padding: 1.5px;
}

.product__image {
  width: 57px;
  height: auto;
  max-height: 57px;
}

.product__productInfo {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin-left: 13px;
  margin-right: 5px;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 640px) {
  .product__productInfo {
    overflow: hidden;
  }
}

.product__description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #363f4c;
}

.product__packageDescription,
.product__lotNumberDesc,
.product__expirationDesc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0px;
  color: #87909e;
}

.product__lotNumberDesc {
  margin-right: 15px;
}

.product__lotNumberDesc--heading,
.product__expirationDesc--heading {
  font-weight: 500;
  padding-right: 5px;
}

.product__lotNumberAndExpirationGroup {
  display: flex;
}
