.facilitiesDataCard__container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #e8ecef;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
}

.facilitiesDataCard__rowGroup {
  display: flex;
  justify-content: flex-start;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

@media (max-width: 470px) {
  .facilitiesDataCard__rowGroup:nth-child(3) {
    flex-direction: column;
  }
}

.facilitiesDataCard__rowGroup:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.facilitiesDataCard__dataList {
  padding: 0;
  margin: 10px;
}

.facilitiesDataCard__colGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  color: #121a2d;
  font-family: "Poppins", sans-serif;
  line-height: 16px;
}

.facilitiesDataCard__colGroup--1 {
  width: 100%;
  margin-right: 20px;
}

.facilitiesDataCard__colGroup--2 {
  width: 150px;
}

@media (max-width: 470px) {
  .facilitiesDataCard__emailGroup {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

.facilitiesDataCard__dataItem {
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin-left: 0 !important;
  color: #121a2d;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

.facilitiesDataCard__dataItem:not(:last-child) {
  margin-bottom: 4px;
}

.facilitiesDataCard__heading {
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 10px;
}

.facilitiesDataCard__value {
  font-size: 13px;
  font-weight: 300;
  overflow: hidden;
  width: 100%;
}

.facilitiesDataCard__actions {
  position: absolute;
  top: 0;
  right: 0;
}

.facilitiesDataCard__actions:hover {
  cursor: pointer;
}
