.TimeCodes__row {
  display: flex;
}

@media (max-width: 640px) {
  .TimeCodes__row {
    flex-direction: column;
    width: 100%;
  }
}

@media (min-width: 641px) {
  .TimeCodes__row {
    flex-direction: row;
  }
}

.TimeCodes__col {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

@media (max-width: 640px) {
  .TimeCodes__col {
    align-items: flex-start;
    padding-left: 0;
    /* padding-right: 12px; */
    box-sizing: border-box;
  }
}

@media (min-width: 641px) {
  .TimeCodes__col {
    height: auto;
  }
}

.TimeCodes__timeCodesContainer {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.TimeCodes__timePickerContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.TimeCodes__timePickerContainer:nth-child(2) {
  margin-left: 8px;
}

.TimeCodes__timeLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--green);
  padding: 0 0 10px 10px;
  margin: 0;
  line-height: 1;
}

.TimeCodes__antButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  cursor: pointer;
  margin-top: 18px;
  margin-right: 8px;
}

.TimeCodes__addIcon {
  font-size: 22px;
  margin-top: 2px;
}

@media (max-width: 1007px) {
  .TimeCodes__remove_button {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.TimeCodes__remove_button {
  width: 40px;
  flex: 0 0 40px;
}

.TimeCodes__removeIconContainer {
  position: absolute;
  top: 30px;
}

.TimeCodes__inputGroup {
  display: flex;
  flex-direction: column;
  position: relative;
}

.TimeCodes__errorMessage {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  color: #dc3545;
  position: absolute;
  padding: 0;
  margin: 0;
  line-height: 1;
}


.TimeCodes__errorMessage--label {
  top: 52px;
  left: 5px;
}

.TimeCodes__errorMessage--time {
  top: 60px;
  left: 5px;
}

.TimeCodes__errorTotal {
  font-family: "Poppins", sans-serif;
  color: #dc3545;
  font-size: 12px;
  margin-bottom: 10px;
}
