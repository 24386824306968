.container-000 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message-000 {
  margin: 8px 0 0 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #252631;
}
