@media only screen and (max-width: 640px) {
  .stockHeader__headingGroup {
    margin-left: 18px;
    margin-top: 13px;
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 641px) {
  .stockHeader__headingGroup {
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
  }
}

/* @media only screen and (min-width: 641px) and (max-width: 1007px) {
  .stockHeader__headingGroup {
    margin-top: 34px;
  }
} */

@media only screen and (min-width: 641px) {
  .stockHeader__headingGroup {
    margin-top: 64px;
  }
}

.stockHeader__heading {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
  line-height: 33px;
}

@media only screen and (min-width: 641px) {
  .stockHeader__heading {
    font-size: 34px;
    font-weight: 500;
    line-height: 27px;
  }
  .stockHeader__closeModalIcon {
    fill: #fff;
    height: 20.7px;
    width: 19.5px;
    position: absolute;
    right: -34px;
    top: -34px;
    mix-blend-mode: normal;
    cursor: pointer;
  }
}

.stockHeader__backArrow {
  padding-right: 15px;
}

@media (max-width: 640px) {
  .stockHeader__backArrow {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 641px) {
  .stockHeader__backArrow:hover {
    cursor: pointer;
  }
}

@media (max-width: 640px) {
  .stockHeader__goBackTitle {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(18, 26, 45, 0.5);
    line-height: 1;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 640px) {
  .stockHeader__backArrow__icon {
    padding-right: 4px;
  }
}

.stockHeader__title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #121a2d;
}

@media (max-width: 640px) {
  .stockHeader__title {
    font-size: 20px;
    line-height: 1;
    margin: 24px 0 0 2px;
    padding: 0;
  }
}
