.EV54Dashboard__graphsGroup {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 36px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.EV54Dashboard__graphsGroup:not(:last-child) {
  margin-bottom: 40px;
}

.EV54Dashboard__graphsGroup > * {
  border: 1px solid #e8ecef;
  filter: drop-shadow(0 5px 14px rgba(120, 146, 141, 0.05));
}

.EV54Dashboard__graphContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins", sans-serif;
  width: 450px;
  height: 290px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
}

.EV54Dashboard__graphContainer--paddingTop {
  padding-top: 24px;
}

.EV54Dashboard__graphContainer--paddingBottom {
  padding-bottom: 12px;
}

.EV54Dashboard__loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 450px;
  height: 288px;
  left: 0;
  right: 0;
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #121a2d;
}

.EV54Dashboard__loader svg {
  margin-top: 40px;
}
