.Report2SelectGroupField__pageContainer {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Report2SelectGroupField__FieldsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.Report2SelectGroupField__headerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 10px;
}

.Report2SelectGroupField__header {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #454b60;
  padding-bottom: 24px;
  width: 100%;
  text-align: center;
}

.Report2SelectGroupField__selectStatement {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--green);
  max-width: 300px;
  padding-left: 8px;
}

.Report2SelectGroupField__buttonGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 110px;
}

.Report2SelectGroupField__reportCollectionsGroup {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.Report2SelectGroupField__schemasListing {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  min-width: 220px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px 15px;
  margin-right: 15px;
}

@media (min-width: 720px) {
  .Report2SelectGroupField__schemasListing {
    min-width: 250px;
    margin-right: 20px;
  }
}

@media (min-width: 850px) {
  .Report2SelectGroupField__schemasListing {
    min-width: 300px;
    margin-right: 30px;
  }
}

@media (min-width: 1008px) {
  .Report2SelectGroupField__schemasListing {
    min-width: 300px;
    margin-right: 40px;
  }
}

.Report2SelectGroupField__collectionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.Report2SelectGroupField__collectionContainer {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 7px;
  padding-left: 5px;
  padding-bottom: 5px;
}

.Report2SelectGroupField__collectionContainer:not(:last-child) {
  border-bottom: 1px dotted var(--blackLight);
}

.Report2SelectGroupField__reportConcept {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: var(--black);
}

.Report2SelectGroupField__reportConcept--active {
  font-weight: 200;
}

.Report2SelectGroupField__reportConcept--inActive {
  background-color: var(--gray);
  font-weight: 200;
}

.Report2SelectGroupField__droppedFieldGroup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 35px;
  min-height: 35px;
  background: #ffffff;
  border: 1px solid #e8ecef;
  box-shadow: 0 5px 14px rgb(120 146 141 / 5%);
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px 7px;
  margin: 0 0 6px 0;
  cursor: pointer;
}

.Report2SelectGroupField__droppedField {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #089bab;
}

.Report2SelectGroupField__dropBoxContainers {
  display: flex;
  justify-content: space-around;
  height: 100%;
}

@media (min-width: 1008px) {
  .Report2SelectGroupField__dropBoxContainers {
    justify-content: space-between;
  }
}

.Report2SelectGroupField__dropBoxContainer {
  display: flex;
  flex-direction: column;
}

.Report2SelectGroupField__schemasDropBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  min-width: 220px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px 15px;
}

@media (min-width: 720px) {
  .Report2SelectGroupField__schemasDropBox {
    min-width: 250px;
  }
}

@media (min-width: 850px) {
  .Report2SelectGroupField__schemasDropBox {
    min-width: 300px;
  }
}

@media (min-width: 1008px) {
  .Report2SelectGroupField__schemasDropBox {
    min-width: 300px;
  }
}

.Report2SelectGroupField__image {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.Report2SelectGroupField__fields {
  padding-right: 8px;
  margin-left: 5px;
}

.Report2SelectGroupField__fieldGroup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 35px;
  min-height: 35px;
  background: #ffffff;
  border: 1px solid #e8ecef;
  box-shadow: 0 5px 14px rgb(120 146 141 / 5%);
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px 7px;
  margin: 0 0 6px 0;
}

.Report2SelectGroupField__field {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #089bab;
  padding-left: 8px;
}

.Report2SelectGroupField__searchBarContainer {
  width: 100%;
  margin-bottom: 24px;
}

.Report2SelectGroupField__trashIconContainer {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8px;
  margin-left: 12px;
  cursor: pointer;
  transition: ease-in-out all 0.3s;
}

.Report2SelectGroupField__trashIconContainer:active,
.Report2SelectGroupField__trashIconContainer:hover {
  background-color: #ffcccb;
}

.Report2SelectGroupField__trashIconContainer > svg {
  width: 14px;
  height: 14px;
  cursor: pointer;
  fill: rgb(8, 155, 171);
  margin: 0;
}

.Report2SelectGroupField__previewButtonContainer {
  margin-bottom: 10px;
}

.Report2SelectGroupField__dropdownContainer {
  width: 600px;
  margin-top: 18px;
}
