@media (max-width: 640px) {
    .ChangePriority__overlay {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

@media (min-width: 641px) {
    .ChangePriority__overlay {
        position: absolute;
        min-height: 100%;
        top: 0;
        right: 0;
        background-color: rgba(152, 169, 188, 0.3);
        backdrop-filter: blur(46.2108px);
        z-index: 210;
    }
}

@media (min-width: 641px) and (max-width: 1007px) {
    .ChangePriority__overlay {
        left: -112px;
        position: absolute;
        min-height: 810px;
        height: 100%;
        top: 0;
        right: 0;
    }
}

@media (min-width: 1008px) {
    .ChangePriority__overlay {
        left: -120px; /* Needed to cover vertical menu */
    }
}

.ChangePriority__view {
    display: flex;
    position: relative;
    flex-direction: column;
}

@media (max-width: 640px) {
    .ChangePriority__view {
        height: 100%;
        color: #fff;
        margin: 12px 12px 0 12px;
    }
}

@media (min-width: 641px) {
    .ChangePriority__view {
        margin: 100px auto;
        max-height: 1080px;
        padding-right: 20px;
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 20px;
    }
}

@media (min-width: 641px) and (max-width: 1007px) {
    .ChangePriority__view {
        margin-top: 40px;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        box-sizing: border-box;
    }
}

@media (min-width: 1008px) {
    .ChangePriority__view {
        max-width: 1100px;
        padding-left: 64px;
        padding-right: 64px;
    }
}

.ChangePriority__container {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    font-family: "Poppins", sans-serif;
    color: #121a2d;
}

@media (max-width: 640px) {
    .ChangePriority__container {
        width: 100%;
        height: 100%;
        margin: 0;
    }
}

@media (min-width: 641px) and (max-width: 1007px) {
    .ChangePriority__container {
        width: 100%;
        max-width: 1000px;
        min-height: 560px;
        height: 100%;
        margin-top: 20px;
    }
}

@media (min-width: 1008px) {
    .ChangePriority__container {
        width: 1000px;
        min-height: 700px;
        height: 100%;
        margin-left: 66px;
        margin-right: 66px;
        margin-top: 64px;
    }
}

.ChangePriority__defaultProductName {
    text-align: center;
}

.ChangePriority__formCloseContainer {
    position: absolute;
    top: 35px;
    right: 36px;
}

.ChangePriority__formCloseContainer:hover {
    cursor: pointer;
}

@media (max-width: 640px) {
    .ChangePriority__header {
        margin-left: 18px;
        margin-top: 1px;
        margin-bottom: 24px;
    }
}

@media (min-width: 641px) {
    .ChangePriority__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        line-height: 1;
    }
}

@media (max-width: 640px) {
    .ChangePriority__backArrow {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 640px) {
    .ChangePriority__goBackTitle {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 400;
        color: rgba(18, 26, 45, 0.5);
        line-height: 1;
        margin: 0;
        padding: 0;
    }
}

@media (max-width: 640px) {
    .ChangePriority__backArrowIcon {
        padding-right: 4px;
    }
}

.ChangePriority__title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #121a2d;
}

@media (max-width: 640px) {
    .ChangePriority__title {
        font-size: 20px;
        line-height: 1;
        margin: 24px 0 0 2px;
        padding: 0;
    }
}

@media (min-width: 641px) and (max-width: 1007px) {
    .ChangePriority__title {
        margin: 0 0 30px 0;
        font-size: 30px;
        line-height: 1;
    }
}

@media (min-width: 1008px) {
    .ChangePriority__title {
        font-size: 34px;
        line-height: 27px;
        margin: 0;
    }
}

.ChangePriority__productSelectionContainer {
    display: flex;
    flex-direction: column;
}

@media (max-width: 640px) {
    .ChangePriority__productSelectionContainer {
        margin-top: 10px;
    }
}

@media (min-width: 641px) {
    .ChangePriority__productSelectionContainer {
        margin-top: 40px;
    }
}

.ChangePriority__basicInfo {
    font-size: 14px;
    color: #089bab;
    padding: 0;
    margin: 0;
    line-height: 1;
}

@media (max-width: 640px) {
    .ChangePriority__basicInfo:last-child {
        margin-bottom: 12px;
    }
}

@media (min-width: 641px) {
    .ChangePriority__basicInfo:not(:last-child) {
        margin-bottom: 12px;
    }
}

.ChangePriority__basicInfo span {
    color: #121a2d;
}

@media (max-width: 640px) {
    .ChangePriority__basicInfo--id {
        order: 2;
    }
    .ChangePriority__basicInfo--drugName {
        order: 1;
    }
}

@media (min-width: 641px) {
    .ChangePriority__basicInfo--id {
        order: 1;
    }
    .ChangePriority__basicInfo--drugName {
        order: 2;
    }
}

@media (max-width: 640px) {
    .ChangePriority__basicInfo--packageIdEn span {
        margin-left: 4px;
    }
    .ChangePriority__basicInfo--packageIdEs span {
        margin-left: 4px;
    }
    .ChangePriority__basicInfo--packageIdFr span {
        margin-left: 4px;
    }

    .ChangePriority__basicInfo--defaultNameEn span {
        margin-left: 0;
    }
    .ChangePriority__basicInfo--defaultNameEs span {
        margin-left: 0;
    }
    .ChangePriority__basicInfo--defaultNameFr span {
        margin-left: 0;
    }
}

@media (min-width: 641px) {
    .ChangePriority__basicInfo--packageIdEn span {
        margin-left: 65px;
    }
    .ChangePriority__basicInfo--packageIdEs span {
        margin-left: 127px;
    }
    .ChangePriority__basicInfo--packageIdFr span {
        margin-left: 80px;
    }

    .ChangePriority__basicInfo--defaultNameEn span {
        margin-left: 10px;
    }
    .ChangePriority__basicInfo--defaultNameEs span {
        margin-left: 10px;
    }
    .ChangePriority__basicInfo--defaultNameFr span {
        margin-left: 10px;
    }
}

.ChangePriority__footer {
    display: flex;
    flex: 1;
    align-items: flex-end;
}

@media (max-width: 640px) {
    .ChangePriority__footer {
        justify-content: center;
        margin-top: 25px;
        margin-bottom: 30px;
    }
}

@media (max-width: 640px) {
    .ChangePriority__footer--noEquivalencies {
        justify-content: flex-start;
    }
}

@media (min-width: 641px) {
    .ChangePriority__footer {
        justify-content: flex-end;
        margin-top: 38px;
        margin-left: 50px;
        margin-right: 42px;
        margin-bottom: 51px;
    }
}

@media (max-width: 640px) {
    .ChangePriority__dropdown {
        margin-top: 20px;
    }
}

@media (min-width: 641px) {
    .ChangePriority__dropdown {
        width: 400px;
        margin-top: 40px;
    }
}

@media (max-width: 640px) {
    .ChangePriority__noEquivalenciesMessage {
        margin-top: 10px;
    }

    .ChangePriority__noEquivalenciesMessage p {
        font-size: 18px;
    }
}

@media (min-width: 641px) {
    .ChangePriority__noEquivalenciesMessage {
        margin-top: 30px;
    }

    .ChangePriority__noEquivalenciesMessage p {
        font-size: 24px;
    }
}

.ChangePriority__errorBannerContainer {
    margin-top: 20px;
}

.ChangePriority__column {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}
