@media only screen and (max-width: 640px) {
  .buttonContainer {
    margin-top: auto;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 18px;
    text-align: center;
  }
}

@media only screen and (min-width: 641px) {
  .buttonContainer {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 43px;
    margin-bottom: 43px;
  }
}
