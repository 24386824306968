@media (max-width: 640px) {
  .navBar__container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 0 0 30px;
    background-color: var(--green);
    width: 234px;
    min-height: 100%;
  }
  .navBar__container--camera {
    background-color: #fff;
    z-index: 220;
    width: 100vw;
    padding: 0;
    box-sizing: border-box;
  }
}

@media (min-width: 640px) {
  .navBar__container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px 0 52px 25px;
    background-color: var(--green);
    width: 100%;
    box-sizing: border-box;
  }
}

@media (min-width: 641px) {
  .navBar__container {
    height: 100%;
    position: static;
    align-items: center;
    padding: 0;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .navBar__container {
    width: 70px;
    min-width: 70px;
    min-height: 660px;
  }
}

@media (min-width: 1008px) {
  .navBar__container {
    width: 120px;
    min-width: 120px;
    min-height: 660px;
  }
}

.navBar__top {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: var(--green);
}

@media (max-width: 640px) {
  .navBar__top {
    margin-top: 0px;
  }
}

.navBar__top--hide {
  display: none;
}

.navBar__bottom {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: var(--green);
}

.navBar__profile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 26px;
  cursor: pointer;
}

@media (max-width: 640px) {
  .navBar__profile {
    align-self: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 38px;
  }
}

@media (min-width: 640px) and (max-width: 1007px) {
  .navBar__profile {
    width: 100%;
    background-color: var(--green);
    justify-content: flex-end;
    height: 100%;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1008px) {
  .navBar__profile {
    margin-top: 90px;
    margin-bottom: 10px;
  }
}

.navBar__profilePic {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  cursor: pointer;
}

@media (max-width: 640px) {
  .navBar__profilePic {
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
}

.navBar__text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #ffffff;
  margin: 10px 0;
}

@media (max-width: 640px) {
  .navBar__text {
    margin-left: 12px;
    font-size: 16px;
    line-height: 1;
  }
}

.navBar__userGroup {
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  outline: none;
}

.navBar__userGroup:focus {
  border: 1px solid white;
  border-radius: 8px;
}

@media (max-width: 640px) {
  .navBar__userGroup {
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;
  }
}

@media (min-width: 640px) {
  .navBar__userGroup {
    flex-direction: column;
  }
}

@media (max-width: 640px) {
  .navBar__logoContainer {
    position: absolute;
    top: 30px;
    left: 0;
    padding-left: 48px;
    padding-bottom: 8px;
    width: 234px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(236, 239, 248, 0.3);
  }
}

.navBar__container {
  margin: 0;
}

.navBar__popup {
  position: absolute;
  left: 0;
}

@media (max-width: 640px) {
  .navBar__popup {
    position: absolute;
    left: 40px;
    top: 0;
  }
}

@media (min-width: 1007px) {
  .navBar__popup {
    bottom: 280px;
    left: 40px;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .navBar__popup {
    bottom: 206px;
    left: 15px;
  }
}

@media (max-width: 640px) {
  .navBar__cameraIconContainer {
    width: 20px;
    padding: 4px;
  }
  .navBar__cameraIconContainer:hover {
    cursor: pointer;
  }
  .navBar__cameraIcon {
    color: #fff;
  }
}
