.HeaderForMobile__container {
  display: flex;
  align-items: center;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 65px;
}

.HeaderForMobile__picFrameHeaderContainer {
  position: absolute;
  top: 18px;
  right: 20px;
  z-index: 12;
}

@media (max-width: 640px) {
  .HeaderForMobile__picFrameHeaderContainer {
    z-index: 5;
  }
}

.HeaderForMobile__picFrameHeader {
  width: 28px;
  height: 28px;
  border-radius: 30px;
}

.HeaderForMobile__title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 1;
  color: #121a2d;
  margin: 0;
  margin-left: 60px;
}

.HeaderForMobile__smHeaderSearchBarContainer {
  width: 76%;
  margin-top: -13px;
  margin-left: 0px;
}

.picFrameContainer svg {
  max-width: 28px;
  max-height: 28px;
}
