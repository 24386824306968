.pageTitle {
    font-family: "Poppins", sans-serif;
    line-height: 1;
    font-weight: 500;
    font-size: 24px;
    color: var(--black);
}

.col {
    display: flex;
    flex-direction: column;
    width: 100%;
}