.CreateStockLocations__row {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
}

.CreateStockLocations__row h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #089bab;
  margin: 0;
}

.CreateStockLocations__row span {
  display: block;
}

.CreateStockLocations__row input[type="time"] {
  margin: 0 5px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #121a2d;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #121a2d;
  outline: none;
}

/*.CreateStockLocations__view {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid white;
}*/

.CreateStockLocations__view {
  display: flex;
  flex-direction: column;
  margin: 100px auto;
  background-color: white;
  border: 1px solid white;
  border-radius: 20px;
  z-index: 50;
}

@media (min-width: 641px) and (max-width: 1007px) {
  .CreateStockLocations__view {
    height: 600px;
    margin: 40px 0 auto auto;
  }
}

@media (min-width: 1008px) {
  .CreateStockLocations__view {
    width: 1000px;
    height: 739px;
  }
}

.CreateStockLocations__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 66px;
  height: 100px;
}

.CreateStockLocations__body {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  margin-right: 66px;
  margin-left: 66px;
}

.CreateStockLocations__title {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 27px;
  color: #121a2d;
}

.CreateStockLocations__closeIcon {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.CreateStockLocations__footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.CreateStockLocations__column {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

.CreateStockLocations__mainContainer {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.CreateStockLocationMobile__mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.CreateStockLocationMobile__header {
  margin-bottom: 24px;
}

.CreateStockLocationMobile__backArrow {
  display: flex;
  align-items: center;
}

.CreateStockLocationMobile__backArrowIcon {
  padding-right: 4px;
}

.CreateStockLocationMobile__goBackTitle {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: rgba(18, 26, 45, 0.5);
  line-height: 1;
  margin: 0;
  padding: 0;
}

.CreateStockLocationMobile__title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #121a2d;
  font-size: 20px;
  line-height: 1;
  margin: 24px 0 0 2px;
  padding: 0;
}

.CreateStockLocationMobile__heading {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.CreateStockLocationMobile__body {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: auto;
  height: 100%;
}

.CreateStockLocationMobile__checkboxes {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.CreateStockLocationMobile__checkboxContainer:first-child {
  margin-right: 24px;
}

.CreateStockLocationMobile__footer {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: auto;
}

.CreateStockLocationMobile__deviceSettingsContainer {
  width: 100%;
}

.CreateStockLocationMobile__returnSystemGroup {
  display: flex;
  flex-direction: column;
}

.CreateStockLocationMobile__returnSystemGroup input {
  width: 100%;
}

.CreateStockLocationMobile__lineBreak {
  display: block;
  margin-top: 4px;
}

.CreateStockLocations__vialLabelInputs {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  width: 150px;
  height: 30px;
  color: #121a2d;
  background-color: #fff;
  border: 2px solid #089bab;
  border-radius: 4px;
  box-sizing: border-box;
  padding-left: 8px;
  outline: none;
}

.CreateStockLocations__vialLabelInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
}

.CreateStockLocations__vialLabelInputContainer:first-child {
  margin-top: 24px;
}

.CreateStockLocations__vialLabelInputContainer:not(:last-child) {
  margin-bottom: 20px;
}

.CreateStockLocationsMobile__vialLabelInputs {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  width: 100%;
  height: 30px;
  color: #121a2d;
  background-color: #fff;
  border: 2px solid #089bab;
  border-radius: 4px;
  box-sizing: border-box;
  padding-left: 8px;
  outline: none;
}

.CreateStockLocationsMobile__vialLabelInputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.CreateStockLocationsMobile__vialLabelInputContainer:not(:last-child) {
  margin-bottom: 20px;
}

.CreateStockLocationsMobile__vialLabelInputContainer h1 {
  margin-bottom: 8px;
}
