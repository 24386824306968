.DataMatrixContainer {
  height: 46px;
  overflow: hidden;
}

.DataMatrixContainer svg {
  margin-left: -10px;
}

.QRBarcodeContainer {
  height: 46px;
  overflow: hidden;
}

.QRBarcodeContainer svg {
  margin-left: -10px;
}

.QRBarcodeContainer svg {
  margin-left: -10px;
}

.Code39Container {
  height: 46px;
  overflow: hidden;
}

.Code39Container svg {
  margin-left: -10px;
}

.Code39Container svg {
  margin-left: -10px;
}

.Code128AContainer {
  height: 46px;
  overflow: hidden;
}

.Code128AContainer svg {
  margin-left: -10px;
}

.Code128AContainer svg {
  margin-left: -13px;
}

.Code128BContainer {
  height: 46px;
  overflow: hidden;
}

.Code128BContainer svg {
  margin-left: -13px;
}

.domBarcodes {
  display: flex;
  visibility: hidden;
}
