@media (min-width: 640px) and (max-width: 1007px) {
  .mainHeading {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    /* font: Poppins; */
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    padding-bottom: 11px;
    border-bottom: 2px solid #089bab;
    margin-top: 40px;
    margin-bottom: 21px;
  }

  .mainHeading__name {
    margin-left: 26px;
    min-width: 170px;
    width: 170px;
    margin-right: 10px;
  }

  .mainHeading__type {
    min-width: 176px;
    width: 176px;
    margin-right: 10px;
  }

  .mainHeading__position {
    min-width: 117px;
    width: 117px;
    margin-right: 10px;
  }

  .mainHeading__controlled {
    min-width: 91px;
    width: 91px;
    margin-right: 10px;
  }

  .mainHeading__barcode {
    min-width: 142px;
    width: 142px;
    margin-right: 10px;
  }

  .mainHeading__qoh {
    min-width: 56px;
    width: 56px;
    margin-right: 10px;
  }

  .mainHeading__min {
    min-width: 54px;
    width: 54px;
    margin-right: 30px;
  }

  .mainHeading__max {
    min-width: 29px;
    width: 29px;
    margin-right: 30px;
  }
}

@media (min-width: 1008px) {
  .mainHeading {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    /* font: Poppins; */
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 11px;
    border-bottom: 2px solid #089bab;
    margin-top: 40px;
    margin-bottom: 21px;
  }

  .mainHeading__name {
    margin-left: 38px;
    min-width: 212px;
    width: 212px;
    margin-right: 30px;
  }

  .mainHeading__type {
    min-width: 220px;
    width: 220px;
    margin-right: 30px;
  }

  .mainHeading__position {
    min-width: 146px;
    width: 146px;
    margin-right: 30px;
  }

  .mainHeading__controlled {
    min-width: 114px;
    width: 114px;
    margin-right: 30px;
  }

  .mainHeading__barcode {
    min-width: 178px;
    width: 178px;
    margin-right: 30px;
  }

  .mainHeading__qoh {
    min-width: 70px;
    width: 70px;
    margin-right: 30px;
  }

  .mainHeading__min {
    min-width: 68px;
    width: 68px;
    margin-right: 30px;
  }

  .mainHeading__max {
    min-width: 36px;
    width: 36px;
    margin-right: 30px;
  }
}
